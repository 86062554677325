/**
 * Login Component
 *
 * This component handles user authentication, including email input and OTP verification.
 *
 * TODO: Implement rate limiting for OTP attempts
 * TODO: Implement proper logout mechanism to clear stored tokens
 */

import React, { useRef, useContext, useEffect, useState } from "react";

import * as z from "zod";
import dayjs from "dayjs";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import { AuthContext } from "@/taskpane/contexts/auth/AuthContext";
import InputCode from "@/taskpane/components/input-code/InputCode";
import LoadingButton from "@/taskpane/components/button/LoadingButton";
import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import { initiateAuth, respondToAuthChallenge } from "@/taskpane/services/auth";
import { enqueueConfirmSnackbar } from "@/taskpane/components/snackbar-provider/SnackbarProvider";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";
import { usePrevious } from "@uidotdev/usehooks";
import { clearSenstiveDataFromLocalStorage } from "@/taskpane/utils/storage";

const MAX_OTP_ATTEMPTS = 3;
const OTP_COOLDOWN_PERIOD = 300000; // 5 minutes in milliseconds

interface KeyDownEvent extends React.KeyboardEvent<HTMLInputElement> {
  key: string;
}

function getOtpCode(element: any) {
  // Select all the elements with the class 'otp-td'
  const otpElements = element.querySelectorAll(".otp-td");

  // Initialize an empty string to hold the OTP code
  let otpCode = "";

  // Check if otpElements is not null or empty
  if (otpElements && otpElements.length > 0) {
    // Loop through each element and concatenate its text content
    otpElements.forEach((el: { textContent: string }) => {
      if (el && el.textContent) {
        // Check if element and its textContent exist
        otpCode += el.textContent;
      }
    });
  } else {
    console.error("OTP elements not found.");
    return null; // Return null if no elements are found
  }

  // Return the final OTP code
  return otpCode || null; // Return null if OTP code remains empty
}

/**
 * Zod schema for validating login form input.
 *
 * This schema ensures that the email field is:
 * - A string
 * - A valid email address
 * - Not empty
 *
 * @constant
 * @type {z.ZodObject}
 * @property {z.ZodString} email - The email field, must be a valid email address and is required.
 * @throws Will throw validation errors if the input does not meet the specified criteria.
 */
const loginSchema = z.object({
  email: z.string().email("Addresse mail invalide").min(1, "L'adresse mail est requise"),
});

type FormData = z.infer<typeof loginSchema>;

/**
 * Login component for handling user authentication.
 *
 * This component manages the login process, including email submission and OTP verification.
 * It uses React hooks for state management and various helper functions for handling authentication.
 *
 * @component
 * @returns {JSX.Element} The rendered login component.
 */
export function LoginPage() {
  const [username, setUsername] = useState(() => localStorage.getItem("username") || "");
  const [showResend, setShowResend] = useState(false);
  const [session, setSession] = useState<string | undefined>(
    () => localStorage.getItem("session") || null || undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [otpAttempts, setOtpAttempts] = useState(0);
  const [lastOtpAttemptTimestamp, setLastOtpAttemptTimestamp] = useState<number>();
  const [otp, setOtp] = useState("");
  const [defaultOtp, setDefaultOtp] = useState("");
  const previousDefaultOtp = usePrevious(defaultOtp);
  const { setTokens: login } = useContext(AuthContext);
  const { changeCounter, currentMailbox } = useOfficeContext();
  const loginFormDefaults: FormData = {
    email: currentMailbox?.userProfile?.emailAddress ?? "",
  };
  const {
    handleSubmit, // function to handle form submission
    formState: { errors }, // Destructure errors
    control,
  } = useForm<FormData>({
    // Call useForm hook with generic type FormData
    resolver: zodResolver(loginSchema), // Pass Zod schema to resolver
    defaultValues: loginFormDefaults, // specify default values for form inputs
  });
  const timeoutRef = useRef<any | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handles the submission of the username (email) form for initiating authentication.
   *
   * @async
   * @function handleOnUserNameSubmit
   * @param {FormData} data - The form data containing the email.
   */
  const handleOnUserNameSubmit = async (data: FormData) => {
    const resultEmail = data.email.toLowerCase();

    setIsLoading(true);

    try {
      const { result, statusCode } = await initiateAuth(resultEmail);

      if (statusCode === 200) {
        const sessionExpOn = dayjs().add(2, 'day').valueOf();

        setSession(result.Session);

        localStorage.setItem("session", result.Session);
        localStorage.setItem("username", resultEmail);
        localStorage.setItem('sessionExpOn', sessionExpOn.toString());

        setOtpAttempts(0);
        setUsername(resultEmail);
        setLastOtpAttemptTimestamp(undefined);
      } else if (statusCode === 400) {
        enqueueSnackbar("L'adresse mail est invalide", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles the submission of the OTP code for verifying 2FA authentication.
   *
   * @async
   * @function handleConfirmOTP
   * @param {string} otpValue - The OTP code entered by the user.
   */
  const handleConfirmOTP = async (otpValue: string) => {
    const currentTime = Date.now();
    if (otpAttempts >= MAX_OTP_ATTEMPTS && currentTime - lastOtpAttemptTimestamp! < OTP_COOLDOWN_PERIOD) {
      const remainingCooldown = Math.ceil((OTP_COOLDOWN_PERIOD - (currentTime - lastOtpAttemptTimestamp!)) / 60000);
      enqueueConfirmSnackbar(
        `Trop de tentatives. Veuillez réessayer dans ${remainingCooldown} minutes.`,
        () => {},
        "OK",
        {
          persist: true,
          maxHideDuration: remainingCooldown,
          variant: "error",
          action: (snackbarId: SnackbarKey | undefined) => (
            <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
          ),
        }
      );
      return;
    }

    setIsLoading(true);
    try {
      const { result, statusCode } = await respondToAuthChallenge(username, otpValue, session);
      if (statusCode === 200) {
        login(result.AuthenticationResult);
        navigate("/folders");
      } else if (statusCode === 400) {
        setOtpAttempts((prevAttempts) => prevAttempts + 1);
        setLastOtpAttemptTimestamp(Date.now());
        enqueueSnackbar("Le code n'est pas valide", {
          variant: "error",
          action: (snackbarId: SnackbarKey | undefined) => (
            <>
              <Button
                color="error"
                variant="text"
                size="small"
                onClick={() => {
                  handleOnUserNameSubmit({ email: username });
                  setOtp("");
                }}
                sx={{ marginRight: 5 }}
              >
                Nouveau code
              </Button>
              <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
            </>
          ),
        });
      }
    } catch (error) {
      console.error("Challenge response failed:", error);
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles the search for an OTP code in the email body.
   * This function will help to detect OTP code in when mail item changes and
   * automaticaly fill the OTP input. This is a TODO feature.
   * @function handleSearchForOTP
   * @param {string} mailContext - The email context containing the email body and other usefull infos
   * that can help to detect OTP code in when mail item changes.
   */
  const handleSearchForOTP = (mailContext: any) => {
    return new Promise((resolve) => {
      if (!mailContext?.item) {
        resolve(null);
        return;
      }

      let { body } = mailContext.item;

      body.getAsync(Office.CoercionType.Html, function (asyncResult: any) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          const bodyHtml = asyncResult.value;

          const element = document.createElement("div");
          element.innerHTML = bodyHtml;

          const otpElement = getOtpCode(element);

          if (otpElement) {
            setDefaultOtp(otpElement);
            setShowResend(false);
          } else {
            // console.log('No OTP code found.');
          }
        }
      });
    });
  };

  function isExpirationValid() {
    const storedDate = localStorage.getItem('expirationDate');
    if (!storedDate) {
      return false;
    }

    const now = dayjs().valueOf();
    return now < parseInt(storedDate, 10);
  }

  useEffect(() => {
    if (location?.pathname === "/login" && session) {
      handleSearchForOTP(currentMailbox);
    }
  }, [changeCounter, currentMailbox]);

  useEffect(() => {
    // Check for saved session data
    const savedUsername = localStorage.getItem("username");
    const savedSession = localStorage.getItem("session");
    if (savedUsername && savedSession) {
      if (isExpirationValid()) {
        setUsername(savedUsername);
        setSession(savedSession);
      } else {
        clearSenstiveDataFromLocalStorage()
      }
    }
  }, []);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setShowResend(true);
    }, 30000);
    return () => {
      // Clear the timeout when component unmounts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [otp]);



  return (
    <Box className="container">
      <Card sx={{ margin: "auto", width: "80%" }}>
        <Stack spacing={2} p={2} alignItems="center">
          {!session && (
            <form
              onSubmit={handleSubmit(handleOnUserNameSubmit)}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" gutterBottom color="black" fontWeight={600} textAlign={"center"}>
                Connexion à votre compte
              </Typography>

              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email}
                    fullWidth
                    helperText={errors.email?.message}
                    label="Adresse email"
                    onChange={(e) => field.onChange(e.target.value)}
                    size="small"
                    type="text"
                    variant="outlined"
                    margin="normal"
                  />
                )}
                control={control}
                defaultValue={loginFormDefaults.email}
                name="email"
              />

              <WarningTooltip title={errors.email?.message ? "Veuillez entrer une adresse email" : ""}>
                <span>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isLoading || !!errors.email?.message}
                    className="login-button"
                    loading={isLoading}
                    size="medium"
                    style={{ paddingLeft: 12, paddingRight: 12, height: 36 }}
                  >
                    Se connecter
                  </LoadingButton>
                </span>
              </WarningTooltip>
            </form>
          )}

          {session && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                color="black"
                fontWeight={600}
                textAlign="center"
                lineHeight={1.4}
              >
                Vous allez recevoir un code de sécurité par mail
              </Typography>

              <InputCode
                length={4}
                onCodeChange={(code) => setOtp(code)}
                handleAltConfirmOTP={handleConfirmOTP}
                defaultOtp={defaultOtp}
                previousDefaultOtp={previousDefaultOtp}
              />
              <WarningTooltip title={otp.length !== 4 ? "Veuillez entrer le code de sécurité reçu par mail" : ""}>
                <span>
                  <LoadingButton
                    disabled={otp.length !== 4}
                    loading={isLoading}
                    onClick={() => {
                      handleConfirmOTP(otp);
                      setShowResend(false);
                    }}
                  >
                    Valider
                  </LoadingButton>
                </span>
              </WarningTooltip>
              {showResend ? (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => loginFormDefaults?.email !== "" && handleOnUserNameSubmit(loginFormDefaults)}
                >
                  Vous n'avez pas reçu de code ? Cliquez ici
                </Button>
              ) : null}
            </>
          )}
        </Stack>
      </Card>
    </Box>
  );
}
