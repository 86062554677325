import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { Typography, IconButton } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

import { styles } from './styles';
import { WidgetProps } from './types';
import { fPercent } from '@/taskpane/utils/format-number';
import { useChart } from '@/taskpane/utils/chart';

/**
 * Un composant widget qui affiche des statistiques avec un graphique.
 * 
 * @component
 * @param {WidgetProps} props - Les propriétés du widget
 * @param {string} props.title - Le titre du widget
 * @param {number} props.total - La valeur totale à afficher
 * @param {number} props.percent - Le pourcentage de changement
 * @param {string} props.period - La description de la période (ex: "par rapport au mois dernier")
 * @param {ColorType} [props.color='primary'] - Le thème de couleur du widget
 * @param {object} props.chart - La configuration du graphique
 * @returns {JSX.Element} Le composant widget rendu
 */
export function Widget({ 
  title, 
  percent, 
  total, 
  period, 
  chart, 
  color = 'primary', 
  sx, 
  ...other 
}: WidgetProps) {
  const theme = useTheme();

  const chartColors = chart.colors ?? [theme.palette[color].dark];

  const chartOptions = useChart({
    chart: { sparkline: { enabled: true } },
    colors: chartColors,
    xaxis: { categories: chart.categories },
    grid: {
      padding: {
        top: 6,
        left: 6,
        right: 6,
        bottom: 6,
      },
    },
    tooltip: {
      y: { 
        formatter: (value: number) => value.toString(),
        title: { formatter: () => '' } 
      },
    },
    ...chart.options,
  });

  return (
    <Card
      sx={{
        ...styles.card(theme, color),
        ...sx,
      }}
      {...other}
    >
      <Box sx={styles.trendingBox}>
        <Box>
          <IconButton size="small" color={percent < 0 ? 'error' : 'success'}>
            {percent < 0 ? <TrendingDown /> : <TrendingUp />}
          </IconButton>

          <Typography
            variant="subtitle2"
            component="span"
            color={percent < 0 ? 'error' : 'success'}
          >
            {percent > 0 && '+'}
            {fPercent(percent)}
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.contentBox}>
        <Box sx={styles.titleBox}>
          <Typography sx={styles.title}>
            {title}
          </Typography>

          <Typography sx={styles.total}>
            {total}
          </Typography>
        </Box>

        <ReactApexChart
          type="line"
          series={chart.series}
          options={chartOptions}
          sx={styles.chart}
        />
      </Box>

      <Box
        component="img"
        src="/assets/background/shape-square.svg"
        alt="background shape"
        sx={styles.backgroundShape(color)}
      />
    </Card>
  );
}