import { DESKTOP_MIN_WIDTH } from "@/taskpane/styles/responsive";

const styles = {
  container: {
    padding: "0 16px",
    gap: 1,
    maxHeight: 0.92,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      maxWidth: "600px",
      margin: "0 auto"
    }
  },

  navigationButtons: {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },

  folderTitle: {
    fontSize: 12,
    color: "secondary.500",
    fontWeight: "bold",
    width: "100%",
    textAlign: "left"
  },

  mainContent: {
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    "& > *": {
      width: "100%"
    }
  },

  actionButtons: {
    flexDirection: "row",
    gap: 2,
    justifyContent: "center",
    margin: "24px 0",
    width: "100%",
    "& .MuiButton-root": {
      minWidth: "120px"
    }
  }
};

export default styles;