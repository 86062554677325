import CheckableFileList from "@/taskpane/components/checkable-file/CheckableFile";
import FolderPageLayout from "@/taskpane/modules/folder/FolderPageLayout";
import { UploadableFile } from "@/taskpane/types/file";
import { getAttachments } from "@/taskpane/utils/attachments";
import { SubFolder } from "@/taskpane/types/folder";
import { Card, Checkbox, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { HiMail } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";
import { checkMimeTypeAllow } from "@/taskpane/hooks/file-dnd";

interface SafeAttachment extends UploadableFile {
  contentType: string;
  type: string;
  lastModifiedDate?: Date;
  mimeTypeAllow: boolean;
}

/**
 * Creates a safe attachment object.
 *
 * @param attachment - The attachment object.
 * @param index - The index of the attachment.
 * @returns The safe attachment object.
 */
function createSafeAttachment(attachment: Partial<SafeAttachment>, index: number): SafeAttachment {
  const file = new File([attachment.base64 || ''], attachment.name || `Unnamed Attachment ${index}`, {
    type: attachment.contentType || attachment.type || "application/octet-stream"
  });


  return {
    id: attachment?.id || `attachment-${index}`,
    name: attachment?.name || `Unnamed Attachment ${index}`,
    size: attachment?.size || 0,
    contentType: attachment?.contentType || "application/octet-stream",
    base64: attachment?.base64 || "",
    type: attachment?.type || attachment?.contentType || "application/octet-stream",
    lastModifiedDate: attachment?.lastModifiedDate,
    mimeTypeAllow: checkMimeTypeAllow(file),
  };
}

/* global Office */

export default function FolderAttachmentsPage() {
  const params = useParams();
  const [currentEmailId, setCurrentEmailId] = useState<string | undefined>(undefined);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const { currentMailbox } = useOfficeContext();

  const updateCurrentEmail = useCallback(async () => {
    if (currentMailbox?.item) {
      const newEmailId = currentMailbox.item.itemId;
      const newSubject = currentMailbox.item.subject || "(No subject)";
      setCurrentEmailId(newEmailId);
      setEmailSubject(newSubject);
    }
  }, [setCurrentEmailId, setEmailSubject]);

  const {
    data: dataAttachments,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getAttachments", currentEmailId],
    queryFn: getAttachments,
    enabled: !!currentEmailId,
  });

  useEffect(() => {
    updateCurrentEmail();
    const intervalId = setInterval(() => {
      updateCurrentEmail();
    }, 5000); // Check for updates every 5 seconds
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (currentEmailId) {
      refetch();
    }
  }, [currentEmailId, refetch]);

  const [selectedSubFolder, setSelectedSubFolder] = useState<SubFolder>();
  const [selectedAttachments, setSelectedAttachments] = useState<SafeAttachment[]>([]);
  const [uploadedAttachments, setUploadedAttachments] = useState<SafeAttachment[]>([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const attachments = dataAttachments?.attachments || [];

  const safeAttachments: SafeAttachment[] = useMemo(() => {
    if (!Array.isArray(attachments)) return [];
    
    return attachments
      .map((attachment, index) => {
        try {
          return createSafeAttachment(attachment, index);
        } catch (error) {
          console.error(`Error creating safe attachment for index ${index}:`, error);
          return null;
        }
      })
      .filter((attachment): attachment is SafeAttachment => attachment !== null);
  }, [attachments]);

  const handleSetSelectedAttachments = useCallback((attachments: React.SetStateAction<SafeAttachment[]>) => {
    if (typeof attachments === 'function') {
      setSelectedAttachments(attachments);
    } else {
      const allowedAttachments = attachments.filter((attachment) => attachment.mimeTypeAllow);
      setSelectedAttachments(allowedAttachments);
    }
  }, []);

  return (
    <FolderPageLayout
      folderId={params.folderId}
      onSelectSubFolder={setSelectedSubFolder}
      selectedSubfolder={selectedSubFolder}
      files={selectedAttachments as UploadableFile[]}
      canValidate={!!selectedSubFolder && selectedAttachments.length > 0}
      onValidate={() => {
        setUploadLoading(false);
        setUploadedAttachments([...uploadedAttachments, ...selectedAttachments]);
        setSelectedAttachments([]);
        setSelectedSubFolder(undefined);
      }}
      onUpload={() => {
        setUploadLoading(true);
      }}
    >
      <Stack height="100%">
        <Typography fontWeight="bold" fontSize={14} color="primary.dark">
          Sélection des pièces jointes :
        </Typography>
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            opacity: uploadLoading ? 0.5 : 1,
            pointerEvents: uploadLoading ? "none" : "auto",
          }}
        >
          <Stack gap={0.5}>
            {isLoading ? (
              <Typography textAlign="center" color="grey.400" p={3}>
                Chargement des pièces jointes...
              </Typography>
            ) : safeAttachments.length === 0 ? (
              <Stack alignItems="center" spacing={2} p={3}>
                <Typography textAlign="center" color="grey.400">
                  Aucune pièce jointe trouvée dans le mail sélectionné
                </Typography>
              </Stack>
            ) : (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={0.5}
                  onClick={() => {
                    const newSelectedAttachments = selectedAttachments.length > 0 ? [] : [...safeAttachments];
                    handleSetSelectedAttachments(newSelectedAttachments);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": {
                        borderRadius: "3px",
                      },
                    }}
                    size="small"
                    indeterminate={
                      selectedAttachments.length > 0 && selectedAttachments.length < safeAttachments.length
                    }
                    checked={selectedAttachments.length === safeAttachments.length}
                    color="warning"
                  />
                  <HiMail color="#1D7BF5" />
                  <Typography fontSize={12}>{emailSubject}</Typography>
                </Stack>
                <CheckableFileList
                  attachments={safeAttachments}
                  selectedAttachments={selectedAttachments}
                  setSelectedAttachments={handleSetSelectedAttachments}
                  uploaded={false}
                  disabled={false}
                  loading={false}
                  newItemIds={new Set()}
                />
              </>
            )}
          </Stack>
        </Card>
      </Stack>
    </FolderPageLayout>
  );
}
