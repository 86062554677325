import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";


/* global Office */
/**
 * Redirect component that handles the redirection based on the current mailbox.
 * The main role of this component is to react to changes in the Office context,
 * such as when the add-in is initialized or when the mailbox changes in order to
 * update the state of the application as expected. 
 * For instance, when the mailbox changes, some page should be close and
 * user should be redirected to a page that is relevant to the new mailbox.
 * @param {{ children?: React.ReactNode }} props - The props object.
 * @param {React.ReactNode} props.children - The child components to be rendered.
 * @returns {React.ReactNode} The child components to be rendered.
 */
export default function Redirect({ children }: { children?: React.ReactNode }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { changeCounter, currentMailbox, setFiliationData, isOfficeReady, isInitialized, setIsInitialized } = useOfficeContext();
  const [initialPathChecked, setInitialPathChecked] = useState(false);
  const [isNewTab, setIsNewTab] = useState(true);

  const log = (message: string, data?: any) => {
    // console.log(`[Redirect] ${message}`, data ? data : '');
  };

  /**
   * Handles the search for filiation mail when a mail item changes.
   * this function is responsible for the good detection of a filiation mail.
   * @param {any} mailContext - The mail context.
   * @returns {Promise<void>} A promise that resolves when the search is complete.
   */
  const handleSearchForFiliationMail = (mailContext: any) => {
    return new Promise((resolve) => {
      log('Searching for filiation mail', { mailContext, location: location.pathname, isNewTab });

      if (!mailContext?.item) {
        log('No mail item found');
        resolve(null);
        
        // Don't redirect if it's a new tab with a filiation URL
        if (location.pathname.includes("/folder/") && 
            location.pathname.includes("/filiation/") && 
            !isNewTab) {
          log('Redirecting to folders - no mail item and not a new tab');
          navigate("/folders")
        }
        return;
      }

      let { body } = mailContext.item;

      body.getAsync(Office.CoercionType.Html, function (asyncResult: any) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          const bodyHtml = asyncResult.value;

          const element = document.createElement("div");
          element.innerHTML = bodyHtml;

          const jsonData = element.querySelector("#filiation-data, #x_filiation-data")?.textContent;
          /**
           * If the parsing of the mail that should be a filiation mail failed, 
           * we resolve the promise with null and we redirect the user to the folders page only if 
           * the current pathname match the pattern "/folder/:folderId/filiation/:filiationId".
           */
          if (!jsonData) {
            log('No filiation data found in mail');
            resolve(null);
            if (location.pathname.includes("/folder/") && 
                location.pathname.includes("/filiation/") && 
                !isNewTab) {
              log('Redirecting to folders - no filiation data and not a new tab');
              navigate("/folders")
            }
            return;
          }

          const filiationData = JSON.parse(jsonData);
          const clientId = filiationData?.client_id;
          const folderId = filiationData?.dossier_id;
          const subFolderId = filiationData?.flow_id;

          /**
           * Feed the office context with the parsed filiation data from the open email
           * when a reaction to the event of email change detection is fire
           */
          setFiliationData({ ...filiationData })

          /**
           * If the parsing of the mail that should be a filiation mail succeeded,
           * but the clientId, folderId or subFolderId are null or undefined, due to a request
           * error or any other reason, we resolve the promise with null and 
           * we redirect the user to the folders page only if
           * the current pathname match the pattern "/folder/:folderId/filiation/:filiationId".
           */
          if (!clientId || !folderId || !subFolderId) {
            log('Missing required filiation data', { clientId, folderId, subFolderId });
            resolve(null);
            if (location.pathname === `/folder/${folderId}/filiation/${subFolderId}` && !isNewTab) {
              log('Redirecting to folders - missing required data and not a new tab');
              navigate("/folders")
            }
            return;
          }

          /**
           * If the parsing of the mail that should be a filiation mail succeeded,
           * and the clientId, folderId and subFolderId are not null or undefined,
           * we resolve the promise with the filiationData and 
           * we redirect the user to the filiation page
           */
          log('Valid filiation data found', { folderId, subFolderId });
          
          if (!isNewTab) {
            log('Navigating to filiation page - not a new tab');
            navigate(`/folder/${folderId}/filiation/${subFolderId}`);
          }

          if (
            !isNewTab &&
            location.pathname.includes("/folder/") &&
            location.pathname.includes("/filiation/") &&
            location.pathname !== `/folder/${folderId}/filiation/${subFolderId}`
          ) {
            log('Redirecting to folders - wrong filiation page and not a new tab');
            navigate("/folders")
          }

          /**
           * If all the prerequisites failed and the current pathname match the pattern
           * "/folder/:folderId/filiation/:filiationId", we redirect the user to the folders page.
           */
        } else if (location.pathname.includes("/folder/") && 
                   location.pathname.includes("/filiation/") && 
                   !isNewTab) {
          log('Redirecting to folders - async result failed and not a new tab');
          navigate("/folders")
        }
      });
    });
  };

  // Check if Office context is initialized
  useEffect(() => {
    log('Checking Office initialization');
    if (Office?.context?.mailbox) {
      log('Office context initialized');
      setIsInitialized(true);
    }
  }, []);

  // Handle initial direct URL navigation and new tab detection
  useEffect(() => {
    if (isInitialized && !initialPathChecked) {
      log('Checking initial path', { pathname: location.pathname });
      
      if (location.pathname.includes("/folder/") && location.pathname.includes("/filiation/")) {
        const pathParts = location.pathname.split('/');
        const folderId = pathParts[pathParts.indexOf('folder') + 1];
        const filiationId = pathParts[pathParts.indexOf('filiation') + 1];
        
        log('Found filiation path', { folderId, filiationId });
        
        if (folderId && filiationId) {
          // This is likely a new tab with a direct filiation URL
          log('Setting new tab state for filiation URL');
          setIsNewTab(true);
        }
      }
      
      setInitialPathChecked(true);
    }
  }, [isInitialized, initialPathChecked, location.pathname]);

  // Handle mailbox changes
  useEffect(() => {
    if (isInitialized) {
      log('Handling mailbox change', { changeCounter, isNewTab });
      handleSearchForFiliationMail(currentMailbox);
      
      // After the first mailbox check, it's no longer considered a new tab
      if (isNewTab && currentMailbox?.item) {
        log('Resetting new tab state after mailbox check');
        setIsNewTab(false);
      }
    }
  }, [isInitialized, changeCounter, currentMailbox]);

  // Don't render until Office is ready and initialized
  if (!isOfficeReady || !isInitialized) {
    log('Waiting for Office initialization');
    return children;
  }

  return children;
}
