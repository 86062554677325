import * as React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./header";
import { Box } from "@mui/material";
import { useMatch } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import { useMemo } from "react";

export function Layout() {
  const { height } = useWindowSize();

  const matchDiagram = useMatch("/filiation/:client_id/:dossier_id/:sous_dossier_id/:flow_id");

  const isSmallScreen = useMemo(() => height ? height < 750 : false, [height]);

  return (
    <Box
      sx={{
        ...(matchDiagram
          ?
          {
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            px: 0
          }
          :
          {
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            height: `calc(100vh-${isSmallScreen ? "40px" : "48px"})`,
            maxWidth: "800px",
            margin: "0 auto",
            ...(isSmallScreen ?
              {
                p: 0
              }
              :
              {
                paddingLeft: "4px",
                paddingRight: "4px",
                "@media (min-width: 550px)": {
                  paddingLeft: "24px",
                  paddingRight: "24px",
                }
              }
          )}),
      }}
    >
      {matchDiagram ? null : <Header />}
      <Box sx={isSmallScreen && !matchDiagram ? { flex: 1, flexDirection: "column",overflow: "hidden", flexGrow: 1 } : { flex: 1, overflow: "hidden", minHeight: 0}}>
        <Outlet />
      </Box>
    </Box>
  );
}
