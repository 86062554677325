import { useQuery } from "@tanstack/react-query";
import { getDashboardData, getDashboardDetailedStats } from "./dashboard";

export function useGetDashboardDataQuery() {
  return useQuery({
    queryKey: ["dashboardData"],
    queryFn: getDashboardData,
  });
}

export function useGetDashboardDetailedStatsQuery(startDate: string, endDate: string) {
  return useQuery({
    queryKey: ["dashboardDetailedStats", startDate, endDate],
    queryFn: () => getDashboardDetailedStats(startDate, endDate),
    enabled: !!startDate && !!endDate,
  });
}