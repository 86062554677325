import Redirect from "@/taskpane/components/redirect/Redirect";
import LoadingScreen from "@/taskpane/components/loading/LoadingScreen";
import { AuthContext } from "@/taskpane/contexts/auth/AuthContext";
import { useGetUserAttributesQuery } from "@/taskpane/services/auth.hook";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

/**
 * ProtectedRoute Component
 *
 * Ensures that the wrapped component is only accessible to authenticated users.
 * Redirects to the login page if the user is not authenticated.
 */
export function ProtectedRoute({
  children,
  ignoreCrpcen = false,
}: {
  children: React.ReactNode;
  ignoreCrpcen?: boolean;
}) {
  const { isAuthenticated } = useContext(AuthContext);
  const { data, isPending } = useGetUserAttributesQuery({
    enabled: isAuthenticated,
  });
  if (!isAuthenticated || (!isPending && !data)) return <Navigate to="/login" replace />;
  if (!ignoreCrpcen && isAuthenticated && !isPending && (!data?.crpcen || data?.crpcen?.includes("NEED_ACCEPT")))
    return <Navigate to="/crpcen" replace />;
  if (isPending) return <LoadingScreen />;

  return <Redirect>{children}</Redirect>;
}

/**
 * ProtectedRoute Component
 *
 * Ensures that the wrapped component is only accessible to authenticated users.
 * Redirects to the login page if the user is not authenticated.
 */
export function NonAuthRouteGuard({ children }: { children: React.ReactNode }) {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  if (isLoading) return <LoadingScreen />;
  if (isAuthenticated) return <Navigate to="/folders" replace />;

  return children;
}
