import { Box, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

export interface LoadingCircularProps {
  text: string;
  variant?: "determinate" | "indeterminate";
  progress?: number | null;
}

export default function LoadingCircular({ text, variant = "determinate", progress = null }: LoadingCircularProps) {
  const theme = useTheme();

  return (
    <Stack spacing={0.5} alignItems="center" width={1} px={2} py={1}>
      <Box sx={{ position: 'relative', display: 'inline-flex', maxWidth: "inherit", minWidth: "inherit" }}>
        <Box sx={{ width: 64, height: 64, position: "relative",  maxWidth: "inherit", minWidth: "inherit" }}>
          <CircularProgress
            color="inherit"
            size={64}
            variant="determinate"
            value={100}
            sx={{ width: "100%", position: "absolute", top: 0, left: 0, color: "text.disabled", zIndex: 1 }}
          />
          <CircularProgress
            color="success"
            variant={variant}
            size={64}
            sx={{ width: "100%", position: "absolute", top: 0, left: 0, zIndex: 2 }}
            {...(progress !== null ? { value: progress } : {})}
          />
        </Box>
        {progress || progress === 0 ?
          (
            <Box
              sx={{
                top: "calc(50% - 8px)",
                left: "calc(50% - 8px)",
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: "16px",
                width: "16px",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                fontSize={13}
                sx={{ color: "text.secondary", ml: 1 }}
              >
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          )
          :
          null
        }
      </Box>
      <Typography
        variant="caption"
        component="div"
        color={theme.palette.success.main}
        fontSize={11}
        sx={{ color: "text.secondary", ml: 0 }}
      >
        {text}
      </Typography>
    </Stack>
  );
}
