import React, { memo } from "react";

import { Checkbox } from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import { CheckboxProps } from "@mui/material/Checkbox";

interface CustomCheckboxProps extends CheckboxProps {
  width?: string | number;
  height?: string | number;
}

// Memoized SVG icons
const CheckedIcon = memo(({ width, height }: { width: string | number, height: string | number }) => (
  <SvgIcon viewBox="0 0 16 15" style={{ width, height }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.71582 3.75024C0.71582 1.67918 2.39475 0.000244141 4.46582 0.000244141H11.9658C14.0369 0.000244141 15.7158 1.67918 15.7158 3.75024V11.2502C15.7158 13.3213 14.0369 15.0002 11.9658 15.0002H4.46582C2.39475 15.0002 0.71582 13.3213 0.71582 11.2502V3.75024ZM11.6771 6.37149L8.11457 9.93399C7.98707 10.0615 7.82207 10.1215 7.64957 10.1215C7.48457 10.1215 7.31957 10.0615 7.19207 9.93399L5.40707 8.15649C5.15207 7.89399 5.15207 7.48149 5.40707 7.22649C5.66207 6.97149 6.08207 6.97149 6.33707 7.22649L7.64957 8.53899L10.7471 5.44149C11.0021 5.18649 11.4221 5.18649 11.6771 5.44149C11.9321 5.70399 11.9321 6.11649 11.6771 6.37149Z"
      fill="currentColor"
    />
  </SvgIcon>
));

CheckedIcon.displayName = 'CheckedIcon';

const UncheckedIcon = memo(({ width, height }: { width: string | number, height: string | number }) => (
  <SvgIcon viewBox="0 0 16 15" style={{ width, height }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.71582 3.75024C0.71582 1.67918 2.39475 0.000244141 4.46582 0.000244141H11.9658C14.0369 0.000244141 15.7158 1.67918 15.7158 3.75024V11.2502C15.7158 13.3213 14.0369 15.0002 11.9658 15.0002H4.46582C2.39475 15.0002 0.71582 13.3213 0.71582 11.2502V3.75024ZM4.46582 1.50024C3.22318 1.50024 2.21582 2.50761 2.21582 3.75024V11.2502C2.21582 12.4929 3.22318 13.5002 4.46582 13.5002H11.9658C13.2085 13.5002 14.2158 12.4929 14.2158 11.2502V3.75024C14.2158 2.50761 13.2085 1.50024 11.9658 1.50024H4.46582Z"
      fill="currentColor"
    />
  </SvgIcon>
));

UncheckedIcon.displayName = 'UncheckedIcon';

const CustomCheckbox = styled(memo((props: CustomCheckboxProps) => {
  const { width = "16px", height = "16px", ...otherProps } = props;
  return (
    <Checkbox
      color={props.color}
      checkedIcon={<CheckedIcon width={width} height={height} />}
      icon={<UncheckedIcon width={width} height={height} />}
      {...otherProps}
    />
  );
}))({});

CustomCheckbox.displayName = 'CustomCheckbox';

export default CustomCheckbox;
