import type { Theme } from '@mui/material/styles';

export default {
    modalStyles: { "& .MuiDialog-paper": { width: "100%", maxHeight: 400, borderRadius: 3 } },
    modalSelectStyles: {
        minWidth: 80,
        color: "black",
        "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid rgba(145, 158, 171, 0.20)`,
            borderRadius: 2,
        },
        "& .MuiSelect-select": {
            fontSize: 12,
        },
    },
    modalSelectDefaultMenuItemStyles: (theme: Theme) => ({
        fontSize: 12,
        color: theme.palette.text.secondary,
    }),
}