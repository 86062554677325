import { getFolders, getSubFolders, getSubFolderDetails, setSousDossierAutoImportStatus } from "@/taskpane/services/folders";
import { folderDataToFolder, subFolderDataToSubFolder } from "@/taskpane/types/folder";
import { MutationOptions, useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import dayjs from "dayjs";

export const useGetFoldersQuery = (opt?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getFolders>>>>) =>
  useQuery({
    ...opt,
    select: (data) => {
      if (!data) {
        return null;
      }
      return {
        dossiers: data.dossiers
          ?.sort((doss1, doss2) => {
        const dateA = dayjs(doss1.updated_on);
        const dateB = dayjs(doss2.updated_on);
        return dateA.valueOf() - dateB.valueOf();
          })
          ?.map((dossier) => folderDataToFolder(dossier)) || [],
        client_blocked: data.client_blocked || false,
        client_blocked_reason: data.client_blocked_reason
      };
    },
    queryFn: getFolders,
    queryKey: ["getFolders"],
  });

export const useGetSubFoldersQuery = (
  dossierId: string,
  opt?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubFolders>>>>
) =>
  useQuery({
    ...opt,
    select: (data) => {
      return data?.sous_dossiers?.map((subFolder) => subFolderDataToSubFolder(subFolder));
    },
    queryFn: () => getSubFolders(dossierId),
    queryKey: ["getSubFolders", dossierId],
  });

export const useGetSubFolderDetailsQuery = (
  folderId: string,
  subFolderId: string,
  opt?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubFolderDetails>>>>
) =>
  useQuery({
    ...opt,
    queryFn: () => getSubFolderDetails(folderId, subFolderId),
    queryKey: ["getSubFolderDetails", folderId, subFolderId],
  });

  export const useSetSousDossierAutoImportStatus = (
    opt?: MutationOptions<Awaited<ReturnType<typeof setSousDossierAutoImportStatus>>, Error, Parameters<typeof setSousDossierAutoImportStatus>[0]>
  ) => {
    return useMutation({
      ...opt,
      mutationFn: setSousDossierAutoImportStatus,
    });
  };
