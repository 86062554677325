import React, { useMemo } from "react";

import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import HelpIcon from "@mui/icons-material/Help";
import PendingIcon from "@mui/icons-material/Pending";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocalStorage, useWindowSize } from "@uidotdev/usehooks";
import StyledSelect from "@/taskpane/components/styled-select/StyledSelect";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";
import { useGetFoldersQuery, useGetSubFolderDetailsQuery, useGetSubFoldersQuery, useSetSousDossierAutoImportStatus } from "@/taskpane/services/folders.hook";
import { Box, Button, Card, CircularProgress, FormControlLabel, IconButton, MenuItem, Paper, Skeleton, Stack, styled, Switch, SwitchProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from "@mui/material";

import styles from "./FolderDetails.styles";
import type { Document } from "./FolderDetails.types";

import 'dayjs/locale/fr';


dayjs.locale('fr');

const ADDIN_API_BASE_URL = process.env.REACT_APP_BASE_URL_API_ADDIN;

/**
 * @description Switch button that enable/disable automatic import of documents from external notaire software.
 *
 * @param {SwitchProps} props - MUI Switch props
 */
const AutoImportStatusSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

/**
 * @description Widget to display information about the folder.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.title - The title of the widget.
 * @param {string | number} props.value - The value to display.
 * @param {boolean} [props.loading=false] - Whether the widget is in loading state.
 * @param {Object} props.size - The size of the widget.
 *
 * @returns {JSX.Element} The rendered component.
 */
function InfoWidget({ title, value, loading = false, size }: { title: string; value: string | number; loading?: boolean, size?: { width: number; height: number } }) {
  return (
    <Card sx={{ py: 2, px: 1, minWidth: 100, height: 72, fontSize: size?.width && size.width < 400 ? "2.8vw" : 12 }}>
      <Typography variant="h6" fontSize={10} color="#000" fontWeight={600}>
        {title}:{" "}
      </Typography>
      {loading ? (
        <Skeleton variant="text" width={10} height={32} />
      ) : (
        <Typography variant="body1" fontSize={10}>
          {value}
        </Typography>
      )}
    </Card>
  );
}

/**
 * @description Table to display documents in the folder.
 *
 * @param {Object} props - The props for the component.
 * @param {Document[]} props.documents - The list of documents to display.
 * @param {boolean} [props.loading=false] - Whether the table is in loading state.
 *
 * @returns {JSX.Element} The rendered component.
 */
function DocumentsTable({ documents, loading = false }: { documents: Document[]; loading?: boolean }) {

  /**
   * @description Function to compute the status of the document in French.
   *
   * @param {string} status - The status of the document.
   *
   * @returns {string} The status of the document in French.
   */
  const computeStatusInFrench = (status: string) => {
    switch (status) {
      case "not_started":
        return "En attente...";
      case "En attente":
        return "En attente...";
      case "success":
        return "Analysé";
      default:
        return "Indeterminé";
    }
  };

  /**
   * @description Function to display the status of the document.
   *
   * @param {string} status - The status icon and corresponding props of the document.
   *
   * @returns {JSX.Element} The rendered icon component.
   */
  const displayedStatus = (status: string) => {
    switch (status) {
      case "not_started":
        return (
          <IconButton color="warning" size="small">
            <PendingIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        );
      case "En attente":
        return (
          <IconButton color="warning" size="small">
            <PendingIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        );
      case "success":
        return (
          <IconButton color="success" size="small">
            <CheckCircleIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        );
      default:
        return (
          <IconButton color="default" size="small">
            <HelpIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        );
    }
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width="50%" sx={{ py: 2 }}>
              <Typography variant="body2" fontSize={10} fontWeight="medium">
                Nom du document
              </Typography>
            </TableCell>
            <TableCell width="20%" sx={{ py: 2 }}>
              <Typography variant="body2" fontSize={10} fontWeight="medium">
                Date d'ajout
              </Typography>
            </TableCell>
            <TableCell width="15%" sx={{ py: 2 }}>
              <Typography variant="body2" fontSize={10} fontWeight="medium">
                Pages
              </Typography>
            </TableCell>
            <TableCell width="15%" sx={{ py: 2 }}>
              <Typography variant="body2" fontSize={10} fontWeight="medium">
                Statut
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? [...Array(3)].map((_, index) => (
              <TableRow key={`skeleton-${index}`}>
                <TableCell width="50%" sx={{ py: 2 }}>
                  <Skeleton variant="text" width={200} />
                </TableCell>
                <TableCell width="20%" sx={{ py: 2 }}>
                  <Skeleton variant="text" width={100} />
                </TableCell>
                <TableCell width="15%" sx={{ py: 2 }}>
                  <Skeleton variant="text" width={50} />
                </TableCell>
                <TableCell width="15%" sx={{ py: 2 }}>
                  <Skeleton variant="text" width={80} />
                </TableCell>
              </TableRow>
            ))
            : documents.map((doc, index) => {
              let uploadTimeDate = '';
              let uploadTimeTime = '';

              if (doc?.uploadTime) {
                const datetimeUploadTime = dayjs(doc.uploadTime);
                if (datetimeUploadTime.isValid()) {
                  uploadTimeDate = datetimeUploadTime.format("DD/MM/YYYY");
                  uploadTimeTime = datetimeUploadTime.format("HH:mm:ss");
                }
              }
              return (
                <TableRow key={index}>
                  <TableCell width="50%" sx={{ maxWidth: 0, py: 2 }}>
                    <Tooltip title={doc.name}>
                      <Typography variant="body2" fontSize={10} noWrap>
                        {doc.name}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell width="20%" sx={{ maxWidth: 0, py: 2 }}>
                    <Tooltip title={doc.uploadTime}>
                      <Stack>
                        {doc?.uploadTime && doc?.uploadTime !== "" ? (
                          <>
                            <Typography variant="body2" fontSize={10} noWrap>
                              {uploadTimeDate}
                            </Typography>
                            <Typography variant="body2" fontSize={10} noWrap>
                              {uploadTimeTime}
                            </Typography>
                          </>
                        ) : null}
                      </Stack>
                    </Tooltip>
                  </TableCell>
                  <TableCell width="15%" sx={{ maxWidth: 0, py: 2 }}>
                    <Tooltip title={doc.pages?.toString() || ""}>
                      <Typography variant="body2" fontSize={10} noWrap>
                        {doc.pages}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell width="15%" sx={{ maxWidth: 0, py: 2 }}>
                    <Tooltip title={computeStatusInFrench(doc.status)}>{displayedStatus(doc.status)}</Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

/**
 * @description Main component to render control center of a sous-dossier.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function FolderDetails() {
  const ADDIN_BASE_URL = ADDIN_API_BASE_URL?.replace("-api", "").replace("prod-", "") ?? "";

  const [selectedSousDossierId, setSelectedSousDossierId] = useLocalStorage("selectedSousDossierId", "");

  const { folderId } = useParams();
  const navigate = useNavigate();

  const size = useWindowSize();

  const theme = useTheme();

  const { data: folders } = useGetFoldersQuery();

  const {
    data: subFolders,
    isFetching: subFoldersLoading,
    refetch: refetchSubFolders,
  } = useGetSubFoldersQuery(folderId || "");

  /**
   * @description Isolate the sous-dossier in the select from the control center.
   * This is the sous-dossier of which the documents are being displayed.
   */
  const selectedSousDossierObject = subFolders?.find((sd) => sd?.sous_dossier_id === selectedSousDossierId);

  const { data: currentSubFolderDetails, isFetching: subFolderDetailsLoading } = useGetSubFolderDetailsQuery(
    folderId || "",
    selectedSousDossierObject?.sous_dossier_id || "",
    {
      enabled:
        !!folderId && !!selectedSousDossierObject?.sous_dossier_id && selectedSousDossierObject?.sous_dossier_id !== "",
      staleTime: 0,
    }
  );

  /**
   * @description Isolate the sous-dossier in the select from the control center.
   *
   * This is the sous-dossier of which the documents are being displayed.
   *
   * @memoization
   * @dependency subFolders - The list of sub-folders in the folder.
   * @dependency selectedSousDossierId - The ID of the selected sub-folder.
   *
   * @returns {Object} The selected sub-folder object.
   */
  const selectedSubFolderObject = useMemo(() => {
    return subFolders?.find((sf) => sf.sous_dossier_id === selectedSousDossierId);
  }, [selectedSousDossierId, subFolders]);

  /**
   * @description Mutation to set the auto-import status of a sous-dossier.
   * This mutation is used to enable/disable the automatic import of documents from the external notaire software.
   * @param {string} onSucces - Callback function to execute when the mutation is successful that display a success message.
   * @param {string} onError - Callback function to execute when the mutation fails that display an error message.
   */
  const setSousDossierAutoImportStatusMutation = useSetSousDossierAutoImportStatus({
    onSuccess: () => {
      refetchSubFolders();
    },
    onError: (error) => {
      enqueueSnackbar(
        typeof error === "string"
          ? error
          : (error?.message ?? "Une erreur est survenue lors de la mise a jour du statut d'import automatique"),
        {
          variant: "error",
          persist: true,
          action: (snackbarId: SnackbarKey | undefined) => (
            <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
          ),
        }
      );
    },
  });

  const currentFolder = useMemo(() => folders?.dossiers.find((f) => f.dossier_id === folderId), [folders, folderId]);

  /**
   * @description Memoized list of documents in the selected sub-folder.
   *
   * @memoization
   * @dependency currentSubFolderDetails - The details of the selected sub-folder.
   *
   * @returns {Document[]} The list of documents in the selected sub-folder.
   */
  const documents = useMemo(() => {
    if (!currentSubFolderDetails?.inputs) {
      return [];
    } else {
      return currentSubFolderDetails.inputs.map((doc: any) => ({
        name: doc?.input_classifier_rename ?? doc?.input_original_filename,
        uploadTime: new Date(doc.created_on).toLocaleString(),
        status: doc.step_classifier_status || "En attente",
        pages: doc?.page_number ?? "N/A",
      }));
    }
  }, [currentSubFolderDetails]);

  /**
   * @description Effect to ensure that the selected sub-folder is always a valid sub-folder.
   *
   * This effect ensures that the selected sub-folder is always a valid sub-folder.
   * If the selected sub-folder is not valid, the first sub-folder in the list is selected.
   *
   * @effect
   * @dependency selectedSousDossierId - The ID of the selected sub-folder.
   * @dependency subFolders - The list of sub-folders in the folder.
   * @dependency setSelectedSousDossierId - The function to set the selected sub-folder ID.
   *
   * @returns {void}
   */
  React.useEffect(() => {
    if (
      selectedSousDossierId !== "" &&
      typeof selectedSousDossierId === "string" &&
      subFolders &&
      subFolders?.length > 0 &&
      !subFolders?.map((item) => item?.sous_dossier_id)?.includes(selectedSousDossierId)
    ) {
      setSelectedSousDossierId(subFolders?.[0]?.sous_dossier_id);
    }

    if (selectedSousDossierId === "" && subFolders) {
      setSelectedSousDossierId(subFolders?.[0]?.sous_dossier_id);
    }
  }, [subFolders, selectedSousDossierId, setSelectedSousDossierId]);

  /**
   * @description Function to open an external filiation in a new browser window.
   *
   * @param {string} url - The URL of the external filiation.
   *
   * @returns {void}
   */
  const openExternalFiliation = (url: any) => {
    if (typeof url === "string") {
      Office.context.ui.openBrowserWindow(url);
      return;
    } else {
      console.error(`URL ${url} is invalid`);
      return;
    }
  };

  /**
   * @description Function to handle the change event of the select element.
   *
   * @param {any} event - The event object.
   *
   * @returns {void}
   */
  const handleChange = (event: any) => {
    setSelectedSousDossierId(event.target.value as string);
  };

  /**
   * @description Memoized formatted datetime of the last input in the selected sub-folder.
   *
   * @memoization
   * @dependency currentSubFolderDetails - The details of the selected sub-folder.
   *
   * @returns {string | null} The formatted datetime of the last input in the selected sub-folder.
   */
  const last_input_formatted_datetime = useMemo(() => {
    if (currentSubFolderDetails?.sous_dossier?.date_last_input && currentSubFolderDetails?.sous_dossier?.date_last_input !== "") {
      const date = dayjs(currentSubFolderDetails?.sous_dossier?.date_last_input);
      return date.format("DD/MM/YYYY HH:mm:ss");
    } else {
      return null;
    }
  }, [currentSubFolderDetails?.sous_dossier?.date_last_input])

  /**
   * @description State that holds the current auto-import status of the selected sous-dossier.
   *
   * This state is used to determine the current status of the automatic import of documents from the external notaire software.
   *
   * @memoization
   * @dependency selectedSubFolderObject - The selected sub-folder object.
   * @dependency selectedSousDossierId - The ID of the selected sub-folder.
   *
   * @returns {boolean} The current auto-import status of the selected sous-dossier.
   */
  const activationAutoImportStatusState = useMemo(() => {
    return (
      !!selectedSubFolderObject?.sous_dossier_import_partenaire_auto &&
      selectedSubFolderObject?.sous_dossier_import_partenaire_auto !== ""
    );
  }, [selectedSubFolderObject, selectedSousDossierId]);


  /**
   * @description Function to handle the change event of the auto-import status switch and mutate status when the status changes.
   *
   * @param {any} _ - The event object.
   * @param {boolean} checked - The checked status of the switch.
   *
   * @returns {void}
   */
  const handleSwitchAutoImportStatus = (_: any, checked: boolean) => {
    const newValue = checked;

    if (!!newValue !== !!activationAutoImportStatusState) {
      setSousDossierAutoImportStatusMutation.mutate({
        dossierId: folderId || "",
        sousDossierId: selectedSousDossierId || "",
        autoImportStatusValue: !!newValue,
      });
    }
  };

  return (
    <Stack height={0.92}>
      <Box sx={{ mb: 0 }}>

        {/** Navigation button to return to the previous page */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button onClick={() => navigate(-1)} sx={styles.backButton}>
            Retour
          </Button>
        </Stack>
      </Box>

      {/** Folder name/id */}
      <Stack spacing={2} sx={{ my: 1, p: 1 }}>
        <Typography color="secondary.500" fontWeight="bold" fontSize={14} noWrap>
          {`Dossier: ${currentFolder?.name} (${currentFolder?.dossier_id})`}
        </Typography>
      </Stack>

      {/** Sub-folder select */}
      <Box sx={styles.selectContainer}>
        {subFoldersLoading ? (
          <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
            <CircularProgress />
          </Stack>
        ) : subFolders && subFolders.length > 0 ? (
          <Box sx={{ width: "100%" }}>

            <Stack sx={styles.selectStack}>
              <StyledSelect
                label={"Sous-dossier"}
                multiple={false}
                value={selectedSousDossierId}
                onChange={handleChange}
              >
                {subFolders.map((sf, index) => (
                  <MenuItem
                    key={sf.sous_dossier_id}
                    value={sf?.sous_dossier_id}
                    id={`menu-item-${index}`}
                  >{`${sf.nature} ${sf.name}`}</MenuItem>
                ))}
              </StyledSelect>
            </Stack>

            {subFolders
              .filter((sf) => sf?.sous_dossier_id === selectedSousDossierId)
              ?.map((_sf, _index) => (
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    width: "100%",
                    borderRadius: "4px",
                    height: "100%",
                    border: "1px solid",
                    borderColor: "rgba(135, 135, 135, 0.34)",
                    "& .MuiCardContent-root": {
                      p: 1,
                    },
                  }}
                >
                  <Box sx={{ p: 0 }}>
                    <Stack spacing={2}>

                      {/** Action to enable/disable automatic import of documents for the selected sous-dossier */}
                      <FormControlLabel
                        control={
                          <AutoImportStatusSwitch
                            size="small"
                            checked={activationAutoImportStatusState}
                            onChange={handleSwitchAutoImportStatus}
                            disabled={subFolderDetailsLoading || setSousDossierAutoImportStatusMutation.isPending}
                            sx={styles.autoImportSwitch}
                          />
                        }
                        label={"Analyse automatique des documents présents dans le logiciel métier"}
                        sx={styles.autoImportSwitchLabel}
                      />

                      {/** Action bar to interact with filiation */}
                      <Box sx={styles.controlCenterActions}>
                        <Box sx={{ width: "47.5%" }}>
                          <Button
                            fullWidth
                            sx={styles.controlCenterActionsButton(size?.width || 0)}
                            variant="contained"
                            color="primary"
                            disabled={subFolderDetailsLoading || !currentSubFolderDetails?.sous_dossier?.filiation_id}
                            onClick={() => {
                              if (currentSubFolderDetails?.sous_dossier?.filiation_id) {
                                navigate(
                                  `/folder/${folderId}/filiation/${currentSubFolderDetails.sous_dossier.filiation_id}`
                                );
                              }
                            }}
                          >
                            Choix des biens analysés
                          </Button>
                        </Box>

                        {/** Action to open the filiation diagram in a new browser window */}
                        <Box sx={{ width: "47.5%" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={styles.controlCenterActionsButton(size?.width || 0)}
                            disabled={
                              subFolderDetailsLoading ||
                              !currentSubFolderDetails?.sous_dossier?.filiation_id ||
                              !currentSubFolderDetails?.client_id
                            }
                            onClick={() => {
                              if (
                                currentSubFolderDetails?.sous_dossier.filiation_id &&
                                currentSubFolderDetails?.client_id
                              ) {
                                openExternalFiliation(
                                  `${ADDIN_BASE_URL}taskpane.html#/filiation/${currentSubFolderDetails?.client_id}/${folderId}/${currentSubFolderDetails.sous_dossier.sous_dossier_id}/${currentSubFolderDetails.sous_dossier.filiation_id}`
                                );
                              }
                            }}
                          >
                            Visualiser le diagramme
                          </Button>
                        </Box>
                      </Box>

                      {/** Informations widgets group */}
                      <Box sx={styles.widgetContainer}>
                        <Box sx={{ width: "47.5%" }}>
                          <InfoWidget
                            size={size as { width: number; height: number }}
                            title="Dernier ajout"
                            value={last_input_formatted_datetime ?? ""}
                            loading={subFolderDetailsLoading}
                          />
                        </Box>
                        <Box sx={{ width: "47.5%" }}>
                          <InfoWidget
                            size={size as { width: number; height: number }}
                            title="Nombre de documents déposés"
                            value={currentSubFolderDetails?.sous_dossier?.stat_number_of_inputs || 0}
                            loading={subFolderDetailsLoading}
                          />
                        </Box>
                      </Box>

                      {/** Documents table of the selected sous-dossier */}
                      <DocumentsTable documents={documents} loading={subFolderDetailsLoading} />

                    </Stack>
                  </Box>
                </Card>
              ))}
          </Box>
        ) : (
          <Typography color="text.secondary" textAlign="center">
            Aucun sous-dossier trouvé
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
