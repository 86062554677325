import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { FixedSizeList } from "react-window";
import { useNavigate } from "react-router-dom";

import { Folder, SubFolderData } from "@/taskpane/types/folder";
import { useQuery } from "@tanstack/react-query";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import { DOSSIER_STATUS_INOT } from "@/taskpane/config/label";
import { getAttachments } from "@/taskpane/utils/attachments";
import { usePolling } from "@/taskpane/services/polling.hook";
import { useGetUserDataQuery } from "@/taskpane/services/user.hook";
import { useLocalStorage, useWindowSize } from "@uidotdev/usehooks";
import { useGetUserAttributesQuery } from "@/taskpane/services/auth.hook";
import { CloseOutlined, SyncAlt as SyncAltIcon, EmailOutlined } from "@mui/icons-material";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";
import { ControlCenterIcon } from "@/taskpane/components/icons/control-center";
import { SelectInitialesDialog } from "@/taskpane/modules/folders/SelectInitialesModal";
import { useGetFoldersQuery } from "@/taskpane/services/folders.hook";
import { getSubFolders } from "@/taskpane/services/folders";
import { alpha, Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, List, ListItemButton, PaletteColor, Skeleton, Stack, styled, TextField, Theme, Tooltip, tooltipClasses, Typography, useTheme, Drawer, drawerClasses, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemIcon, ListItemSecondaryAction, Badge } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';

import { CLIENT_API_ALLOW_TO_AUTO_IMPORT } from "@/taskpane/config/client-allow-to-auto-import";

import styles from "./FoldersList.styles"

import type { SyncStatusTooltipProps, SyncStatus } from "./FoldersList.types";

/**
 * @description Tooltip embeding description of the synchronization status of a folder.
 */
const SyncStatusTooltip = styled(({ className, color = "secondary", ...props }: SyncStatusTooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} color={color} />
))(
  ({
    theme,
    color = "secondary",
  }: {
    theme: Theme;
    color: "primary" | "secondary" | "error" | "warning" | "info" | "success";
  }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: (theme.palette?.[color] as PaletteColor)?.light,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: (theme.palette?.[color] as PaletteColor)?.light,
      color: (theme.palette?.[color] as PaletteColor)?.main,
    },
  })
);

/**
 * @description Renders a single folder item in the folders list.
 *
 * @param {Object} props - The props for the component.
 * @param {Folder} props.folder - The folder object to render.
 * @param {() => void} props.onClick - The click event handler for the folder item.
 * @param {boolean} props.selected - Indicates whether the folder item is selected.
 *
 * @returns {JSX.Element} The rendered component.
 */
function FolderItem({ folder, onClick, selected }: { folder: Folder; onClick: () => void; selected: boolean }) {
  const navigate = useNavigate();

  const theme = useTheme();

  /**
   * @description Computes the synchronization status of the folder based on the presence and value of the key: dossier_import_partenaire_auto on the dossier object.
   *
   * @memoization
   * @dependency {folder?.dossier_id} - The ID of the dossier.
   * @dependency {subFolders} - The list of sous-dossiers.
   *
   * @returns {string} The synchronization status of the folder. Either "loading", "synced", "unsync", or "partialSynced".
   */
  const syncStatus = useMemo(() => {
    const synced = folder && folder?.dossier_import_partenaire_auto_status === "sync";
    if (synced) {
      return "synced";
    }
    const partialSynced = folder && folder?.dossier_import_partenaire_auto_status === "partial";
    if (partialSynced) {
      return "partialSynced";
    }
    const unsynced = !folder || !folder?.dossier_import_partenaire_auto_status || folder?.dossier_import_partenaire_auto_status === "unsync";
    if (unsynced) {
      return "unsync";
    }
    return "loading";

  }, [folder?.dossier_id, folder?.dossier_import_partenaire_auto_status]);

  /**
   * @description Computes the props for the sync icon based on the sync status.
   *
   * @memoization
   * @dependency {syncStatus} - The synchronization status of the folder. Either "loading", "synced", "unsync", or "partialSynced".
   *
   * @returns {SyncStatus} The props for the sync icon.
   */
  const getSyncStatus = useMemo(() => {
    switch (syncStatus) {
      case "synced":
        return {
          label: "Analyse automatique des documents présents dans le logiciel métier activée",
          color: "success",
          value: "synced",
        } as SyncStatus;
      case "unsync":
        return {
          label: "Pas d'analyse automatique des documents présents dans le logiciel métier",
          color: "error",
          value: "unsync",
        } as SyncStatus;
      case "partialSynced":
        return {
          label:
            "Analyse automatique des documents présents dans le logiciel métier restreint à certains sous-dossiers",
          color: "warning",
          value: "partialSynced",
        } as SyncStatus;
      default:
        return { label: "N/A", color: "secondary", value: "loading" } as SyncStatus;
    }
  }, [syncStatus]);

  return (
    <ListItemButton
      sx={styles.listItemButton(theme, alpha)}
      onClick={onClick}
      selected={selected}
    >
      <Grid container alignItems="center">

        {/* First column: Name and creation date */}
        <Grid item xs={9}>
          <Stack direction="row" alignItems="space-between" spacing={1}>

            {/* First column: Sync status icon */}
            <SyncStatusTooltip title={getSyncStatus.label} color={getSyncStatus.color} theme={theme}>
              <IconButton
                color={getSyncStatus.color}
                size="small"
                sx={styles.iconButton(getSyncStatus.color, selected, theme, alpha)}
              >
                <SyncAltIcon
                  fontSize="small"
                  sx={styles.syncIcon(getSyncStatus.color)}
                />
              </IconButton>
            </SyncStatusTooltip>

            <Stack sx={styles.dossierNameAndCreationDateContainer}>

              {/* First column: Folder name */}
              <Stack direction="row">
                <Tooltip title={folder?.name?.length > 30 ? folder?.name : ""}>
                  <Typography
                    fontWeight="bold"
                    fontSize={11}
                    noWrap
                    sx={styles.dossierName}
                  >
                    {folder?.name ?? "N/A"}
                  </Typography>
                </Tooltip>

                {folder.en_attente_choix_filiation_dossier && (
                  <Tooltip title="En attente de sélection des biens">
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        width: 8,
                        height: 8,
                        backgroundColor: 'orange',
                        borderRadius: '50%',
                        marginLeft: 1,
                      }}
                    />
                  </Tooltip>
                )}
              </Stack>

              {/* First column: Folder creation date */}
              <Typography noWrap fontSize={10} fontWeight="bold" color="black" sx={styles.creationDate}>
                Créé le {folder?.created_at.toLocaleDateString()} ({folder?.intervenants_notaires.join("/")})
              </Typography>

            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={3} sx={{ pl: 0 }}>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={1} wrap="nowrap">

            {/* Second column: Details button */}
            <Grid item>
              {selected && (
                <IconButton
                  size="small"
                  color="inherit"
                  sx={styles.controlCenterIcon(theme)}
                  edge="start"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/folder/${folder.dossier_id}/details`);
                  }}
                >
                  <ControlCenterIcon color={folder?.en_attente_choix_filiation_dossier ? "warning" : "inherit"} fontSize="small" sx={{ "&.MuiSvgIcon-root": { fontSize: "18px" } }} />
                </IconButton>
              )}
            </Grid>

            {/* Third column: ID and status */}
            <Grid item>
              <Stack alignItems="flex-end">

                <Typography color="black" sx={{ opacity: 0.5 }} fontWeight="bold" fontSize={11} noWrap>
                  {folder?.dossier_id ?? "N/A"}
                </Typography>

                <Typography color="black" sx={{ opacity: 0.5 }} fontWeight="bold" fontSize={11} noWrap>
                  {DOSSIER_STATUS_INOT?.[`${folder?.status as keyof typeof DOSSIER_STATUS_INOT}`] ?? "N/A"}
                </Typography>

              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItemButton>
  );
}

/**
 * Component to display a message when no folders are found.
 * @returns {JSX.Element} The rendered component.
 */
function NoFolders() {
  return (
    <Typography fontWeight="bold" color="grey.400" textAlign="center" p={3}>
      Aucun dossier correspondant à ces critères n'a été trouvé
    </Typography>
  );
}


/**
 * @description Main component to render the list of folders.
 * @returns {JSX.Element} The rendered component.
 */
export default function FolderList() {
  const [search, setSearch] = useState("");
  const [showMyFolders, setShowMyFolders] = useState(false);
  const [showInProgress, setShowInProgress] = useState(false);
  const [showFiliationChoiceButton, setShowFiliationChoiceButton] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const [currentEmailId, setCurrentEmailId] = useState<string | undefined>(undefined);
  const [hasAttachments, setHasAttachments] = useState(false);
  const [listHeight, setListHeight] = useState(0);
  const [isFiliationEmail, setIsFiliationEmail] = useState(false);
  const [filiationInfo, setFiliationInfo] = useState<{folderId?: string, subFolderId?: string} | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sousDossierWithChoiceFiliationNeededList, setSousDossierWithChoiceFiliationNeededList] = useState<SubFolderData[]>([]);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const isCheckingSousDossiersRef = useRef<boolean>(false);
  const navigate = useNavigate();

  const theme = useTheme();

  const [refreshCount, setRefreshCount] = useLocalStorage<number>("refreshCount", 0);
  const [isPopupBlockFor24Hour, setIsPopupBlockFor24Hour] = useLocalStorage<boolean | null>("isPopupBlockFor24Hour", null);
  const [isPopupBlockFor24HourSince, setIsPopupBlockFor24HourSince] = useLocalStorage<number | null>("isPopupBlockFor24HourSince", null);

  const size = useWindowSize();

  const { changeCounter, currentMailbox, setFiliationData } = useOfficeContext();

  const { isPolling, setPollingConfig, startPolling } = usePolling();

  const { data: foldersData, isLoading } = useGetFoldersQuery();
  const { data: userData } = useGetUserDataQuery();
  const { data: dataAttachments, refetch } = useQuery({
    queryKey: ["getAttachments", currentEmailId],
    queryFn: getAttachments,
    enabled: !!currentEmailId,
  });
  const { data: currentUser, isLoading: isLoadingCurrentUser } = useGetUserAttributesQuery();

  const folders = foldersData?.dossiers || [];

  const updateCurrentEmail = useCallback(async () => {
    if (currentMailbox?.item) {
      const newEmailId = currentMailbox.item.itemId;
      setCurrentEmailId(newEmailId);
    }
  }, [setCurrentEmailId, currentMailbox]);

  /**
   * @description Checks if the current email is a filiation email.
   * 
   * @returns {Promise<void>} A promise that resolves when the check is complete.
   */
  const checkForFiliationEmail = useCallback(() => {
    if (!currentMailbox?.item) {
      setIsFiliationEmail(false);
      setFiliationInfo(null);
      return;
    }

    const { body } = currentMailbox.item;
    
    body.getAsync(Office.CoercionType.Html, function (asyncResult: any) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        const bodyHtml = asyncResult.value;
        
        const element = document.createElement("div");
        element.innerHTML = bodyHtml;
        
        const jsonData = element.querySelector("#filiation-data, #x_filiation-data")?.textContent;
        
        if (!jsonData) {
          setIsFiliationEmail(false);
          setFiliationInfo(null);
          return;
        }
        
        try {
          const filiationData = JSON.parse(jsonData);
          const clientId = filiationData?.client_id;
          const folderId = filiationData?.dossier_id;
          const subFolderId = filiationData?.flow_id;
          
          if (!clientId || !folderId || !subFolderId) {
            setIsFiliationEmail(false);
            setFiliationInfo(null);
            return;
          }
          
          // Store filiation data in context for use in redirection
          setFiliationData({ ...filiationData });
          
          // Set local state for UI display
          setIsFiliationEmail(true);
          setFiliationInfo({ folderId, subFolderId });
        } catch (error) {
          console.error("[FoldersList] Error parsing filiation data:", error);
          setIsFiliationEmail(false);
          setFiliationInfo(null);
        }
      } else {
        setIsFiliationEmail(false);
        setFiliationInfo(null);
      }
    });
  }, [currentMailbox, setFiliationData]);

  /**
   * @description Filters the folders based on the provided criteria.
   *
   * @param {Folder[]} folders - The list of folders to filter.
   * @param {boolean} showMyFolders - Indicates whether to show only the user's folders.
   * @param {boolean} showInProgress - Indicates whether to show only folders in progress.
   * @param {string} search - The search term to filter folders by name or dossier ID.
   * @param {UserData} userData - The user data containing information about the user.
   *
   * @returns {Folder[]} - The filtered list of folders.
   */
  const filteredFolders = useMemo(
    () =>
      (folders ?? ([] as Folder[])).filter((folder) => {
        const matchesSearch =
          folder.name.toLowerCase().includes(search.toLowerCase()) ||
          folder.dossier_id.toLowerCase().includes(search.toLowerCase());
        const matchesMyFolders =
          !showMyFolders ||
          (userData?.user_info?.initiales_inot &&
            folder?.dossier_intervenants_notaire.includes(userData.user_info.initiales_inot));
        const matchesInProgress = !showInProgress || folder.status === "en_cours";
        return matchesSearch && matchesMyFolders && matchesInProgress;
      }),
    [folders, showMyFolders, showInProgress, search, userData]
  );

  /**
   * @description Computes for each render if the modal allowing the user to select their initials should be displayed.
   *
   * @dependency {isPolling} - Indicates whether the polling is active.
   * @dependency {currentUser} - The current user data. If the user has no partner ID, that's meaning that the user has not selected or found their initials yet.
   */
  const shouldDisplaySelectionUserInfosModal = useMemo(() => {
    return !isLoadingCurrentUser && !currentUser?.partenaireId && !isPolling
  }, [isPolling, currentUser, isLoadingCurrentUser]);

  /**
   * @description Computes for each render if the modal allowing the user to select their initials should be block during 24 hour because of exceed the limit of refresh count (2 times).
   *
   * @dependency {refreshCount} -
   * @dependency {isPopupBlockFor24Hour} -
   * @dependency {isPopupBlockFor24HourSince} -
   */
  const shouldBlockSelectionUserInfosModal = useMemo(() => {
    if (refreshCount >= 2 && isPopupBlockFor24Hour && isPopupBlockFor24HourSince) {
      const now = Date.now();
      const diffBetweenNowAndIsBlockedSinceInMs = now - isPopupBlockFor24HourSince;
      const diffBetweenNowAndIsBlockedSinceInSeconds = diffBetweenNowAndIsBlockedSinceInMs / 1000;
      const diffBetweenNowAndIsBlockedSinceIsLessThan24Hour = diffBetweenNowAndIsBlockedSinceInSeconds <= 24 * 60 * 60;

      if (diffBetweenNowAndIsBlockedSinceIsLessThan24Hour) {
        return true;
      } else {
        setRefreshCount(0);
        setIsPopupBlockFor24Hour(false);
        setIsPopupBlockFor24HourSince(null);

        return false;
      }
    } else {
      return false;
    }
  }, [refreshCount, isPopupBlockFor24Hour, isPopupBlockFor24HourSince, setRefreshCount, setIsPopupBlockFor24Hour, setIsPopupBlockFor24HourSince]);

  const displayModal = shouldBlockSelectionUserInfosModal ? false : shouldDisplaySelectionUserInfosModal;


  /**
   * @description Effect hook to update the current email ID and refetch the attachments when the current email ID changes.
   *
   * @effect
   * @dependency {updateCurrentEmail} - The function to update the current email ID.
   */
  useEffect(() => {
    updateCurrentEmail();
    const intervalId = setInterval(() => {
      updateCurrentEmail();
    }, 5000); // Check for updates every 5 seconds
    return () => clearInterval(intervalId);
  }, [updateCurrentEmail]);

  /**
   * @description Effect hook to refetch the attachments when the current email ID changes.
   *
   * @effect
   * @dependency {currentEmailId} - The current email ID.
   */
  useEffect(() => {
    if (currentEmailId) {
      refetch();
    }
  }, [currentEmailId, refetch]);

  /**
   * @description Effect hook to update the hasAttachments state based on the dataAttachments.
   *
   * @effect
   * @dependency {dataAttachments} - The attachments data.
   */
  useEffect(() => {
    setHasAttachments(!!dataAttachments && dataAttachments?.attachments && dataAttachments?.attachments?.length > 0);
  }, [dataAttachments]);

  /**
   * @description Effect hook to update the current email ID when the changeCounter or currentMailbox changes.
   *
   * @effect
   * @dependency {changeCounter} - The change counter.
   * @dependency {currentMailbox} - The current mailbox.
   */
  useEffect(() => {
    updateCurrentEmail();
    checkForFiliationEmail();
  }, [changeCounter, currentMailbox, checkForFiliationEmail]);

  // Update list height when container size changes
  useEffect(() => {
    const updateHeight = () => {
      if (listContainerRef.current) {
        setListHeight(listContainerRef.current.clientHeight);
      }
    };

    // Initial height
    updateHeight();

    // Create ResizeObserver to watch for container size changes
    const resizeObserver = new ResizeObserver(updateHeight);
    if (listContainerRef.current) {
      resizeObserver.observe(listContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  /**
   * @description Effect hook to update the selected folder when the filtered folders change.
   *
   * @effect
   * @dependency {selectedFolder} - The currently selected folder.
   * @dependency {filteredFolders} - The filtered list of folders.
   */
  useEffect(() => {
    if (!selectedFolder) {
      return;
    }
    if (!filteredFolders.find((f) => f.dossier_id === selectedFolder.dossier_id)) {
      setSelectedFolder(undefined);
    }
  }, [selectedFolder, filteredFolders]);

  /**
   * @description Effect hook to check the list of dossiers for the key en_attente_choix_filiation_dossier.
   *
   * @effect
   * @dependency {foldersData} - The data containing the list of dossiers.
   */
  useEffect(() => {
    if (foldersData?.dossiers) {
      const folderWithFiliationStatus = foldersData.dossiers.find(
        (folder) => folder.en_attente_choix_filiation_dossier
      );
      
      // Only update state if it's different from current state to prevent loops
      const shouldShowButton = !!folderWithFiliationStatus;
      if (shouldShowButton !== showFiliationChoiceButton) {
        if (folderWithFiliationStatus) {
          // setFiliationList(foldersData.dossiers.filter(
          //   (folder) => folder.en_attente_choix_filiation_dossier
          // ));

          // Need to update SousDossierWithChoiceFiliationNeededList by calling the API for each folder with 
          // en_attente_choix_filiation_dossier set to true
          for (const folder of foldersData.dossiers) {
            if (folder.en_attente_choix_filiation_dossier) {
              var all_sous_dossiers_with_filiation: SubFolderData[] = [];
              getSubFolders(folder.dossier_id).then((subFoldersData) => {
                if (subFoldersData?.sous_dossiers) {
                  const sousDossierWithFiliation = subFoldersData.sous_dossiers.find(
                    (sousDossier) => sousDossier.en_attente_choix_filiation_sous_dossier
                  );
                  
                  if (sousDossierWithFiliation) {
                    all_sous_dossiers_with_filiation.push(sousDossierWithFiliation);
                  }
                }
              });
              setSousDossierWithChoiceFiliationNeededList(all_sous_dossiers_with_filiation);
            }
          }
        }
        setShowFiliationChoiceButton(shouldShowButton);
      }
    }
  }, [foldersData]);

  const shouldOverrideRenderInitialesDialog = useMemo(() => {
    if (userData?.user_info?.client_api && CLIENT_API_ALLOW_TO_AUTO_IMPORT.includes(userData.user_info.client_api)) {
      return true;
    } else {
      return false;
    }
  }, [userData]);

  /**
   * @description Renders a row in the FoldersList component. Essential for the react-window FixedSizeList component.
   *
   * @param index - The index of the row.
   * @param style - The CSS styles to apply to the row (generated by react-window).
   *
   * @callback
   * @dependency {filteredFolders} - The filtered list of dossiers.
   * @dependency {isLoading} - Indicates whether the dossiers are loading.
   * @dependency {selectedFolder} - The currently selected dossier.
   */
  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      if (isLoading) {
        return (
          <div style={style}>
            <ListItemButton sx={{ opacity: 1 - index * 0.1 }}>
              <Grid container alignItems="center" spacing={1}>
                {/* First column skeleton */}
                <Grid item xs={7}>
                  <Stack spacing={0.5}>
                    <Skeleton animation="wave" variant="text" width="90%" height={20} />
                    <Skeleton animation="wave" variant="text" width="70%" height={14} />
                  </Stack>
                </Grid>

                {/* Second column skeleton */}
                <Grid item xs={3}>
                  <Stack alignItems="flex-end" spacing={0.5}>
                    <Skeleton animation="wave" variant="text" width="80%" height={20} />
                    <Skeleton animation="wave" variant="text" width="60%" height={16} />
                  </Stack>
                </Grid>

                {/* Third column skeleton */}
                <Grid item xs={2} sx={{ textAlign: "right", pl: 0 }}>
                  <Skeleton animation="wave" variant="rectangular" width={80} height={32} sx={{ borderRadius: 1 }} />
                </Grid>
              </Grid>
            </ListItemButton>
            <Divider sx={{ width: "60%", mx: "auto", borderColor: "grey.300", opacity: 1 - index * 0.1 }} />
          </div>
        );
      }

      const folder = filteredFolders[index];
      return (
        <div style={style}>
          <FolderItem
            folder={folder}
            onClick={() => setSelectedFolder(folder)}
            selected={folder.dossier_id === selectedFolder?.dossier_id}
          />
        </div>
      );
    },
    [filteredFolders, isLoading, selectedFolder]
  );

  return (
    <Box sx={styles.pageContainer}>
      {/* Combined Filiation Button - Priority to Email Filiation */}
      {(isFiliationEmail && filiationInfo) || (showFiliationChoiceButton) ? (
        <Box sx={{ position: 'absolute', top: userData?.user_info?.role === "ADMIN" ? 16 : 16, right: 8, zIndex: 5 }}>
          {isFiliationEmail && filiationInfo ? (
            <Tooltip title="Accéder à la filiation de cet email">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<EmailOutlined />}
                onClick={() => navigate(`/folder/${filiationInfo.folderId}/filiation/${filiationInfo.subFolderId}`)}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Choisir les biens
              </Button>
            </Tooltip>
          ) : null}
        </Box>
      ) : null}

    {!(isFiliationEmail && filiationInfo) || !(showFiliationChoiceButton) ? (
      <Box sx={{ position: 'absolute', top: 2, right: { xs: 2, sm: 16 }, zIndex: 3 }}>
        <Tooltip title="Dossier(s) en attente de sélection des biens">
          <IconButton
              size="medium"
              onClick={() => setDrawerOpen(true)}
              disabled={!showFiliationChoiceButton}
            >
              {showFiliationChoiceButton ? <Badge variant="dot" color="warning" sx={{ xs: { width: 8, height: 8 }, sm: { width: 16, height: 16 } }}><NotificationsIcon sx={{width: { xs: 28, sm: 32 }, height: { xs: 28, sm: 32 }}} /></Badge> : <NotificationsIcon sx={{width: { xs: 28, sm: 32 }, height: { xs: 28, sm: 32 }}} />}
            </IconButton>
          </Tooltip>
      </Box>
    ) : null}

    {(!(isFiliationEmail && filiationInfo) || !(showFiliationChoiceButton)) ? (
          <Box sx={{ position: 'absolute', top: 2, right: { xs: 2, sm: 16 }, zIndex: 3 }}>
            <Tooltip title="Dossier(s) en attente de sélection des biens">
              <IconButton
                  size="medium"
                  onClick={() => setDrawerOpen(true)}
                  disabled={!showFiliationChoiceButton}
                >
                  {showFiliationChoiceButton ? <Badge variant="dot" color="warning" sx={{ xs: { width: 8, height: 8 }, sm: { width: 16, height: 16 } }}><NotificationsIcon sx={{width: { xs: 28, sm: 32 }, height: { xs: 28, sm: 32 }}} /></Badge> : <NotificationsIcon sx={{width: { xs: 28, sm: 32 }, height: { xs: 28, sm: 32 }}} />}
                </IconButton>
              </Tooltip>
          </Box>
        ) : null}

      {userData?.user_info?.role === "ADMIN" && (
        <Box sx={{ position: 'absolute', right: showFiliationChoiceButton && (!(isFiliationEmail && filiationInfo) || !(showFiliationChoiceButton)) ?  { xs: 42, sm: 56 } : { xs: 154, sm: 154 }, top: showFiliationChoiceButton && (!(isFiliationEmail && filiationInfo) || !(showFiliationChoiceButton)) ? { xs: 8, sm: 10 } : { xs: 14, sm: 0 }, zIndex: 5 }}>
          <Tooltip title="Accéder au tableau de bord">
            <IconButton onClick={() => navigate('/dashboard')} size="small">
              <SpaceDashboardIcon color="primary" sx={{ xs: { width: 8, height: 8 }, sm: { width: 16, height: 16 } }}/>
            </IconButton>
            </Tooltip>
        </Box>
      )}

      {/* Access blocked message */}
      {foldersData?.client_blocked && (
        <Box
          sx={styles.blockedMessageContainer}
        >
          <Box
            sx={styles.blockedReasonText}
          >
            <Typography variant="h6" color="primary" fontWeight="bold">
              {foldersData.client_blocked_reason || "Accès bloqué"}
            </Typography>
          </Box>
        </Box>
      )}

      {/* Dossiers search bar */}
      <Box sx={styles.searchContainer}>
        <TextField
          autoFocus
          sx={styles.searchField}
          fullWidth
          size="small"
          variant="outlined"
          margin="none"
          placeholder="Rechercher un dossier ..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            size: "small",
            endAdornment: search && (
              <IconButton size="small" onClick={() => setSearch("")}>
                <CloseOutlined />
              </IconButton>
            ),
          }}
        />

        {/* Dossiers filter checkboxes */}
        <Stack pl={1.5} mt={0.5} mb={0.5} direction="row">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showMyFolders}
                color="primary"
                onChange={(e) => setShowMyFolders(e.target.checked)}
                sx={{ mr: 0.5 }}
              />
            }
            label="Mes dossiers"
            slotProps={{ typography: { fontSize: 12 } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={showInProgress}
                onChange={(e) => setShowInProgress(e.target.checked)}
                sx={{ mr: 0.5 }}
              />
            }
            label="En cours"
            slotProps={{ typography: { fontSize: 12 } }}
          />
        </Stack>
      </Box>

      {/* Folders list */}
      {(isLoading || !!filteredFolders.length) && (
        <Box ref={listContainerRef} sx={styles.listContainer}>
          <FixedSizeList
            height={listHeight}
            itemSize={50}
            itemCount={isLoading ? 8 : filteredFolders.length}
            width="100%"
            style={styles.listStyles}
          >
            {Row}
          </FixedSizeList>
        </Box>
      )}

      {/* Folders list if content is empty */}
      {!isLoading && !filteredFolders.length && (
        <List
          sx={{
            ...styles.listStyles,
            height: "100%",
          }}
        >
          <NoFolders />
        </List>
      )}

      {/* Bottom action bar (Pieces-jointes, Glissez-déposez, Espace notarial) */}
      <Box sx={styles.buttonsGrid}>
        <Grid container spacing={{ xs: 2 }} columns={{ xs: 3 }} sx={styles.buttonsContainer}>
          <Grid item xs={1} sx={styles.buttonItem}>
            <WarningTooltip
              title={
                !selectedFolder
                  ? "Vous devez sélectionner un dossier"
                  : hasAttachments
                    ? ""
                    : "Aucune pièce jointe disponible"
              }
            >
              <span>
                <Button
                  size="large"
                  fullWidth
                  disabled={!selectedFolder || !hasAttachments}
                  onClick={() => navigate(`/folder/${selectedFolder?.dossier_id}/attachment`)}
                  sx={size?.width ? styles.bottomNavButtons(size.width) : {}}
                >
                  Pièces jointes
                </Button>
              </span>
            </WarningTooltip>
          </Grid>
          <Grid item xs={1} sx={styles.buttonItem}>
            <WarningTooltip title={!selectedFolder ? "Vous devez sélectionner un dossier" : ""}>
              <span>
                <Button
                  size="large"
                  fullWidth
                  disabled={!selectedFolder}
                  onClick={() => navigate(`/folder/${selectedFolder?.dossier_id}/drag-and-drop`)}
                  sx={size?.width ? styles.bottomNavButtons(size.width) : {}}
                >
                  Glissez-déposez
                </Button>
              </span>
            </WarningTooltip>
          </Grid>
          <Grid item xs={1} sx={styles.buttonItem}>
            <WarningTooltip title={!selectedFolder ? "Vous devez sélectionner un dossier" : ""}>
              <span>
                <Button
                  size="large"
                  fullWidth
                  disabled={!selectedFolder}
                  onClick={() => navigate(`/folder/${selectedFolder?.dossier_id}/notary-space`)}
                  sx={size?.width ? styles.bottomNavButtons(size.width) : {}}
                >
                  Espace-notarial
                </Button>
              </span>
            </WarningTooltip>
          </Grid>
        </Grid>
      </Box>

      {/* Select partenaire modal */}
      {shouldOverrideRenderInitialesDialog ?
        (
          <SelectInitialesDialog
            id="select-initiales-dialog"
            open={!!displayModal}
            keepMounted={false}
            setPollingConfig={setPollingConfig}
            startPolling={startPolling}
            isPolling={isPolling}
          />
        )
        :
        null
      }

      <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)} hideBackdrop
        PaperProps={{
          sx: { width: "90%", maxWidth: 400, backgroundColor: "rgb(255, 255, 255, 0.8)", borderTopLeftRadius: 4, borderTopRightRadius: 0, borderBottomLeftRadius: 4, borderBottomRightRadius: 0 },
        }}
      >
          <Box sx={{ width: '100%', backgroundColor: "transparent" }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 1,
              py: 1,
              borderBottom: '1px solid',
              borderColor: 'divider',
            }}>
            <Typography color="primary" variant="h6" fontWeight="bold" sx={{fontSize: '14px' }}>
              Dossier(s) en attente de choix
            </Typography>
            <IconButton onClick={() => setDrawerOpen(false)} size="small">
              <CloseOutlined sx={{ "&:hover": { color: "error.main" } }} />
            </IconButton>
            </Box>

          <List dense disablePadding sx={{ }}>
            {sousDossierWithChoiceFiliationNeededList?.map((sous_dossier) => (
              <ListItemButton key={`sous-dossier-${sous_dossier.sous_dossier_id}-${sous_dossier.dossier_id}`} divider disableGutters alignItems="flex-start" dense onClick={() => navigate(`/folder/${sous_dossier.dossier_id}/filiation/${sous_dossier.sous_dossier_id}`)} sx={{ "&:hover": { backgroundColor: "rgba(27,72,158,0.05)" } }}>
                <ListItem dense alignItems="flex-start" sx={{ backgroundColor: "transparent" }}
                  secondaryAction={
                    <IconButton edge="end" sx={{ color: "#1C252E" }}>
                      <LaunchIcon />
                    </IconButton>
                  }>
                    <ListItemText
                      primary={
                        <>
                          <Box sx={{ m: 0 }}>
                              <Typography
                                  variant="caption"
                                  display="block"
                                  sx={{
                                      marginBottom: -0.5,
                                      overflow: "hidden",
                                      fontSize: "11px",
                                      textOverflow: "ellipsis",
                                      color: "#1C252E"
                                  }}
                                  title={sous_dossier.dossier_id}
                              >
                                  {sous_dossier.dossier_id ?? ""}
                              </Typography>
                              <Typography
                                  variant="subtitle2"
                                  sx={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      color: "#1C252E"
                                  }}
                                  title={sous_dossier.dossier_name}
                              >
                                  {sous_dossier.dossier_name ?? ""}
                              </Typography>
                              </Box>
                        </>
                      }
                      secondary={
                        <Stack spacing={0.5}>
                          <Typography variant="body2" sx={{ color: '#637381', display: 'inline', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', fontSize: '12px' }}>
                            {sous_dossier.sous_dossier_name}
                          </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ color: '#637381', display: 'inline', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', fontSize: '12px' }}
                              title={sous_dossier.en_attente_choix_filiation_sous_dossier_by_details?.map(detail => detail.email).join(', ') || 'Aucun email'}
                          >
                            {sous_dossier.en_attente_choix_filiation_sous_dossier_by_details?.map(detail => detail.email.split('@')[0]).join(', ') || 'Aucun email'}
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
              </ListItemButton>
            ))}
          </List>
        </Box>
        <Box sx={{ backgroundColor: "rgb(255, 255, 255, 0.15)", backdropFilter: "blur(4px)", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}></Box>
      </Drawer>
    </Box>
  );
}
