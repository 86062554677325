import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Close icon for notifications's snackbars.
 * @param {Object} props - The props for the component.
 * @param {() => void} props.handleClose - Function to close the snackbar.
 * @param {boolean} [props.display=true] - Boolean to display the close icon or not.
 * @returns {JSX.Element | null} The rendered component or null.
 */
export function SnackbarCloseIcon({ handleClose, display = true }: { handleClose: () => void; [key: string]: any }) {
  if (display) {
    return (
      <IconButton
        aria-label="close"
        color="default"
        onClick={handleClose}
        sx={{ position: "absolute", top: -2.5, right: -2.5, color: "#637381" }}
      >
        <CloseIcon />
      </IconButton>
    );
  } else {
    return null;
  }
}
