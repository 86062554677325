import React, { useEffect, useState } from "react";
import { clearSenstiveDataFromLocalStorage } from "@/taskpane/utils/storage";

export default function Logo({ width = '190px' }) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter >= 5) {
      clearSenstiveDataFromLocalStorage();
      window.location.reload();
    }
  }, [counter]);

  return <img src="/assets/logo-hero.svg" width={width} onClick={() => setCounter(counter + 1)} />;
}
