import React, { useState } from "react";
import { 
  Box, 
  CircularProgress, 
  Grid, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography,
  TableSortLabel,
  TablePagination,
  Button,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { Widget } from "@/taskpane/components/widget";
import { useGetDashboardDataQuery } from "@/taskpane/services/dashboard.hook";

type Order = 'asc' | 'desc';
type DossierOrderBy = 'id' | 'name' | 'last_activity' | 'status';
type UserOrderBy = 'email' | 'role' | 'last_active' | 'register_on';

export default function Dashboard() {
  const navigate = useNavigate();
  const { data: dashboardData, isLoading } = useGetDashboardDataQuery();

  // Dossiers table state
  const [dossierOrder, setDossierOrder] = useState<Order>('desc');
  const [dossierOrderBy, setDossierOrderBy] = useState<DossierOrderBy>('last_activity');
  const [dossierPage, setDossierPage] = useState(0);
  const [dossierRowsPerPage, setDossierRowsPerPage] = useState(10);

  // Users table state
  const [userOrder, setUserOrder] = useState<Order>('desc');
  const [userOrderBy, setUserOrderBy] = useState<UserOrderBy>('last_active');
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);

  const handleDossierSort = (property: DossierOrderBy) => {
    const isAsc = dossierOrderBy === property && dossierOrder === 'asc';
    setDossierOrder(isAsc ? 'desc' : 'asc');
    setDossierOrderBy(property);
  };

  const handleUserSort = (property: UserOrderBy) => {
    const isAsc = userOrderBy === property && userOrder === 'asc';
    setUserOrder(isAsc ? 'desc' : 'asc');
    setUserOrderBy(property);
  };

  const handleDossierChangePage = (event: unknown, newPage: number) => {
    setDossierPage(newPage);
  };

  const handleDossierChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDossierRowsPerPage(parseInt(event.target.value, 10));
    setDossierPage(0);
  };

  const handleUserChangePage = (event: unknown, newPage: number) => {
    setUserPage(newPage);
  };

  const handleUserChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserRowsPerPage(parseInt(event.target.value, 10));
    setUserPage(0);
  };

  const parseDateDDMMYYYY = (dateStr: string) => {
    if (!dateStr) return 0;
    const [datePart, timePart] = dateStr.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    if (timePart) {
      const [hours, minutes, seconds] = timePart.split(':').map(Number);
      return new Date(year, month - 1, day, hours, minutes, seconds).getTime();
    }
    return new Date(year, month - 1, day).getTime();
  };

  const sortDossiers = (dossiers: any[]) => {
    return [...dossiers].sort((a, b) => {
      let aValue = a[dossierOrderBy];
      let bValue = b[dossierOrderBy];
      
      if (dossierOrderBy === 'last_activity') {
        aValue = parseDateDDMMYYYY(aValue);
        bValue = parseDateDDMMYYYY(bValue);
      }

      if (dossierOrder === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
    });
  };

  const sortUsers = (users: any[]) => {
    return [...users].sort((a, b) => {
      let aValue = a[userOrderBy];
      let bValue = b[userOrderBy];
      
      if (userOrderBy === 'last_active' || userOrderBy === 'register_on') {
        aValue = parseDateDDMMYYYY(aValue);
        bValue = parseDateDDMMYYYY(bValue);
      }

      if (userOrder === 'asc') {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
    });
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">
          Impossible de charger les données du tableau de bord
        </Typography>
      </Box>
    );
  }

  const { stats } = dashboardData;

  return (
    <Box sx={{ p: 3, pb: 6 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ mr: 2 }}
        >
          Retour
        </Button>
        <Typography variant="h4">
          Tableau de Bord
        </Typography>
      </Box>

      {/* Widgets Section */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <Widget
            title="Dossiers Analysés"
            total={stats?.dossiers_analyzed?.total ?? 0}
            percent={stats?.dossiers_analyzed?.percent_change ?? 0}
            chart={{
              categories: stats?.dossiers_analyzed?.monthly_data?.map(d => d.month) ?? [],
              series: [{
                name: "Dossiers",
                data: stats?.dossiers_analyzed?.monthly_data?.map(d => d.count) ?? [],
              }],
            }}
            color="primary"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Widget
            title="Documents Analysés"
            total={stats?.documents_analyzed?.total ?? 0}
            percent={stats?.documents_analyzed?.percent_change ?? 0}
            chart={{
              categories: stats?.documents_analyzed?.monthly_data?.map(d => d.month) ?? [],
              series: [{
                name: "Documents",
                data: stats?.documents_analyzed?.monthly_data?.map(d => d.count) ?? [],
              }],
            }}
            color="info"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Widget
            title="Documents Créés"
            total={stats?.documents_created?.total ?? 0}
            percent={stats?.documents_created?.percent_change ?? 0}
            chart={{
              categories: stats?.documents_created?.monthly_data?.map(d => d.month) ?? [],
              series: [{
                name: "Documents",
                data: stats?.documents_created?.monthly_data?.map(d => d.count) ?? [],
              }],
            }}
            color="success"
          />
        </Grid>
      </Grid>

      {/* Tables Section */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {/* Derniers Dossiers Table */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Derniers Dossiers
            </Typography>
            <TableContainer sx={{ maxHeight: '300px' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={dossierOrderBy === 'id'}
                        direction={dossierOrderBy === 'id' ? dossierOrder : 'asc'}
                        onClick={() => handleDossierSort('id')}
                      >
                        ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={dossierOrderBy === 'name'}
                        direction={dossierOrderBy === 'name' ? dossierOrder : 'asc'}
                        onClick={() => handleDossierSort('name')}
                      >
                        Nom
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={dossierOrderBy === 'last_activity'}
                        direction={dossierOrderBy === 'last_activity' ? dossierOrder : 'asc'}
                        onClick={() => handleDossierSort('last_activity')}
                      >
                        Dernière Activité
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboardData.recent_dossiers?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        Aucun dossier récent
                      </TableCell>
                    </TableRow>
                  ) : (
                    sortDossiers(dashboardData.recent_dossiers ?? [])
                      .slice(dossierPage * dossierRowsPerPage, dossierPage * dossierRowsPerPage + dossierRowsPerPage)
                      .map((dossier) => (
                        <TableRow key={dossier.id}>
                          <TableCell>{dossier.id}</TableCell>
                          <TableCell>{dossier.name}</TableCell>
                          <TableCell>{dossier.last_activity}</TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={dashboardData.recent_dossiers?.length ?? 0}
              rowsPerPage={dossierRowsPerPage}
              page={dossierPage}
              onPageChange={handleDossierChangePage}
              onRowsPerPageChange={handleDossierChangeRowsPerPage}
            />
          </Paper>
        </Grid>

        {/* Users Table */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Liste des Utilisateurs
            </Typography>
            <TableContainer sx={{ maxHeight: '300px' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={userOrderBy === 'email'}
                        direction={userOrderBy === 'email' ? userOrder : 'asc'}
                        onClick={() => handleUserSort('email')}
                      >
                        Nom
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={userOrderBy === 'role'}
                        direction={userOrderBy === 'role' ? userOrder : 'asc'}
                        onClick={() => handleUserSort('role')}
                      >
                        Rôle
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={userOrderBy === 'last_active'}
                        direction={userOrderBy === 'last_active' ? userOrder : 'asc'}
                        onClick={() => handleUserSort('last_active')}
                      >
                        Dernière Activité
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={userOrderBy === 'register_on'}
                        direction={userOrderBy === 'register_on' ? userOrder : 'asc'}
                        onClick={() => handleUserSort('register_on')}
                      >
                        Date d'inscription
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboardData.user_activities?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        Aucun utilisateur actif
                      </TableCell>
                    </TableRow>
                  ) : (
                    sortUsers(dashboardData.user_activities ?? [])
                      .slice(userPage * userRowsPerPage, userPage * userRowsPerPage + userRowsPerPage)
                      .map((user) => (
                        <TableRow key={user.email}>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.role}</TableCell>
                          <TableCell>{user.last_active || ''}</TableCell>
                          <TableCell>{user.register_on || ''}</TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              component="div"
              count={dashboardData.user_activities?.length ?? 0}
              rowsPerPage={userRowsPerPage}
              page={userPage}
              onPageChange={handleUserChangePage}
              onRowsPerPageChange={handleUserChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}