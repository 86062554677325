import { Dataroom } from "@/taskpane/types/notary-space";
import { CloseOutlined } from "@mui/icons-material";
import { Card, FormControlLabel, IconButton, Radio, Stack, TextField, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import styles from "./NotarySpaceDataroom.styles";

export interface NotarySpaceDataroomProps {
  datarooms: Dataroom[];
  selectedDataroom?: string;
  setSelectedDataroom: (dataroom: string) => void;
}

export default function NotarySpaceDataroom({
  datarooms,
  selectedDataroom,
  setSelectedDataroom,
}: NotarySpaceDataroomProps) {
  const [search, setSearch] = useState("");

  const filteredDatarooms = useMemo(
    () => datarooms?.filter((dr) => dr.dataroom_name.toLowerCase().includes(search.toLowerCase())) || [],
    [datarooms, search]
  );

  return (
    <Stack sx={styles.container}>
      <Card sx={styles.card}>
        <Stack sx={styles.content}>
          <TextField
            label="Rechercher une dataroom..."
            variant="outlined"
            size="small"
            fullWidth
            sx={styles.searchField}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: search && (
                <IconButton size="small" onClick={() => setSearch("")}>
                  <CloseOutlined />
                </IconButton>
              ),
            }}
          />

          <Stack sx={styles.dataroomList}>
            {!filteredDatarooms.length && (
              <Typography sx={styles.noResults}>
                Aucune dataroom correspondant à ces critères n'a été trouvée
              </Typography>
            )}
            {filteredDatarooms.map((dr) => (
              <FormControlLabel
                key={dr.dataroom_id}
                sx={styles.formControl}
                control={
                  <Radio
                    size="small"
                    checked={selectedDataroom === dr.dataroom_id}
                    color="secondary"
                    onChange={() => setSelectedDataroom(dr.dataroom_id)}
                  />
                }
                label={dr.dataroom_name}
                slotProps={{ typography: styles.label }}
              />
            ))}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
}
