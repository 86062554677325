import { ACCEPTED_MIME_MAPPING } from "@/taskpane/config/mime-accepted";


// Function to rename the file extension to .pdf in lowercase
export function renamePdfExtension(fileName: string) {
    const extensionIndex = fileName.lastIndexOf('.');
  
    if (extensionIndex >= 0) {
      const basename = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex);
  
      if (extension.toLowerCase() === '.pdf') {
        return `${basename}.pdf`;
      }
    }
    // Return the original file name if the extension is not .pdf
    return fileName;
  }



export function renameFileExtension(fileName: string): string {
  const extensionIndex = fileName.lastIndexOf('.');

  if (extensionIndex >= 0) {
    const basename = fileName.substring(0, extensionIndex);
    const extension = fileName.substring(extensionIndex);

    if (Object.keys(ACCEPTED_MIME_MAPPING).includes(extension.toLowerCase())) {
      return `${basename}${extension}`;
    }
  }
  // Return the original file name if the extension is not .pdf
  return fileName;
}