import React, { useCallback, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { SubFolder } from "@/taskpane/types/folder";
import { UploadableFile } from "@/taskpane/types/file";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import LoadingBar from "@/taskpane/components/loading-bar/LoadingBar";
import LoadingButton from "@/taskpane/components/button/LoadingButton";
import { useUploadFilesMutation } from "@/taskpane/services/upload.hook";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";
import { useGetFoldersQuery, useGetSubFoldersQuery } from "@/taskpane/services/folders.hook";
import { enqueueConfirmSnackbar } from "@/taskpane/components/snackbar-provider/SnackbarProvider";
import { Box, Button, Card, CircularProgress, FormControlLabel, IconButton, Radio, Stack, Typography } from "@mui/material";


export interface FolderPageLayoutProps {
  onSelectSubFolder: (subfolder: SubFolder) => void;
  selectedSubfolder?: SubFolder;
  children?: React.ReactNode;
  canValidate?: boolean;
  onValidate?: () => void;
  onUpload?: () => void;
  files: UploadableFile[];
  folderId?: string;
}

export default function FolderPageLayout({
  onSelectSubFolder,
  selectedSubfolder,
  children,
  canValidate,
  onValidate,
  onUpload,
  files,
  folderId,
}: FolderPageLayoutProps) {
  const { data: folders } = useGetFoldersQuery();
  const { data: subFolders, isFetching: subFoldersLoading } = useGetSubFoldersQuery(folderId || "");
  const [uploadFilesLoading, setUploadFilesLoading] = useState(false);
  const uploadFiles = useUploadFilesMutation({
    onSuccess: () => {
      enqueueConfirmSnackbar(
        "Envoi terminé, l’analyse est en cours et les résultats seront directement intégrés dans iNot.",
        () => {
          setUploadFilesLoading(false);
          onValidate?.();
        },
        "Compris",
        {
          persist: true
        }
      );
    },
    onError: () => {
      setUploadFilesLoading(false);
      enqueueSnackbar("Un problème est survenue", {
        variant: "error",
        action: (snackbarId: SnackbarKey | undefined) => (
          <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
        )
      });
      onValidate?.();
    },
  });
  const navigate = useNavigate();

  const handleValidate = useCallback(() => {
    if (!canValidate) {
      return;
    }
    onUpload?.();
    uploadFiles.mutate({ folderId: folderId!, subFolderId: selectedSubfolder!.sous_dossier_id, files });
    setUploadFilesLoading(true);
  }, [canValidate]);

  const currentFolder = useMemo(() => folders?.find((f) => f.dossier_id === folderId), [folders, folderId]);

  const loadingState = useMemo(() => {
    return uploadFiles.isPending || uploadFilesLoading;
  }, [uploadFiles.isPending, uploadFilesLoading]);

  return (
    <Stack id="folder-page-layout" p={0} gap={1} height={0.92}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {/* <Logo /> */}
        {!loadingState && (
          <Button onClick={() => navigate(-1)} sx={{ position: "absolute", bottom: 20, left: 20 }}>
            Retour
          </Button>
        )}
      </Stack>
      <Typography fontSize={12} color="secondary.500" fontWeight="bold" sx={{ maxWidth: "75vw" }} noWrap>
        Dossier: {currentFolder?.name}
      </Typography>
      <Box sx={{ display: "grid", gridTemplateRows: "1fr 1fr", height: "100%", minHeight: 0 }}>
        <Box minHeight={0}>{children}</Box>
        <Stack minHeight={0} mt={1}>
          <Typography fontWeight="bold" fontSize={14} color="primary.dark">
            Sélection du sous-dossier iNot :
          </Typography>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              opacity: loadingState ? 0.5 : 1,
              pointerEvents: loadingState ? "none" : "auto",
              overflowY: "auto",
            }}
          >
            {subFoldersLoading && (
              <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
                <CircularProgress />
              </Stack>
            )}
            {subFolders?.map((sf) => (
              <FormControlLabel
                key={sf.sous_dossier_id}
                control={
                  <Radio
                    size="small"
                    checked={selectedSubfolder?.sous_dossier_id === sf?.sous_dossier_id}
                    color="secondary"
                    onChange={() => onSelectSubFolder(sf)}
                  />
                }
                label={sf.nature + " " + sf.name}
                slotProps={{ typography: { fontSize: 12 } }}
              />
            ))}
          </Card>
        </Stack>
      </Box>
      <Stack alignItems="center" justifyContent="flex-end" direction="row">
        {uploadFiles.isPending ? (
          <LoadingBar text="Envoi des documents ..." />
        ) : (
          <WarningTooltip title={!canValidate ? "Vous devez sélectionner un fichier et un sous-dossier" : ""}>
            <span>
              <LoadingButton
                disabled={!canValidate}
                onClick={handleValidate}
                variant={canValidate ? "outlined" : "contained"}
                color="success"
              >
                Valider
              </LoadingButton>
            </span>
          </WarningTooltip>
        )}
      </Stack>
    </Stack>
  );
}
