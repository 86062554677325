import React, { useCallback, useMemo, useState } from "react";

import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { typeToColor, typeToLabel } from "@/taskpane/utils/filiation";
import CustomCheckbox from "@/taskpane/components/custom-checkbox/CustomCheckbox";
import TableValueCell from "@/taskpane/modules/folder/filiation/filiation-table/TableValueCell";
import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { sortNumberOrString } from "@/taskpane/utils/filiation";
import { useWindowSize } from "@uidotdev/usehooks";

import type { FiliationTableProps } from "./FiliationTable.types";


export default function FiliationTable({
  blockUi,
  colNum,
  data,
  filters,
  loading,
  noDataMessage,
  onSelectProperty,
  selectedProperties,
  type,
}: FiliationTableProps) {
  const [sort, setSort] = useState<"asc" | "desc">("asc");
  const { height } = useWindowSize();
  const isSmallScreen = useMemo(() => height ? height < 750 : false, [height]);

  const theme = useTheme();

  /**
   * @description Handle the sort of the filiation table
   *
   * @callback
   * @dependency {string} sort - Current sort state. Is either "asc" or "desc"
   * @dependency {function} sort - setState function for the sort state
   */
  const handleSort = useCallback(() => {
    setSort((prev) => {
      if (prev === "asc") {
        return "desc";
      } else {
        return "asc";
      }
    });
  }, [sort, setSort]);

  /**
   * @description Filter the data based on the search input and the selected parcelles and volumes.
   *
   * @memoization
   * @dependency {PropertyNode[]} data - The data to filter.
   * @dependency {Object} filters - The filters object containing the search input, the selected parcelles and volumes.
   */
  const filteredData = useMemo(() => {
    let filtered = data;

    if (filters.search) {
      filtered = filtered.filter(
        (node) =>
          node.value.toLowerCase().includes(filters.search.toLowerCase()) ||
          node.parents.some((parent) => parent.value.toLowerCase().includes(filters.search.toLowerCase()))
      );
    }

    if (filters.parcelleIds.length > 0) {
      filtered = filtered.filter((node) => node.parents.some((parent) => filters.parcelleIds.includes(parent.id)));
    }

    if (filters.volumeIds.length > 0) {
      filtered = filtered.filter((node) => node.parents.some((parent) => filters.volumeIds.includes(parent.id)));
    }

    return filtered;
  }, [data, filters]);

  /**
   * @description Sort the filtered data based on the sort state.
   *
   * @memoization
   * @dependency {PropertyNode[]} filteredData - The filtered data to sort.
   * @dependency {string} sort - The sort state. Is either "asc" or "desc"
   */
  const filteredAndSortedData = useMemo(() => {
    return sortNumberOrString(filteredData, sort);
  }, [filteredData, sort]);

  return (
    <Stack height={1} sx={{ overflowY: "auto", paddingTop: "4px", pb: filteredAndSortedData?.length === 0 ? 0 : 4 }}>
      <TableContainer>

        {/* Table */}
        <Table>

          {/* Table Head */}
          <TableHead>
            <TableRow>

              {/* Checkbox column header */}
              <TableCell width={5}>
                <CustomCheckbox
                  sx={{ p: 0 }}
                  disabled={!!blockUi}
                  color={typeToColor(type)}
                  checked={filteredAndSortedData.every((node) => selectedProperties.includes(node.id)) && filteredAndSortedData.length > 0}
                  onChange={(_, checked) =>
                    onSelectProperty(
                      filteredAndSortedData.map((node) => node.id),
                      checked
                    )
                  }
                />
              </TableCell>

              {/* Sort Icon column header */}
              <TableCell onClick={handleSort} style={{ cursor: "pointer" }}>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography fontSize={12} color={`${typeToColor(type)}.600`} fontWeight={600}>
                    {typeToLabel(type)}
                  </Typography>
                  {sort === "asc" ? (
                    <MdArrowDownward size={16} style={{ color: theme.palette[typeToColor(type) || "primary"].main }} />
                  ) : (
                    <MdArrowUpward size={16} style={{ color: theme.palette[typeToColor(type) || "primary"].main }} />
                  )}
                </Stack>
              </TableCell>

              {/* Lot column header */}
              {type === "lot" && (
                <TableCell>
                  <Typography fontStyle="italic" fontSize={12} color={`${typeToColor("volume")}.600`}>
                    Volume attaché
                  </Typography>
                </TableCell>
              )}

              {/* Lot/Volume column header */}
              {(type === "lot" || type === "volume") && (
                <TableCell>
                  <Typography fontStyle="italic" fontSize={12} color={`${typeToColor("cadastre")}.600`}>
                    Parcelle attachée
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {!loading &&
              filteredAndSortedData.length > 0 &&
              filteredAndSortedData
                .map((node) => (
                  <TableRow key={node.id}>

                    <TableCell width={5}>
                      <CustomCheckbox
                        disabled={!!blockUi}
                        sx={{ py: 0.2, px: 0 }}
                        color={typeToColor(type)}
                        checked={selectedProperties.includes(node.id)} // As the selectedProperties is updated with filiation element that are chosen, it carry the actual checked the value.
                        onChange={(_, checked) => onSelectProperty([node.id], checked)}
                      />
                    </TableCell>

                    <TableCell>
                      <Typography fontSize={12} color={`${typeToColor(type)}.600`} fontWeight={600}>
                        {node.value}
                      </Typography>
                    </TableCell>

                    {type === "lot" && <TableValueCell node={node} color={typeToColor("volume")} type="volume" />}

                    {(type === "lot" || type === "volume") && (
                      <TableValueCell node={node} color={typeToColor("cadastre")} type="cadastre" />
                    )}
                  </TableRow>
                ))}

            {loading && <TableRowsLoader rowsNum={6} colNum={colNum} />}

          </TableBody>

        </Table>
      </TableContainer>

      {/* Display a message when there is no data */}
      {!loading && filteredData.length === 0 && (
        <Typography fontSize={12} color="gray" textAlign="center" mt={2}>
          {noDataMessage}
        </Typography>
      )}
    </Stack>
  );
}

/**
 * @description Skeleton loader for the table rows
 *
 * @param {number} rowsNum - Number of rows to display
 * @param {number} colNum - Number of columns to display
 */
const TableRowsLoader = ({ rowsNum = 5, colNum = 3 }) => {
  return [...Array(rowsNum)].map((_, index) => (
    <TableRow key={index}>
      <TableCell component="th" scope="row">
        <Skeleton animation="wave" variant="text" />
      </TableCell>
      <>
        {colNum > 0 &&
          [...Array(colNum)].map((_, index) => (
            <TableCell key={`col-${index}`}>
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
      </>
    </TableRow>
  ));
};
