import * as React from "react";

import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import useTheme from "@mui/material/styles/useTheme";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useQueryClient } from "@tanstack/react-query";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import StyledSelect from "@/taskpane/components/styled-select/StyledSelect";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";
import {
  useGetAllUserPartenaireId,
  useRefreshAllUserPartenaireIdMutation,
  useSetUserPartenaireIdMutation,
} from "@/taskpane/services/user.hook";
import styles from "@/taskpane/modules/folders/SelectInitialesModal.styles";

import type { SelectInitialesDialogProps } from "./SelectInitialesModal.types";

/**
 * Component for selecting user initials in a modal dialog.
 * @param {SelectInitialesDialogProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export function SelectInitialesDialog(props: SelectInitialesDialogProps) {
  const [selectedInitiales, setSelectedInitiales] = React.useState<string | null>(null);
  const [refreshCount, setRefreshCount] = useLocalStorage<number>("refreshCount", 0);
  const [isRefreshRequested, setIsRefreshRequested] = useLocalStorage<boolean>("isRefreshRequested", false);

  const queryClient = useQueryClient();

  const {
    data: partenaireUsers,
    isPending: isLoadingPartenaireUsers,
    isError: errorPartenaireUsers,
    isSuccess: successPartenaireUsers,
  } = useGetAllUserPartenaireId({
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  const { mutate: refreshAllUserPartenaireIdMutate, isPending: refreshAllUserPartenaireIdMutateLoading } =
    useRefreshAllUserPartenaireIdMutation();

  const { mutate: setUserPartenaireIdMutate, isPending: setUserPartenaireIdMutateLoading } =
    useSetUserPartenaireIdMutation();

  const theme = useTheme();

  /**
   * @description Handles the change event for the select input.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const handleChange = (event: any) => {
    setSelectedInitiales((event?.target as HTMLInputElement)?.value ?? null);
  };

  /**
   * @description  Handles the success event after validating the initials.
   * Same as for the refresh, we need to handle the snackbar display accordingly if the modal is open or not.
   * If the modal is open, we display the success message, otherwise we don't display it as the user didn't ask for it.
   */
  const handleOnValidateSuccess = () => {
    if (props?.open) {
      enqueueSnackbar("Vos initiales métier ont été mises à jour avec succès!", {
        variant: "success",
        persist: false,
        autoHideDuration: 5000,
        action: (snackbarId: SnackbarKey | undefined) => (
          <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} display={false} />
        ),
      });
    }

    queryClient.invalidateQueries({ queryKey: ["getUserAttributes"] });
  };

  /**
   * @description  Handles the error event after failing to validate the initials.
   */
  const handleOnValidateError = () => {
    // Close all potential already as all snackbar are persistent and displayed only one at a time. So we give priority to the error one if user hasn't closed the previous one.
    closeSnackbar();
    // Display the error message from the request if any or the following message if no error message is provided.
    enqueueSnackbar("Une erreur est survenue. Si cela se reproduit, veuillez contacter le support.", {
      variant: "error",
      persist: true,
      action: (snackbarId: SnackbarKey | undefined) => (
        <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
      ),
    });
  };

  /**
   * @description  Handles the validate button click event.
   */
  const handleValidate = React.useCallback(() => {
    if (!!selectedInitiales) {
      setUserPartenaireIdMutate(
        { partenaireId: selectedInitiales },
        { onSuccess: handleOnValidateSuccess, onError: handleOnValidateError }
      );
      queryClient.invalidateQueries({ queryKey: ["getAllUserPartenaireId"] });
      queryClient.invalidateQueries({ queryKey: ["getUserAttributes"] });
    }
  }, [selectedInitiales, setUserPartenaireIdMutate]);

  /**
   * @description Handles the success event after refreshing the user partenaire ID.
   * As the refresh can be triggered automatically or manually, we need to handle the snackbar display accordingly.
   * If the refresh is triggered automatically, we don't display the success message as the user didn't ask for it.
   * If the refresh is triggered manually, we display the success message.
   * Refreshing manually is only possible when the modal is open, so we check if the modal is open to display the success message or
   * the error message if the refresh failed.
   * @param {boolean} isAutoRefresh - Indicates if the refresh was triggered automatically.
   */
  const handleOnRefreshSuccess = (isAutoRefresh: boolean) => {
    if (refreshCount > 1) {
      closeSnackbar();
      if (props?.open) {
        enqueueSnackbar(
          "Si vous ne trouvez pas les initiales utilisées dans votre logiciel métier, veuillez contacter le support Bylaw.",
          {
            variant: "error",
            persist: true,
            action: (snackbarId: SnackbarKey | undefined) => (
              <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} display={true} />
            ),
          }
        );
      }
    } else {
      if (props.open) {
        enqueueSnackbar("La liste des initales est en cours d’actualisation.", {
          variant: "success",
          persist: false,
          autoHideDuration: 5000,
          action: (snackbarId: SnackbarKey | undefined) => (
            <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} display={false} />
          ),
        });
      }
    }
    if (!isAutoRefresh) {
      setRefreshCount((prevState) => prevState + 1);
    }
    queryClient.invalidateQueries({ queryKey: ["getAllUserPartenaireId"] });
    queryClient.invalidateQueries({ queryKey: ["getUserAttributes"] });
    props?.setPollingConfig({
      isPolling: true,
    });
    props?.startPolling();
  };

  /**
   *  @description Handles the error event after failing to refresh the user partenaire ID.
   */
  const handleOnRefreshError = () => {
    // Close all potential already as all snackbar are persistent and displayed only one at a time. So we give priority to the error one if user hasn't closed the previous one.
    closeSnackbar();
    // Display the error message from the request if any or the following message if no error message is provided.
    enqueueSnackbar("Une erreur est survenue. Si cela se reproduit, veuillez contacter le support.", {
      variant: "error",
      persist: true,
      action: (snackbarId: SnackbarKey | undefined) => (
        <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
      ),
    });
  };

  /**
   * @description Trigger a request to the server to ask for a refresh of the partenaire users list.
   * @param {boolean} isAutoRefresh - If the refresh is triggered automatically, we don't increment the refresh count because the user didn't ask for it.
   */
  const handleRefresh = (isAutoRefresh = false) => {
    refreshAllUserPartenaireIdMutate([], {
      onSuccess: () => handleOnRefreshSuccess(isAutoRefresh),
      onError: handleOnRefreshError,
    });
  };

  /**
   * @description Handles the refresh of the partenaire users list when the user requests it.
   *
   * @effect
   * @dependency {boolean} successPartenaireUsers - The success state of the partenaire users query.
   */
  React.useEffect(() => {
    if (isRefreshRequested !== partenaireUsers?.user_partenaire_update_requested) {
      setIsRefreshRequested(partenaireUsers?.user_partenaire_update_requested ?? false);
      if (partenaireUsers?.user_partenaire_update_requested === true) {
        handleRefresh(true);
      }
    }
  }, [successPartenaireUsers]);

  return (
    <Dialog maxWidth="xl" fullWidth sx={styles.modalStyles} {...props} PaperProps={{ sx: { margin: "16px" } }}>
      {/* Modal title */}
      <DialogTitle sx={{ p: 2 }}>
        <Typography variant="h6" fontSize={14} fontStyle="bold" sx={{ mb: 0 }}>
          {"Sélection de vos initiales dans votre logiciel métier"}
        </Typography>
      </DialogTitle>

      {/* Modal content */}
      <DialogContent dividers sx={{ p: 2 }}>
        <Stack spacing={2}>
          {/* Initiales select */}
          <Box sx={{ pt: 2, px: 0 }}>
            <StyledSelect
              autoFocus
              displayEmpty
              label={"Initiales dans votre logiciel métier"}
              labelProps={{
                sx: {
                  color: theme.palette.text.primary,
                },
              }}
              multiple={false}
              onChange={handleChange}
              size="small"
              sx={styles.modalSelectStyles}
              disabled={
                setUserPartenaireIdMutateLoading || isLoadingPartenaireUsers || refreshAllUserPartenaireIdMutateLoading
              }
              value={
                selectedInitiales === null || selectedInitiales === undefined || selectedInitiales === ""
                  ? 0
                  : selectedInitiales
              }
            >
              <MenuItem
                key={"placeholder-index--1"}
                value={0}
                id={`menu-item--1`}
                disabled
                sx={styles.modalSelectDefaultMenuItemStyles(theme)}
              >
                <Typography
                  fontStyle="italic"
                  variant="body2"
                  sx={{ fontSize: 12, color: theme.palette.text.secondary }}
                >
                  Veuillez sélectionnez vos initiales...
                </Typography>
              </MenuItem>
              {partenaireUsers?.users_partenaire
                ?.filter((partenaireUser) => partenaireUser?.initiales !== null)
                ?.sort((a, b) => (a.initiales as string).localeCompare(b.initiales as string))
                ?.map((partenaireUser, index) => (
                  <MenuItem
                    key={partenaireUser?.partenaire_id || `index-${index}`}
                    id={`menu-item-${partenaireUser?.partenaire_id}`}
                    value={partenaireUser?.partenaire_id || ""}
                  >
                    {`${partenaireUser?.initiales}${partenaireUser?.nom && partenaireUser?.prenom ? " - " + partenaireUser?.nom + " " + partenaireUser?.prenom : ""}`}
                  </MenuItem>
                ))}
            </StyledSelect>
          </Box>

          <Stack alignContent="left" spacing={0}>
            {/* Refresh initiales list button */}
            <Button
              variant="text"
              size="small"
              disabled={
                setUserPartenaireIdMutateLoading || isLoadingPartenaireUsers || refreshAllUserPartenaireIdMutateLoading
              }
              onClick={() => handleRefresh(false)}
              sx={{
                textAlign: "left",
                width: "fit-content",
                fontSize: 10,
                fontStyle: "italic",
                color: theme.palette.primary.main,
              }}
            >
              Vos initiales sont absentes de la liste ?
            </Button>
          </Stack>
        </Stack>
      </DialogContent>

      {/* Modal actions */}
      <DialogActions>
        {/* Modal validate button */}
        <Button
          onClick={handleValidate}
          size="medium"
          sx={{ fontSize: 12, fontWeight: 600 }}
          variant="outlined"
          disabled={
            !selectedInitiales ||
            selectedInitiales === "" ||
            setUserPartenaireIdMutateLoading ||
            isLoadingPartenaireUsers ||
            refreshAllUserPartenaireIdMutateLoading
          }
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
