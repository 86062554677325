import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { styles } from "./DragAndDrop.styles";


export interface DragAndDropZoneProps {
  className?: string;
  options?: DropzoneOptions;
  folded?: boolean;
}

export default function DragAndDrop({ options, className, folded }: DragAndDropZoneProps) {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone(options);
  const theme = useTheme();

  const styleProps = { isDragAccept, isDragReject, theme };

  return (
    <Card
      {...getRootProps()}
      sx={styles.card(styleProps)}
      className={className}
    >
      <input {...getInputProps()} />
      {folded ? (
        <Typography sx={styles.foldedText}>
          Vous pouvez continuer à déposer des fichiers ici
        </Typography>
      ) : (
        <Stack sx={styles.contentStack(styleProps)}>
          <Box component="img" src="/assets/upload-illustration_2.svg" sx={styles.illustration} />
          <Typography sx={styles.mainText}>
            Déposez les fichiers ici
          </Typography>
          <Typography sx={styles.subText}>
            ou <Box component="span" sx={styles.browseText(theme)}>parcourir</Box> vos dossiers
          </Typography>
        </Stack>
      )}
    </Card>
  );
}
