import { getUserData, setUserCrpcen, getAllUserPartenaireId, refreshAllUserPartenaireId, setUserPartenaireId } from "@/taskpane/services/user";
import { MutationOptions, useMutation, useQuery, UseQueryOptions, useQueryClient } from "@tanstack/react-query";

export const useUpdateCrpcenMutation = (
  opt?: MutationOptions<Awaited<ReturnType<typeof setUserCrpcen>>, Error, Parameters<typeof setUserCrpcen>[0]>
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...opt,
    mutationFn: setUserCrpcen,
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ["getUserAttributes"],
      });
      if (opt?.onSuccess) {
        opt.onSuccess(data, variables, context);
      }
    },
  });
};

// This hook fetches user data and caches it for 1 hour.
// The data will be considered fresh for 55 minutes (staleTime),
// and will be eligible for garbage collection after 1 hour (gcTime) even if unused.
// This reduces unnecessary API calls while ensuring relatively up-to-date data.
export const useGetUserDataQuery = (opt?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserData>>>>) =>
  useQuery({
    queryKey: ["getUserData"],
    queryFn: getUserData,
    staleTime: 55 * 60 * 1000, // 55 minutes
    gcTime: 60 * 60 * 1000, // 1 hour
    ...opt,
  });

/**
 * Fetch all user partenaire IDs.
 *
 * It's useful for scenarios where you need to display the list of all partenaire IDs
 * associated with the current user or client (etude notariale).
 *
 * @param {Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUserPartenaireId>>>>} opt - Optional configuration options for the query.
 * @returns {UseQueryResult} A react-query result object containing the fetched data, loading state, and error state.
 */
export const useGetAllUserPartenaireId = (
  opt?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllUserPartenaireId>>>>
) =>
  useQuery({
    ...opt,
    queryFn: () => getAllUserPartenaireId(),
    queryKey: ["getAllUserPartenaireId"],
  });

/**
 * Refresh the list of all user partenaire IDs.
 *
 * This mutation hook is useful when the list of partenaire IDs needs to be updated,
 * for example, when a user can't find their information in the current list.
 *
 * It triggers a refresh of the data on the server.
 *
 * @param {MutationOptions<Awaited<ReturnType<typeof refreshAllUserPartenaireId>>, Error, Parameters<typeof refreshAllUserPartenaireId>>} opt - Optional configuration options for the mutation.
 * @returns {UseMutationResult} A react-query mutation result object for handling the refresh operation.
 */
export const useRefreshAllUserPartenaireIdMutation = (
  opt?: MutationOptions<Awaited<ReturnType<typeof refreshAllUserPartenaireId>>, Error, Parameters<typeof refreshAllUserPartenaireId>>
) => {
  return useMutation({
    ...opt,
    mutationFn: refreshAllUserPartenaireId,
  });
};


/**
 * Set the user's partenaire ID.
 *
 * This mutation hook is used to associate a specific partenaire ID with the current user.
 *
 * The partenaire ID typically corresponds to the user's initiales in the etude notariale
 * and serves as a unique identifier for the user's external data.
 *
 * @param {MutationOptions<Awaited<ReturnType<typeof setUserPartenaireId>>, Error, Parameters<typeof setUserPartenaireId>[0]>} opt - Optional configuration options for the mutation.
 * @returns {UseMutationResult} A react-query mutation result object for handling the set operation.
 */
export const useSetUserPartenaireIdMutation = (
  opt?: MutationOptions<Awaited<ReturnType<typeof setUserPartenaireId>>, Error, Parameters<typeof setUserPartenaireId>[0]>
) => {
  return useMutation({
    ...opt,
    mutationFn: setUserPartenaireId,
  });
};