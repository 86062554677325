import { SxProps, Theme } from "@mui/material";
import { DESKTOP_MIN_WIDTH, desktopMaxWidth, desktopPadding } from "@/taskpane/styles/responsive";

export const styles: Record<string, SxProps> = {
  root: {
    p: 0,
    gap: 1,
    height: 0.98,
    maxHeight: 0.98,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...desktopPadding,
    ...desktopMaxWidth(600),
    margin: "0 auto",
  },
  backButton: (isSmallScreen: boolean, theme: Theme) => ({
    position: "absolute",
    top: 20,
    right: isSmallScreen ? 20 : "calc((100% - 600px) / 2 + 20px)",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      right: "calc((100% - 600px) / 2 + 20px)",
    },
  }),
  folderTitle: {
    fontSize: 12,
    color: "secondary.500",
    fontWeight: "bold",
    width: "100%",
    textAlign: "left",
  },
  contentContainer: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    height: "100%",
    minHeight: 0,
    width: "100%",
  },
  childrenContainer: {
    minHeight: 0,
    width: "100%",
  },
  subFolderSection: {
    minHeight: 0,
    mt: 1,
    width: "100%",
  },
  subFolderTitle: {
    fontWeight: "bold",
    fontSize: 14,
    color: "primary.dark",
    width: "100%",
    textAlign: "left",
  },
  subFolderCard: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  validateButtonContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    direction: "row",
    width: "100%",
  },
  modalUploadInProgress: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,

  }
};
