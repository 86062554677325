import type { UploadableFile } from "@/taskpane/types/file";

export type FolderData = {
  dossier_id: string;
  dossier_name: string;
  dossier_status: string;
  dossier_intervenants_notaire: string[];
  created_on: string;
  updated_on: string;
  dossier_import_partenaire_auto_status?: string;
  en_attente_choix_filiation_dossier?: boolean;
  en_attente_choix_filiation_dossier_by?: string[];
  en_attente_choix_filiation_dossier_by_details?: {
    email: string;
    initiales: string;
    nom: string;
    prenom: string;
  }[];
  en_attente_choix_filiation_dossier_id?: string;
};

export type Folder = {
  dossier_id: string;
  name: string;
  status: string;
  intervenants_notaires: string[];
  dossier_intervenants_notaire: string[];
  created_at: Date;
  updated_at: Date;
  dossier_import_partenaire_auto_status: "sync" | "unsync" | "partial";
  en_attente_choix_filiation_dossier?: boolean;
  en_attente_choix_filiation_dossier_by?: string[];
  en_attente_choix_filiation_dossier_by_details?: {
    email: string;
    initiales: string;
    nom: string;
    prenom: string;
  }[];
  en_attente_choix_filiation_dossier_id?: string;
};

export function folderDataToFolder(folderData: FolderData): Folder {
  return {
    dossier_id: folderData.dossier_id,
    name: folderData.dossier_name,
    status: folderData.dossier_status,
    intervenants_notaires: folderData.dossier_intervenants_notaire,
    dossier_intervenants_notaire: folderData.dossier_intervenants_notaire,
    created_at: new Date(folderData.created_on),
    updated_at: new Date(folderData.updated_on),
    dossier_import_partenaire_auto_status: folderData?.dossier_import_partenaire_auto_status?.toLowerCase() ?? "unsync",
    en_attente_choix_filiation_dossier: folderData?.en_attente_choix_filiation_dossier,
    en_attente_choix_filiation_dossier_by: folderData?.en_attente_choix_filiation_dossier_by,
    en_attente_choix_filiation_dossier_by_details: folderData?.en_attente_choix_filiation_dossier_by_details,
    en_attente_choix_filiation_dossier_id: folderData?.en_attente_choix_filiation_dossier_id,
  };
}

export type SubFolderData = {
  dossier_id: string;
  dossier_name: string;
  sous_dossier_id: string;
  sous_dossier_name: string;
  index: number;
  sous_dossier_nature: string; // maybe replace with enum?
  espace_notarial_connection_id: string;
  espace_notarial_status: string;
  espace_notarial_last_download: string;
  sous_dossier_import_partenaire_auto: string;
  sous_dossier_import_partenaire_auto_by: string;
  sous_dossier_import_partenaire_auto_on: string;
  en_attente_choix_filiation_sous_dossier?: boolean;
  en_attente_choix_filiation_sous_dossier_by?: string[];
  en_attente_choix_filiation_sous_dossier_by_details?: {
    email: string;
    initiales: string;
    nom: string;
    prenom: string;
  }[];
};

export type SubFolder = {
  sous_dossier_id: string;
  index: number;
  name: string;
  nature: string; // maybe replace with enum?
  espace_notarial_connection_id: string;
  espace_notarial_status: string;
  espace_notarial_last_download: Date;
  sous_dossier_import_partenaire_auto: string;
  sous_dossier_import_partenaire_auto_by: string;
  sous_dossier_import_partenaire_auto_on: string;
  en_attente_choix_filiation_sous_dossier?: boolean;
  en_attente_choix_filiation_sous_dossier_by?: string[];
};

export function subFolderDataToSubFolder(subFolderData: SubFolderData): SubFolder {
  return {
    sous_dossier_id: subFolderData.sous_dossier_id,
    index: subFolderData.index,
    name: subFolderData.sous_dossier_name,
    nature: subFolderData.sous_dossier_nature,
    espace_notarial_connection_id: subFolderData.espace_notarial_connection_id,
    espace_notarial_status: subFolderData.espace_notarial_status,
    espace_notarial_last_download: new Date(subFolderData.espace_notarial_last_download),
    sous_dossier_import_partenaire_auto: subFolderData.sous_dossier_import_partenaire_auto,
    sous_dossier_import_partenaire_auto_by: subFolderData.sous_dossier_import_partenaire_auto_by,
    sous_dossier_import_partenaire_auto_on: subFolderData.sous_dossier_import_partenaire_auto_on,
    en_attente_choix_filiation_sous_dossier: subFolderData.en_attente_choix_filiation_sous_dossier,
    en_attente_choix_filiation_sous_dossier_by: subFolderData.en_attente_choix_filiation_sous_dossier_by,
  };
}

export interface FolderPageLayoutProps {
  canValidate?: boolean;
  children?: React.ReactNode;
  files: UploadableFile[];
  folderId?: string;
  onSelectSubFolder: (subfolder: SubFolder) => void;
  onUpload?: () => void;
  onValidate?: () => void;
  selectedSubfolder?: SubFolder;
  setUploadProgress?: (prevState: any) => void;
}