/**
 * Example of expected JSON response from /dashboard/stats:
 * 
 * {
 *   "stats": {
 *     "folders_analyzed": {
 *       "total": 324,
 *       "percent_change": 15,
 *       "monthly_data": [
 *         { "month": "Jan", "count": 180 },
 *         { "month": "Fév", "count": 220 },
 *         { "month": "Mar", "count": 250 },
 *         { "month": "Avr", "count": 280 },
 *         { "month": "Mai", "count": 300 },
 *         { "month": "Juin", "count": 324 }
 *       ]
 *     },
 *     "documents_analyzed": {
 *       "total": 1256,
 *       "percent_change": 8,
 *       "monthly_data": [
 *         { "month": "Jan", "count": 800 },
 *         { "month": "Fév", "count": 920 },
 *         { "month": "Mar", "count": 1050 },
 *         { "month": "Avr", "count": 1150 },
 *         { "month": "Mai", "count": 1200 },
 *         { "month": "Juin", "count": 1256 }
 *       ]
 *     },
 *     "documents_created": {
 *       "total": 458,
 *       "percent_change": 23,
 *       "monthly_data": [
 *         { "month": "Jan", "count": 250 },
 *         { "month": "Fév", "count": 300 },
 *         { "month": "Mar", "count": 350 },
 *         { "month": "Avr", "count": 380 },
 *         { "month": "Mai", "count": 420 },
 *         { "month": "Juin", "count": 458 }
 *       ]
 *     }
 *   },
 *   "recent_folders": [
 *     {
 *       "id": "F123",
 *       "name": "Dossier Dupont",
 *       "created_at": "15/01/2024",
 *       "status": "En cours"
 *     },
 *     {
 *       "id": "F124",
 *       "name": "Dossier Martin",
 *       "created_at": "14/01/2024",
 *       "status": "Terminé"
 *     },
 *     {
 *       "id": "F125",
 *       "name": "Dossier Bernard",
 *       "created_at": "13/01/2024",
 *       "status": "En attente"
 *     }
 *   ],
 *   "user_activities": [
 *     {
 *       "id": 1,
 *       "name": "Jean Dupont",
 *       "role": "Administrateur",
 *       "last_active": "15/01/2024"
 *     },
 *     {
 *       "id": 2,
 *       "name": "Marie Martin",
 *       "role": "Utilisateur",
 *       "last_active": "14/01/2024"
 *     },
 *     {
 *       "id": 3,
 *       "name": "Pierre Bernard",
 *       "role": "Utilisateur",
 *       "last_active": "13/01/2024"
 *     }
 *   ]
 * }
 */

import { apiClient } from "@/taskpane/utils/client";
import { handleError } from "@/taskpane/utils/errors";

export interface DashboardStats {
  folders_analyzed: {
    total: number;
    percent_change: number;
    monthly_data: {
      month: string;
      count: number;
    }[];
  };
  documents_analyzed: {
    total: number;
    percent_change: number;
    monthly_data: {
      month: string;
      count: number;
    }[];
  };
  documents_created: {
    total: number;
    percent_change: number;
    monthly_data: {
      month: string;
      count: number;
    }[];
  };
}

export interface RecentFolder {
  id: string;
  name: string;
  created_at: string;
  status: string;
}

export interface UserActivity {
  email: string;
  role: string;
  last_active: string;
}

export interface DashboardData {
  stats: DashboardStats;
  recent_folders: RecentFolder[];
  user_activities: UserActivity[];
}

/**
 * Récupère les statistiques du tableau de bord.
 * @returns Une promesse qui résout vers les données du tableau de bord.
 */
export async function getDashboardData(): Promise<DashboardData | null> {
  try {
    const response = await apiClient.get("/dashboard/stats");
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /dashboard/stats failed ${errorMessage ? 
        "with status" + " " + errorMessage : 
        ""}`,
      `Impossible de récupérer les statistiques du tableau de bord`);
  }
}

/**
 * Récupère les statistiques détaillées pour une période spécifique.
 * @param startDate Date de début au format YYYY-MM-DD
 * @param endDate Date de fin au format YYYY-MM-DD
 * @returns Une promesse qui résout vers les statistiques détaillées
 */
export async function getDashboardDetailedStats(
  startDate: string,
  endDate: string
): Promise<DashboardStats | null> {
  try {
    const response = await apiClient.get("/dashboard/detailed-stats", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /dashboard/detailed-stats failed ${errorMessage ? 
        "with status" + " " + errorMessage : 
        ""}`,
      `Impossible de récupérer les statistiques détaillées`);
  }
}