import { DESKTOP_MIN_WIDTH } from "@/taskpane/styles/responsive";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    padding: "16px",
    width: "100%",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      maxWidth: "600px",
      margin: "0 auto"
    }
  },

  card: {
    width: "100%",
    maxWidth: "400px",
    margin: "auto",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      width: "80%",
      maxWidth: "480px",
      padding: "24px"
    }
  },

  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    gap: 2
  },

  title: {
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 2,
    color: "text.primary"
  },

  textField: {
    width: "100%",
    marginBottom: 2
  },

  button: {
    minWidth: "120px",
    height: 36,
    paddingLeft: 2,
    paddingRight: 2,
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      minWidth: "160px"
    }
  },

  otpSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 2
  },

  resendButton: {
    marginTop: 1,
    fontSize: "0.875rem"
  }
} as const;

export default styles;
