import { LinearProgress, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

export interface LoadingBarProps {
  text: string;
}

export default function LoadingBar({ text }: LoadingBarProps) {
  const theme = useTheme();

  return (
    <Stack spacing={0.5} alignItems="center" width={1} px={2} py={1}>
      <LinearProgress color="success" variant="indeterminate" sx={{ width: "100%" }} />
      <Typography fontSize={13} color={theme.palette.success.main}>
        {text}
      </Typography>
    </Stack>
  );
}
