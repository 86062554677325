import React, { useState, useCallback, useEffect } from "react";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { ReactFlowProvider } from "@xyflow/react";
import { Box } from "@mui/material";
import { Flow, FiliationPanel, TimeLinePanel } from "@/diagramme/components";

import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

import Logo from "@/taskpane/components/logo/Logo";

import { useParams } from "react-router-dom";
import { usePrevious } from "@uidotdev/usehooks";
import { useGetPublicFiliationQuery } from "@/taskpane/services/filiation.hook";

import {
  BLUE_BYLAW,
  BORDER_RADIUS_MUI,
  DEFAULT_BLUR,
  DEFAULT_ELEVATION,
  DEFAULT_PADDING,
  LOGO_BOX_WIDTH,
  LOGO_WIDTH,
  PANEL_HEADER_TITLE_HEIGHT,
  PANEL_WIDTH,
  TOOLBAR_HEIGHT,
  WHITE,
} from "@/diagramme/config";

import { areArraysEquals, isEqual, getFiliationEventsGroupedByDates } from "@/diagramme/utils";

import type {
  FiliationEvent,
  FiliationNode,
  FiliationTree,
  FiliationEventWithFiliationStateGroupedByDate,
} from "@/diagramme/types";

dayjs.extend(customParseFormat);

const drawerWidth = PANEL_WIDTH;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    },
  ],
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `100%`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const FiliationDiagramme = () => {
  /**
   * @description Extract route parameters
   */
  const { client_id, dossier_id, sous_dossier_id, flow_id } = useParams();

  /**
   * @description Fetch filiation data using a custom hook. This method call a public endpoint.
   * @param {string} client_id - The client Id or Etude
   * @param {string} dossier_id - The dossier ID
   * @param {string} sous_dossier_id - The sous-dossier ID
   * @param {string} flow_id - The flow ID
   * @returns {Object} data - The fetched filiation data
   */
  const { data, isLoading }: any = useGetPublicFiliationQuery(
    client_id || "",
    dossier_id || "",
    sous_dossier_id || "",
    flow_id || "",
    {
      initialData: null,
    }
  );

  /**
   * @description Hook to keep track of the previous state of data.
   * This allow to react to actual changes of data.
   */
  const previousData = usePrevious(data);

  /**
   * @description State for storing the chosen filiation. By chosen we mean here, filiation data from the result of the user previous choice.
   * This state include all filiation object with chosen to true and their related filiation elements as their ascendants.
   */
  const [chosenFiliationData, setChosenFiliationData] = useState<FiliationTree | null>(null);
  const previousChosenFiliationData = usePrevious(chosenFiliationData);

  /**
   * @description State storing origines filiation objects for only chosen objects.
   * The main goal of this state is to represent the differents events in time that concern the chosen filiation objects.
   */
  const [chosenFiliationEvents, setChosenFiliationEvents] = useState<FiliationEvent[] | null>(null);
  const previousChosenFiliationEvents = usePrevious(chosenFiliationEvents);

  /**
   * @description State storing a list of every filiation state for each events related to chosen filiation objects.
   */
  const [filiationStatesByEvents, setFiliationStatesByEvents] = useState<
    FiliationEventWithFiliationStateGroupedByDate[] | null
  >(null);

  /**
   * @description State holding today's date
   */
  const [today, setToday] = useState<string>(() => dayjs().format("DD/MM/YYYY"));

  /**
   * @description State storing the current date at which the filiation object need to be rendered
   */
  const [currentDate, setCurrentDate] = useState<string>(() => dayjs().format("DD/MM/YYYY"));
  const previousCurrentDate = usePrevious(currentDate);

  /**
   * @description State for managing filiation objects that have chosen to true as defaults values and keep track of manually choose elements by user.
   */
  const [selectedProperties, setSelectedProperties] = useState<number[]>([]);

  /**
   * @description Filiation panel open/close state
   */
  const [open, setOpen] = React.useState(true);

  /**
   * @description Effect reacting on data changes to retrieve origines of chosen filiation objects and computing all
   * possible filiation states for each event found in origines related to chosen elements.
   */
  useEffect(() => {
    if (!isEqual(previousData, data) && data) {
      // Get events from filiation data origines property
      const filiationEvents = data?.origines;

      // Get nodes from data nodes property
      // const filiationNodes = data?.nodes;

      const chosenFiliationOriginNodes = filiationEvents.filter((node: any) => !!node?.event_descripion?.final_nodes);

      setChosenFiliationEvents([...chosenFiliationOriginNodes]);
    }
  }, [data]);

  useEffect(() => {
    if (data && !isEqual(previousData, data)) {
      const dataOrigines = data?.origines;
      const dataNodes = data?.nodes;

      const chosenNodes: any[] = [];

      const parcelles = dataNodes?.filter((node: FiliationNode) => node.type === "cadastre");
      const volumes = dataNodes?.filter((node: FiliationNode) => node.type === "volume");
      const lots = dataNodes?.filter((node: FiliationNode) => node.type === "lot");

      const chosenLots = lots.filter((lot: any) => lot.chosen === true);
      const chosenVolumes = volumes.filter((volume: any) => volume.chosen === true);
      const chosenParcelles = parcelles.filter((parcelle: any) => parcelle.chosen === true);

      chosenNodes.push(...chosenLots);
      chosenNodes.push(...chosenVolumes);
      chosenNodes.push(...chosenParcelles);

      const chosenNodesAndTheirAscendants = [...chosenNodes];

      chosenNodes.forEach((node: any) => {
        const potentialAlreadyChosenAscendantNode = chosenNodes.find((chosenNode: any) => {
          return node.parents_ids?.includes(chosenNode.id);
        });

        if (potentialAlreadyChosenAscendantNode) {
          return;
        } else {
          const ascendantNodes = dataNodes.filter((potentialAscendantNode: any) => {
            return node.parents_ids?.includes(potentialAscendantNode.id);
          });

          chosenNodesAndTheirAscendants.push(...ascendantNodes);
        }
      });

      let isAllAscendantsAddedToChosenNodes = false;

      while (!isAllAscendantsAddedToChosenNodes) {
        const ascendantsOfAllChosenNodes = chosenNodesAndTheirAscendants
          .map((node: any) => node.parents_ids)
          .flat(Infinity);
        const uniqueAscendantsOfAllChosenNodes = ascendantsOfAllChosenNodes.filter(
          (value, index, self) => self.indexOf(value) === index
        );

        const ascendantsOfAllChosenNodesThatAreNotInChosenNodes = uniqueAscendantsOfAllChosenNodes.filter(
          (ascendantId: any) => {
            return !chosenNodesAndTheirAscendants.find((node: any) => node.id === ascendantId);
          }
        );

        if (ascendantsOfAllChosenNodesThatAreNotInChosenNodes.length === 0) {
          isAllAscendantsAddedToChosenNodes = true;
        } else {
          const ascendantNodes = dataNodes.filter((potentialAscendantNode: any) => {
            return ascendantsOfAllChosenNodesThatAreNotInChosenNodes.includes(potentialAscendantNode.id);
          });

          chosenNodesAndTheirAscendants.push(...ascendantNodes);
        }
      }

      const chosenNodesAndTheirAscendantsSortedById = chosenNodesAndTheirAscendants.sort(
        (a: any, b: any) => a.id - b.id
      );

      const chosenNodesFiliationLinkedData = {
        nodes: chosenNodesAndTheirAscendantsSortedById,
        origines: dataOrigines,
      };

      setChosenFiliationData({ ...chosenNodesFiliationLinkedData });
    }
  }, [data]);

  /**
   * @description Effect hook reacting on chosenFiliationEvents in order to compute every filiation state corresponding to each events.
   * This effect will be triggered when chosenFiliationEvents changes and need to be computed without blocking the UI.
   * Main goal is to avoid re-computing the filiation data at each event selection but only render the appropriate part of the filiations by events state object (TODO).
   *
   * @dependency {FiliationEvent[]} chosenFiliationEvents - Chosen filiation events updated by the call to the API and processed to be restricted on chosen filiation before update
   */
  useEffect(() => {
    // Check if chosenFiliationEvents is different from previous chosenFiliationEvents state
    if (
      !areArraysEquals(previousChosenFiliationEvents, chosenFiliationEvents) &&
      chosenFiliationEvents &&
      chosenFiliationData?.nodes
    ) {
      const filiationStatesByEventsValue: FiliationEventWithFiliationStateGroupedByDate[] =
        getFiliationEventsGroupedByDates(chosenFiliationEvents, chosenFiliationData?.nodes);

      const newFiliationStatesByEvents = [...filiationStatesByEventsValue];

      // newFiliationStatesByEvents.unshift({ date: today, events: chosenFiliationData?.nodes });

      // console.log("newFiliationStatesByEvents", newFiliationStatesByEvents);

      setFiliationStatesByEvents([...newFiliationStatesByEvents]);
    }
  }, [chosenFiliationEvents]);

  /**
   * @description Callback function to handle current date change.
   */
  const handleCurrentDateChange = useCallback(
    (date: string) => {
      setCurrentDate(date);
    },
    [setCurrentDate]
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        backdropFilter: `blur(${DEFAULT_BLUR}px)`,
        backgroundColor: "rgba(255,255,255,0.2)",
        display: "flex",
        height: "100vh",
        margin: 0,
        overflow: "hidden",
        padding: 0,
        WebkitBackdropFilter: `blur(${DEFAULT_BLUR}px)`,
      }}
    >
      <CssBaseline />
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={[
          {
            position: "absolute",
            zIndex: 3000,
            left: "12px",
            top: `${TOOLBAR_HEIGHT}px`,
            "& .MuiSvgIcon-root ": {
              fill: `${BLUE_BYLAW}`,
            },
          },
          open && { display: "none" },
        ]}
      >
        <ExpandCircleDownIcon fontSize="large" sx={{ rotate: "270deg", color: `${WHITE}` }} />
      </IconButton>
      <AppBar position="fixed" open={open} variant="elevation" elevation={DEFAULT_ELEVATION}>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pr: 2,
            backgroundColor: `${WHITE}`,
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: `${WHITE}`,
              display: "flex",
              height: `${TOOLBAR_HEIGHT}px`,
              width: `${LOGO_BOX_WIDTH}px`,
              justifyContent: "center",
              mr: 2,
            }}
          >
            <Logo width={`${LOGO_WIDTH}px`} />
          </Box>
          {filiationStatesByEvents && filiationStatesByEvents.length > 0 ? (
            <TimeLinePanel
              data={filiationStatesByEvents}
              setCurrentDate={handleCurrentDateChange}
              todayData={chosenFiliationData?.nodes}
              today={today}
            />
          ) : null}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"left"}
        variant="persistent"
        elevation={DEFAULT_ELEVATION}
        open={open}
        hideBackdrop={true}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        PaperProps={{
          variant: "elevation",
          elevation: DEFAULT_ELEVATION,
          sx: {
            borderRadius: BORDER_RADIUS_MUI,
            top: `${TOOLBAR_HEIGHT + DEFAULT_PADDING}px`,
            left: `${DEFAULT_PADDING}px`,
            bottom: `${DEFAULT_PADDING}px`,
            height: "unset",
            width: `${PANEL_WIDTH}px`,
          },
        }}
      >
        <FiliationPanel
          setSelectedProperties={setSelectedProperties}
          selectedProperties={selectedProperties}
          filteredFiliationDataIds={chosenFiliationData?.nodes.map((node: any) => node.id)}
          parentLoading={isLoading}
          handleDrawerClose={handleDrawerClose}
        />
      </Drawer>
      <Main open={open}>
        <ReactFlowProvider>
          {chosenFiliationData ? (
            <Flow
              chosenFiliationData={chosenFiliationData}
              currentDate={currentDate}
              filiationStatesByEvents={filiationStatesByEvents}
              panelOpenState={open}
              previousChosenFiliationData={previousChosenFiliationData}
              previousCurrentDate={previousCurrentDate}
            />
          ) : null}
        </ReactFlowProvider>
      </Main>
    </Box>
  );
};

export default FiliationDiagramme;
