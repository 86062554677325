import { apiClient } from "@/taskpane/utils/client";
import { FolderData, SubFolderData } from "@/taskpane/types/folder";
import { handleError } from "@/taskpane/utils/errors";

/**
 * Retrieves the list of folders.
 * @returns A promise that resolves to an object containing an array of FolderData objects.
 */
export async function getFolders(): Promise<{ dossiers: FolderData[], client_blocked: boolean, client_blocked_reason?: string } | null> {
  try {
    const response = await apiClient.get("/dossiers");
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /dossiers failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Aucun dossiers récupérés`);
  }
}

/**
 * Retrieves the sub-folders of a given folder.
 * @param folderId The ID of the folder.
 * @returns A promise that resolves to an array of sub-folder data.
 */
export async function getSubFolders(folderId: string): Promise<{ sous_dossiers: SubFolderData[] } | null> {
  try {
    const response = await apiClient.get(`/dossier/${folderId}/sous-dossiers`)
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /dossier/${folderId}/sous-dossiers failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Aucun sous-dossier n'a été trouvé ${folderId}`);
  }
}

/**
 * Retrieves detailed information about a specific sub-folder.
 * @param folderId The ID of the parent folder.
 * @param subFolderId The ID of the sub-folder.
 * @returns A promise that resolves to the sub-folder details.
 */
interface InputV2 {
  page_number: number;
  input_id: string;
  input_classifier_rename: string;
  input_original_filename: string;
  created_on: string;
}

interface Input {
  input_classifier_rename: string;
  created_on: string;
  input_id: string;
  input_original_filename: string;
  step_classifier_started_on: string;
  step_classifier_completed_on: string;
  step_classifier_status: string;
  inputs_v2: InputV2[];
}

interface SubFolderDetails {
  sous_dossier: {
    sous_dossier_id: string;
    sous_dossier_name: string;
    nature: string;
    filiation_id: string;
    stat_number_of_inputs: number;
    date_last_input: string;
  };
  inputs: Input[];
  outputs: any[];
  client_id: string;
}

export async function getSubFolderDetails(folderId: string, subFolderId: string): Promise<SubFolderDetails | null> {
  try {
    const response = await apiClient.get(`/dossier/${folderId}/sous-dossier/${subFolderId}/details`);
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /dossier/${folderId}/sous-dossier/${subFolderId} failed ${errorMessage ?
        "with status" + " " + errorMessage :
        ""
      }`,
      `Impossible de récupérer les détails du sous-dossier`);
  }
}


/**
 * Enable/Disable the auto import status for a given sous-dossier in a dossier.
 *
 * @param {string} options.dossierId - The ID of the dossier.
 * @param {string} options.sousDossierId - The ID of the sous-dossier.
 * @returns {Promise<void>} - A promise that resolves when the sous-dossier auto import status is updated.
 */
export async function setSousDossierAutoImportStatus({
  dossierId,
  sousDossierId,
  autoImportStatusValue,
}: {
  dossierId: string;
  sousDossierId: string;
  autoImportStatusValue: boolean;
}) {
  try {
    const body = {
      'auto_import_sous_dossiers_status': autoImportStatusValue
    }
    const response = await apiClient.put(`/dossier/${dossierId}/sous-dossier/${sousDossierId}/auto-import-status`, body);
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `POST request to update auto import status endpoint failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Echec de la mise à jour du statut d'importation automatique`);
  }
}