import { useTheme } from "@mui/material";
import React, { memo, useMemo } from "react";
import { BsFileEarmarkPdfFill, BsFileEarmarkTextFill, BsFileEarmarkImageFill, BsFileEarmarkXFill } from "react-icons/bs";
import { IoDocumentOutline } from "react-icons/io5";
import {
  PiMicrosoftExcelLogoFill,
  PiMicrosoftWordLogoFill,
  PiMicrosoftPowerpointLogoFill,
  PiFileSvgFill,
  PiFileXFill
} from "react-icons/pi";
import { SiOnlyoffice } from "react-icons/si";
import { IconType } from "react-icons";

const styles = {
  flexShrink: 0,
} as const;

interface FileTypeConfig {
  Icon: IconType;
  color?: string;
  useThemeColor?: boolean;
}

// Static mapping of file extensions to their configurations
const FILE_TYPE_CONFIG: Record<string, FileTypeConfig> = {
  pdf: { Icon: BsFileEarmarkPdfFill, color: "#E94848" },
  word: { Icon: PiMicrosoftWordLogoFill, color: "#185ABD" },
  excel: { Icon: PiMicrosoftExcelLogoFill, color: "#107C41" },
  powerpoint: { Icon: PiMicrosoftPowerpointLogoFill, color: "#D24726" },
  openDocument: { Icon: SiOnlyoffice, color: "#0E85CD" },
  text: { Icon: BsFileEarmarkTextFill, useThemeColor: true },
  image: { Icon: BsFileEarmarkImageFill, color: "#4CAF50" },
  svg: { Icon: PiFileSvgFill, color: "#FF9800" },
  xml: { Icon: PiFileXFill, color: "#FFC107" },
  design: { Icon: BsFileEarmarkXFill, color: "#9C27B0" },
  default: { Icon: IoDocumentOutline, useThemeColor: true }
} as const;

const EXTENSION_MAP: Record<string, keyof typeof FILE_TYPE_CONFIG> = {
  // Microsoft Word
  doc: 'word',
  docx: 'word',
  dot: 'word',
  dotx: 'word',
  docm: 'word',
  dotm: 'word',

  // Microsoft Excel
  xls: 'excel',
  xlsx: 'excel',
  xlsm: 'excel',
  xlsb: 'excel',
  xlt: 'excel',
  xltx: 'excel',
  xltm: 'excel',
  xla: 'excel',
  xlam: 'excel',
  xlw: 'excel',

  // Microsoft PowerPoint
  ppt: 'powerpoint',
  pptx: 'powerpoint',
  pptm: 'powerpoint',
  pot: 'powerpoint',
  potx: 'powerpoint',
  potm: 'powerpoint',
  pps: 'powerpoint',
  ppsx: 'powerpoint',
  ppsm: 'powerpoint',

  // OpenDocument formats
  odt: 'openDocument',
  ott: 'openDocument',
  ods: 'openDocument',
  ots: 'openDocument',
  odp: 'openDocument',
  otp: 'openDocument',

  // Text files
  txt: 'text',
  rtf: 'text',
  tex: 'text',

  // Image files
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  gif: 'image',
  bmp: 'image',
  tiff: 'image',
  tif: 'image',

  // SVG
  svg: 'svg',

  // XML
  xml: 'xml',

  // Design files
  eps: 'design',
  ai: 'design',

  // PDF
  pdf: 'pdf'
} as const;

const FileIconType = memo(function FileIconType({ fileName }: { fileName: string }) {
  const theme = useTheme();

  const fileConfig = useMemo(() => {
    const extension = fileName.split(".").pop()?.toLowerCase() || '';
    const fileType = EXTENSION_MAP[extension] || 'default';
    return FILE_TYPE_CONFIG[fileType];
  }, [fileName]);

  const { Icon, color, useThemeColor } = fileConfig;
  const iconColor = useThemeColor ? theme.palette.text.primary : color;

  return <Icon style={styles} color={iconColor || 'inherit'} />;
});

FileIconType.displayName = 'FileIconType';

export default FileIconType;