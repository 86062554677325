import { SxProps } from "@mui/material";

// Breakpoints
export const DESKTOP_MIN_WIDTH = 550;

// Padding values
export const DESKTOP_PADDING = 0; // 24px in MUI spacing

// Responsive style mixins
export const desktopPadding: SxProps = {
  [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
    px: DESKTOP_PADDING,
  }
};

export const desktopMaxWidth = (maxWidth: number): SxProps => ({
  [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
    maxWidth: `${maxWidth}px`,
  }
});