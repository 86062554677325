import React, { useState, useEffect, useContext } from "react";

/**
 * @description Interface for the Office context.
 * Define the props of the OfficeContextProvider component.
 *
 * This should be a React node that represents the child components to be rendered 
 * as the main page of the add-in.
 * @property {any} children - The children rendered by the OfficeContextProvider component.
 * This component is between the App component and each page rendered in the router of the add-in.
 */
interface Props {
    children: any
}

/**
 * Define the initial state for the Office context.
 * @property {boolean} isInitialized - Indicates whether the Office context has been initialized.
 * This is set to false by default, could be updated if necessary. This isn't used in the current implementation.
 * @type {IOfficeContext}
 */
export const INITIAL_OFFICE_CONTEXT: IOfficeContext = {
    isInitialized: false,
};

/**
 * Create a context for Office with the initial state.
 * This context will be used to share data between components that need it.
 * @type {React.Context<any>}
 */
const OfficeContext = React.createContext<any>(INITIAL_OFFICE_CONTEXT);

/**
 * Custom hook to access the Office context.
 * You should call this hook in any component that needs to access the Office context.
 * The uniqueness of the lifecycle registering the Office context event is handled by this hook and
 * should only be managed here to avoid conflict or overflow in registering the event.
 * @returns {any} - The current context value.
 */
export const useOfficeContext = () => useContext(OfficeContext);

/**
 * @description Office context provider component. 
 * The main role of this component is to manage event registering to the Office context and
 * provide the managed state to the children components.
 * @param {Props} props - The props for the OfficeContextProvider component.
 * @returns {JSX.Element} - The JSX element representing the OfficeContextProvider component.
 */
export const OfficeContextProvider: React.FC<Props> = ({ children }) => {
    /**
     * State to hold the current open mail item.
     * @type {[any, React.Dispatch<React.SetStateAction<any>>]}
     */
    const [openMail, setOpenMail] = useState<any>(null);
    /**
     * State to hold the count of how many time the user changed email in a app lifecycle.
     * @type {[number, React.Dispatch<React.SetStateAction<number>>]}
     */
    const [changeCount, setChangeCount] = useState(0)
    /**
     * State to hold filiation data parsed from the opened email
     */
    const [filiationData, setFiliationData] = useState(null)

    /**
     * Effect hook to initialize the Office context, registering event reacting to mail changed and 
     * handle changes to the registered event.
     */
    useEffect(() => {
        const asyncHandler = async () => {
            /**
             * We'll call this function to read the current office state
             * from the opened mailbox of the Office context and update our
             * application state that will be traced by the useEffect hook.
             */
            const handleOpenMailChanged = () => {
                // Unpack the office context.
                const { mailbox } = Office.context;

                setChangeCount((prev) => prev + 1)
                setOpenMail(mailbox)
            };
            /**
             * Update our application state when the mail item changes.
             */
            handleOpenMailChanged();
            /**
             * Register the event listening to mailbox changes.
             */
            Office.context.mailbox &&
                Office.context.mailbox.addHandlerAsync(
                    Office.EventType.ItemChanged,
                    handleOpenMailChanged
                );
        };
        /**
         * Wait for the Office context to be ready before initializing the add-in by calling
         * the asyncHandler function that will register the event listening to mailbox changes.
         */
        Office.onReady(() => {
            asyncHandler();
        });
    }, []);

    return (
        <OfficeContext.Provider value={{ changeCounter: changeCount, currentMailbox: openMail, filiationData: filiationData, setFiliationData: setFiliationData }}>{children}</OfficeContext.Provider>
    );
};

/**
 * OfficeContextConsumer component to consume the Office context.
 * @type {React.Consumer<any>}
 */
export const OfficeContextConsumer = OfficeContext.Consumer;

export type EmailAddressDetails = {
    displayName: string;
    emailAddress: string;
};

export interface IItemState {
    from: EmailAddressDetails;
    to: EmailAddressDetails[];
    cc: EmailAddressDetails[];
    internetMessageId: string;
    conversationId: string;
    itemType: string;
    body: Office.Body;
}

export interface IMailboxState {
    item?: IItemState;
    userProfile: EmailAddressDetails;
}

export interface IOfficeState {
    mailbox?: IMailboxState;
}

export interface IOfficeContext {
    isInitialized?: boolean;
    state?: IOfficeState;
}