import * as React from "react";

import { Card, CardContent, Typography, Stack, Paper, Divider, Box } from "@mui/material";

import { TimelinePanelTabContent } from "./timeline-panel-tab-content";

import type { FiliationEventWithFiliationStateGroupedByDate } from "@/diagramme/types";
import { useTheme } from "@mui/material";
import { BLUE_BYLAW, PANEL_HEADER_TITLE_HEIGHT, WHITE_DARK, DEFAULT_PADDING_MUI, BORDER_RADIUS_MUI } from "@/diagramme/config";

export function TimelineEventPanel({ data, selectedTab }: any) {
  const theme = useTheme();

  return (
    <>
      {data?.map((filiationByDate: FiliationEventWithFiliationStateGroupedByDate, index: number, arr: any[]) => {
        const { events, date } = filiationByDate

        return (
          <TimelinePanelTabContent key={`tab-panel-${date}`} value={selectedTab} index={index}>
            <Box
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: WHITE_DARK,
                width: 1,
                p: 1,
                height: `${PANEL_HEADER_TITLE_HEIGHT}px`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ color: BLUE_BYLAW, fontWeight: 700 }}
              >{`${arr[index]?.date !== undefined ? 'Évènements du ' + arr[index]?.date : "Dernier état connu de la filiation"}`}</Typography>
            </Box>
            <Stack
              direction="column"
              rowGap={2}
              alignItems="center"
              padding={DEFAULT_PADDING_MUI}
              sx={{
                height: `calc(100% - ${PANEL_HEADER_TITLE_HEIGHT}px)`
              }}
            >
              <>
                {arr[index]?.events?.map((event: any) => {
                  return (
                    <>
                      <Divider
                        orientation="horizontal"
                        variant="fullWidth"
                        sx={{ borderColor: theme.palette.divider, borderWidth: "1px", width: 1 }}
                      />
                      <Paper variant="elevation" elevation={6} sx={{ p: 1, width: 1 }}>
                        <Card variant="elevation" sx={{ border: "none", borderRadius: BORDER_RADIUS_MUI }}>
                          <CardContent sx={{ padding: 1 }}>
                            <>
                              <Typography variant="body1" gutterBottom sx={{ color: "text.secondary" }}>
                                {event?.event_descripion?.nature_transformation ?? "Informations à venir..."}
                              </Typography>
                              <Typography variant="body1">
                                {event?.event_descripion?.text ?? "Informations à venir..."}
                              </Typography>
                            </>
                          </CardContent>
                        </Card>
                      </Paper>
                    </>
                  );
                })}
              </>
            </Stack>
          </TimelinePanelTabContent>
        );
      })}
    </>
  );
}
