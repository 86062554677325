import React, { createContext, useContext, useState, useCallback, ReactNode, useEffect } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { getAllUserPartenaireId } from "@/taskpane/services/user";

import type { PollingState, PollingContextType } from "@/taskpane/types/polling";


const PollingContext = createContext<PollingContextType | undefined>(undefined);

/**
 * @description Custom hook to use the polling context.
 *
 * @returns {PollingContextType} Polling context including method to set, start and stop polling.
 *
 * @throws Will throw an error if used outside of a PollingProvider.
 */
export const usePollingContext = () => {
  const context = useContext(PollingContext);
  if (!context) {
    throw new Error("usePollingContext must be used within a PollingProvider");
  }
  return context;
};

/**
 * @description Provider component for the polling context. Allow to share polling states and methods across the app.
 * The values of state are persisted in local storage.
 *
 * @param {ReactNode} props.children - The children components.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const PollingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isPollingStorage, setIsPollingStorage] = useLocalStorage<boolean>("isPolling", false);
  const [_pollingStartTime, setPollingStartTime] = useLocalStorage<number | null>("pollingStartTime", null);
  const [refreshCount, _setRefreshCount] = useLocalStorage<number>("refreshCount", 0);
  const [_isPopupBlockFor24Hour, setIsPopupBlockFor24Hour] = useLocalStorage<boolean | null>("isPopupBlockFor24Hour", null);
  const [_isPopupBlockFor24HourSince, setIsPopupBlockFor24HourSince] = useLocalStorage<number | null>("isPopupBlockFor24HourSince", null);
  const [polling, setPolling] = useState<PollingState>({
    queryKey: "getAllUserPartenaireId",
    queryFn: () => getAllUserPartenaireId(),
    interval: 5000,
    stopCondition: (data) => data?.user_partenaire_update_requested === false,
    isPolling: isPollingStorage,
  });

/**
 * @description Effect hook to manage popup blocking based on refresh count.
 *
 * When the refresh count exceeds 1 (so at 2), it sets a flag to block popups for 24 hours
 * and records the timestamp when this block was initiated.
 *
 * @effect
 * @dependency {number} refreshCount - The number of times the page has been refreshed.
 */
  useEffect(() => {
    if (refreshCount > 1) {
      setIsPopupBlockFor24Hour(true);
      setIsPopupBlockFor24HourSince(Date.now());
    }
  }, [refreshCount]);

/**
 * @description Effect hook to synchronize the local state with the persisted polling state.
 *
 * This effect updates the local state's `isPolling` property whenever the `isPollingStorage` value changes.
 * It ensures that the component's state remains in sync with the persisted storage value.
 *
 * @effect
 * @dependency {boolean} isPollingStorage - The persisted polling state from local storage.
 */
  useEffect(() => {
    setPolling((prevState) => ({ ...prevState, isPolling: isPollingStorage }));
  }, [isPollingStorage]);


/**
 * @description Callback function allowing to configure the polling state.
 *
 * @param {Partial<PollingState>} config - The configuration to apply to the polling state.
 *
 * @callback
 * @dependency {function} setIsPollingStorage - setter function for the `isPollingStorage` state.
 *
 * @returns {void}
 */
  const setPollingConfig = useCallback(
    (config: Partial<PollingState>) => {
      setPolling((prevState) => {
        const newState = { ...prevState, ...config };
        if (newState.isPolling !== prevState.isPolling) {
          setIsPollingStorage(newState.isPolling);
        }
        return newState;
      });
    },
    [setIsPollingStorage]
  );

  /**
   * @description Starts the polling process.
   *
   * @callback
   * @dependency {function} setIsPollingStorage - setter function for the `isPollingStorage` state.
   * @dependency {function} setPolling - setter function for the `state` state.
   * @dependency {function} setPollingStartTime - setter function for the `pollingStartTime` state.
  */
  const startPolling = useCallback(() => {
    setPolling((prevState) => ({ ...prevState, isPolling: true }));
    setIsPollingStorage(true);
    setPollingStartTime(Date.now());
  }, [setIsPollingStorage, setPolling, setPollingStartTime]);

  /**
   * @description Stop the polling process.
   *
   * @callback
   * @dependency {function} setIsPollingStorage - setter function for the `isPollingStorage` state.
   * @dependency {function} setPolling - setter function for the `state` state.
   * @dependency {function} setPollingStartTime - setter function for the `pollingStartTime` state.
  */
  const stopPolling = useCallback(() => {
    setPolling((prevState) => ({ ...prevState, isPolling: false }));
    setIsPollingStorage(false);
    setPollingStartTime(null);
  }, [setIsPollingStorage, setPolling, setPollingStartTime]);

  return (
    <PollingContext.Provider
      value={{
        ...polling,
        setPollingConfig,
        startPolling,
        stopPolling,
      }}
    >
      {children}
    </PollingContext.Provider>
  );
};
