import React, { useMemo } from "react";

import { Box, Stack } from "@mui/material";
import Logo from "@/taskpane/components/logo/Logo";
import { useWindowSize } from "@uidotdev/usehooks";

export function Header() {
  const { height } = useWindowSize();

  const isSmallScreen = useMemo(() => height ? height < 800 : false, [height]);

  const defaultStyles = {
    box: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      margin: "0 auto",
      maxWidth: "800px",
      width: "100%",
      zIndex: 0,
      pt: 1
    },
    logo: {
      height: "48px",
      margin: "0 auto",
    }
  };

  const smallStyles = {
    box: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      margin: "0 auto",
      maxWidth: "800px",
      width: "100%",
      zIndex: 2,
      pt: "2px"
    },
    logo: {
      height: "40px",
      margin: "auto"
    }
  };

  return (
    <Box
      sx={isSmallScreen ? smallStyles.box : defaultStyles.box}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" sx={{ zIndex: 2, position: "relative" }}>
        <Logo height={isSmallScreen ? smallStyles.logo.height : defaultStyles.logo.height}/>
      </Stack>
    </Box>
  );
}
