/**
 * AuthContext and AuthProvider
 *
 * This module implements the authentication context for the application.
 *
 * TODO: Implementing token refresh mechanism.
 * TODO: Add error handling for JSON parsing in useEffect.
 * TODO: Implement secure storage for tokens (e.g., encrypted localStorage or httpOnly cookies).
 */

import { getAuthTokens, saveAuthTokens, TOKEN_STORAGE_KEY } from "@/taskpane/utils/client";
import { Tokens } from "@/taskpane/types/auth";
import React, { createContext, useEffect, useState } from "react";

interface AuthContextProps {
  isAuthenticated: boolean;
  setTokens: (newTokens: Omit<Tokens, "ExpiresOn">) => void;
  logout: () => void;
  tokens?: Tokens;
  isLoading: boolean;
}

export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  setTokens() {},
  logout() {},
  isLoading: true,
});

// TODO: Implement secure storage for tokens (e.g., encrypted localStorage or httpOnly cookies).

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const storedTokens = await getAuthTokens();
        if (storedTokens) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error parsing authentication tokens:", error);
        logout();
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const setTokensFct = (newTokens: Omit<Tokens, "ExpiresOn">) => {
    setIsAuthenticated(true);
    saveAuthTokens(newTokens);
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    localStorage.removeItem('session');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setTokens: setTokensFct, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
