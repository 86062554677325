import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FixedSizeList } from "react-window";
import { useNavigate } from "react-router-dom";

import { Folder } from "@/taskpane/types/folder";
import { useQuery } from "@tanstack/react-query";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import { DOSSIER_STATUS_INOT } from "@/taskpane/config/label";
import { getAttachments } from "@/taskpane/utils/attachments";
import { usePolling } from "@/taskpane/services/polling.hook";
import { useGetUserDataQuery } from "@/taskpane/services/user.hook";
import { useLocalStorage, useWindowSize } from "@uidotdev/usehooks";
import { useGetUserAttributesQuery } from "@/taskpane/services/auth.hook";
import { CloseOutlined, SyncAlt as SyncAltIcon } from "@mui/icons-material";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";
import { ControlCenterIcon } from "@/taskpane/components/icons/control-center";
import { SelectInitialesDialog } from "@/taskpane/modules/folders/SelectInitialesModal";
import { useGetFoldersQuery, useGetSubFoldersQuery } from "@/taskpane/services/folders.hook";
import { alpha, Box, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, List, ListItemButton, PaletteColor, Skeleton, Stack, styled, TextField, Theme, Tooltip, tooltipClasses, Typography, useTheme } from "@mui/material";
import styles from "./FoldersList.styles"

import type { SyncStatusTooltipProps, SyncStatus } from "./FoldersList.types";

/**
 * @description Tooltip embeding description of the synchronization status of a folder.
 */
const SyncStatusTooltip = styled(({ className, color = "secondary", ...props }: SyncStatusTooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} color={color} />
))(
  ({
    theme,
    color = "secondary",
  }: {
    theme: Theme;
    color: "primary" | "secondary" | "error" | "warning" | "info" | "success";
  }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: (theme.palette?.[color] as PaletteColor)?.light,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: (theme.palette?.[color] as PaletteColor)?.light,
      color: (theme.palette?.[color] as PaletteColor)?.main,
    },
  })
);

/**
 * @description Renders a single folder item in the folders list.
 *
 * @param {Object} props - The props for the component.
 * @param {Folder} props.folder - The folder object to render.
 * @param {() => void} props.onClick - The click event handler for the folder item.
 * @param {boolean} props.selected - Indicates whether the folder item is selected.
 *
 * @returns {JSX.Element} The rendered component.
 */
function FolderItem({ folder, onClick, selected }: { folder: Folder; onClick: () => void; selected: boolean }) {
  const { data: subFolders, isLoading: subFoldersLoading } = useGetSubFoldersQuery(folder?.dossier_id, {
    enabled: !!folder?.dossier_id,
    staleTime: 0,
  });

  const navigate = useNavigate();

  const theme = useTheme();


  /**
   * @description Computes the synchronization status of the folder based on the presence and value of the key: sous_dossier_import_partenaire_auto.
   *
   * If none of the subfolders are synchronized, the folder is considered unsynchronized.
   *
   * If all subfolders are synchronized, the folder is considered synchronized.
   *
   * Otherwise, the folder is considered partially synchronized.
   *
   * @memoization
   * @dependency {folder?.dossier_id} - The ID of the dossier.
   * @dependency {subFolders} - The list of sous-dossiers.
   *
   * @returns {string} The synchronization status of the folder. Either "loading", "synced", "unsync", or "partialSynced".
   */
  const syncStatus = useMemo(() => {
    if (subFoldersLoading) {
      return "loading";
    }

    if (!subFolders || !subFolders.length) {
      return "unsync";
    }

    const synced = subFolders.every((sf) => {
      const value = sf?.sous_dossier_import_partenaire_auto;
      return (typeof value === "string" && value !== "") || (typeof value === "boolean" && value === true);
    });

    const unsynced = subFolders.every(
      (sf) =>
        !sf?.sous_dossier_import_partenaire_auto ||
        (typeof sf.sous_dossier_import_partenaire_auto === "string" && sf.sous_dossier_import_partenaire_auto === "")
    );

    return synced ? "synced" : unsynced ? "unsync" : "partialSynced";
  }, [folder?.dossier_id, subFolders, subFoldersLoading]);

  /**
   * @description Computes the props for the sync icon based on the sync status.
   *
   * @memoization
   * @dependency {syncStatus} - The synchronization status of the folder. Either "loading", "synced", "unsync", or "partialSynced".
   *
   * @returns {SyncStatus} The props for the sync icon.
   */
  const getSyncStatus = useMemo(() => {
    switch (syncStatus) {
      case "loading":
        return { label: "Chargement...", color: "secondary", value: "loading" } as SyncStatus;
      case "synced":
        return {
          label: "Analyse automatique des documents présents dans le logiciel métier activée",
          color: "success",
          value: "synced",
        } as SyncStatus;
      case "unsync":
        return {
          label: "Pas d’analyse automatique des documents présents dans le logiciel métier",
          color: "error",
          value: "unsync",
        } as SyncStatus;
      case "partialSynced":
        return {
          label:
            "Analyse automatique des documents présents dans le logiciel métier restreint à certains sous-dossiers",
          color: "warning",
          value: "partialSynced",
        } as SyncStatus;
      default:
        return { label: "N/A", color: "secondary", value: "loading" } as SyncStatus;
    }
  }, [syncStatus]);


  return (
    <ListItemButton
      sx={styles.listItemButton(theme, alpha)}
      onClick={onClick}
      selected={selected}
    >
      <Grid container alignItems="center">

        {/* First column: Name and creation date */}
        <Grid item xs={9}>
          <Stack direction="row" alignItems="space-between" spacing={1}>

            {/* First column: Sync status icon */}
            <SyncStatusTooltip title={getSyncStatus.label} color={getSyncStatus.color} theme={theme}>
              <IconButton
                color={getSyncStatus.color}
                size="small"
                sx={styles.iconButton(getSyncStatus.color, selected, theme, alpha)}
              >
                <SyncAltIcon
                  fontSize="small"
                  sx={styles.syncIcon(getSyncStatus.color)}
                />
              </IconButton>
            </SyncStatusTooltip>

            <Stack sx={styles.dossierNameAndCreationDateContainer}>

              {/* First column: Folder name */}
              <Tooltip title={folder?.name?.length > 30 ? folder?.name : ""}>
                <Typography
                  fontWeight="bold"
                  fontSize={11}
                  noWrap
                  sx={styles.dossierName}
                >
                  {folder?.name ?? "N/A"}
                </Typography>
              </Tooltip>

              {/* First column: Folder creation date */}
              <Typography noWrap fontSize={10} fontWeight="bold" color="black" sx={styles.creationDate}>
                Créé le {folder?.created_at.toLocaleDateString()} ({folder?.intervenants_notaires.join("/")})
              </Typography>

            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={3} sx={{ pl: 0 }}>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={1} wrap="nowrap">

            {/* Second column: Details button */}
            <Grid item>
              {selected && (
                <IconButton
                  size="small"
                  color="inherit"
                  sx={styles.controlCenterIcon(theme)}
                  edge="start"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/folder/${folder.dossier_id}/details`);
                  }}
                >
                  <ControlCenterIcon fontSize="small" sx={{ "&.MuiSvgIcon-root": { fontSize: "18px" } }} />
                </IconButton>
              )}
            </Grid>

            {/* Third column: ID and status */}
            <Grid item>
              <Stack alignItems="flex-end">

                <Typography color="black" sx={{ opacity: 0.5 }} fontWeight="bold" fontSize={11} noWrap>
                  {folder?.dossier_id ?? "N/A"}
                </Typography>

                <Typography color="black" sx={{ opacity: 0.5 }} fontWeight="bold" fontSize={11} noWrap>
                  {DOSSIER_STATUS_INOT?.[`${folder?.status as keyof typeof DOSSIER_STATUS_INOT}`] ?? "N/A"}
                </Typography>

              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItemButton>
  );
}

/**
 * Component to display a message when no folders are found.
 * @returns {JSX.Element} The rendered component.
 */
function NoFolders() {
  return (
    <Typography fontWeight="bold" color="grey.400" textAlign="center" p={3}>
      Aucun dossier correspondant à ces critères n’a été trouvé
    </Typography>
  );
}


/**
 * @description Main component to render the list of folders.
 * @returns {JSX.Element} The rendered component.
 */
export default function FolderList() {
  const [search, setSearch] = useState("");
  const [showMyFolders, setShowMyFolders] = useState(false);
  const [showInProgress, setShowInProgress] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const [currentEmailId, setCurrentEmailId] = useState<string | undefined>(undefined);
  const [hasAttachments, setHasAttachments] = useState(false);

  const [refreshCount, setRefreshCount] = useLocalStorage<number>("refreshCount", 0);
  const [isPopupBlockFor24Hour, setIsPopupBlockFor24Hour] = useLocalStorage<boolean | null>("isPopupBlockFor24Hour", null);
  const [isPopupBlockFor24HourSince, setIsPopupBlockFor24HourSince] = useLocalStorage<number | null>("isPopupBlockFor24HourSince", null);

  const size = useWindowSize();

  const navigate = useNavigate();

  const { changeCounter, currentMailbox } = useOfficeContext();

  const { isPolling, setPollingConfig, startPolling } = usePolling();

  const { data: foldersData, isLoading } = useGetFoldersQuery();
  const { data: userData } = useGetUserDataQuery();
  const { data: dataAttachments, refetch } = useQuery({
    queryKey: ["getAttachments", currentEmailId],
    queryFn: getAttachments,
    enabled: !!currentEmailId,
  });
  const { data: currentUser, isLoading: isLoadingCurrentUser } = useGetUserAttributesQuery();

  const folders = foldersData?.dossiers || [];

  /**
   * @description Updates the current email ID based on the Office context mailbox item.
   *
   * @returns {Promise<void>} A promise that resolves when the current email ID is updated.
   */
  const updateCurrentEmail = useCallback(async () => {
    if (currentMailbox?.item) {
      const newEmailId = currentMailbox.item.itemId;
      setCurrentEmailId(newEmailId);
    }
  }, [setCurrentEmailId, currentMailbox]);

  /**
   * @description Filters the folders based on the provided criteria.
   *
   * @param {Folder[]} folders - The list of folders to filter.
   * @param {boolean} showMyFolders - Indicates whether to show only the user's folders.
   * @param {boolean} showInProgress - Indicates whether to show only folders in progress.
   * @param {string} search - The search term to filter folders by name or dossier ID.
   * @param {UserData} userData - The user data containing information about the user.
   *
   * @returns {Folder[]} - The filtered list of folders.
   */
  const filteredFolders = useMemo(
    () =>
      (folders ?? ([] as Folder[])).filter((folder) => {
        const matchesSearch =
          folder.name.toLowerCase().includes(search.toLowerCase()) ||
          folder.dossier_id.toLowerCase().includes(search.toLowerCase());
        const matchesMyFolders =
          !showMyFolders ||
          (userData?.user_info?.initiales_inot &&
            folder?.dossier_intervenants_notaire.includes(userData.user_info.initiales_inot));
        const matchesInProgress = !showInProgress || folder.status === "en_cours";
        return matchesSearch && matchesMyFolders && matchesInProgress;
      }),
    [folders, showMyFolders, showInProgress, search, userData]
  );

  /**
   * @description Computes for each render if the modal allowing the user to select their initials should be displayed.
   *
   * @dependency {isPolling} - Indicates whether the polling is active.
   * @dependency {currentUser} - The current user data. If the user has no partner ID, that's meaning that the user has not selected or found their initials yet.
   */
  const shouldDisplaySelectionUserInfosModal = useMemo(() => {
    return !isLoadingCurrentUser && !currentUser?.partenaireId && !isPolling
  }, [isPolling, currentUser, isLoadingCurrentUser]);

  /**
   * @description Computes for each render if the modal allowing the user to select their initials should be block during 24 hour because of exceed the limit of refresh count (2 times).
   *
   * @dependency {refreshCount} -
   * @dependency {isPopupBlockFor24Hour} -
   * @dependency {isPopupBlockFor24HourSince} -
   */
  const shouldBlockSelectionUserInfosModal = useMemo(() => {
    if (refreshCount >= 2 && isPopupBlockFor24Hour && isPopupBlockFor24HourSince) {
      const now = Date.now();
      const diffBetweenNowAndIsBlockedSinceInMs = now - isPopupBlockFor24HourSince;
      const diffBetweenNowAndIsBlockedSinceInSeconds = diffBetweenNowAndIsBlockedSinceInMs / 1000;
      const diffBetweenNowAndIsBlockedSinceIsLessThan24Hour = diffBetweenNowAndIsBlockedSinceInSeconds <= 24 * 60 * 60;

      if (diffBetweenNowAndIsBlockedSinceIsLessThan24Hour) {
        return true;
      } else {
        setRefreshCount(0);
        setIsPopupBlockFor24Hour(false);
        setIsPopupBlockFor24HourSince(null);

        return false;
      }
    } else {
      return false;
    }
  }, [refreshCount, isPopupBlockFor24Hour, isPopupBlockFor24HourSince, setRefreshCount, setIsPopupBlockFor24Hour, setIsPopupBlockFor24HourSince]);

  const displayModal = shouldBlockSelectionUserInfosModal ? false : shouldDisplaySelectionUserInfosModal;


  /**
   * @description Effect hook to update the current email ID and refetch the attachments when the current email ID changes.
   *
   * @effect
   * @dependency {updateCurrentEmail} - The function to update the current email ID.
   */
  useEffect(() => {
    updateCurrentEmail();
    const intervalId = setInterval(() => {
      updateCurrentEmail();
    }, 5000); // Check for updates every 5 seconds
    return () => clearInterval(intervalId);
  }, [updateCurrentEmail]);

  /**
   * @description Effect hook to refetch the attachments when the current email ID changes.
   *
   * @effect
   * @dependency {currentEmailId} - The current email ID.
   */
  useEffect(() => {
    if (currentEmailId) {
      refetch();
    }
  }, [currentEmailId, refetch]);

  /**
   * @description Effect hook to update the hasAttachments state based on the dataAttachments.
   *
   * @effect
   * @dependency {dataAttachments} - The attachments data.
   */
  useEffect(() => {
    setHasAttachments(!!dataAttachments && dataAttachments?.attachments && dataAttachments?.attachments?.length > 0);
  }, [dataAttachments]);

  /**
   * @description Effect hook to update the current email ID when the changeCounter or currentMailbox changes.
   *
   * @effect
   * @dependency {changeCounter} - The change counter.
   * @dependency {currentMailbox} - The current mailbox.
   */
  useEffect(() => {
    updateCurrentEmail();
  }, [changeCounter, currentMailbox]);

  /**
   * @description Effect hook to update the selected folder when the filtered folders change.
   *
   * @effect
   * @dependency {selectedFolder} - The currently selected folder.
   * @dependency {filteredFolders} - The filtered list of folders.
   */
  useEffect(() => {
    if (!selectedFolder) {
      return;
    }
    if (!filteredFolders.find((f) => f.dossier_id === selectedFolder.dossier_id)) {
      setSelectedFolder(undefined);
    }
  }, [selectedFolder, filteredFolders]);

  /**
   * @description Renders a row in the FoldersList component. Essential for the react-window FixedSizeList component.
   *
   * @param index - The index of the row.
   * @param style - The CSS styles to apply to the row (generated by react-window).
   *
   * @callback
   * @dependency {filteredFolders} - The filtered list of dossiers.
   * @dependency {isLoading} - Indicates whether the dossiers are loading.
   * @dependency {selectedFolder} - The currently selected dossier.
   */
  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      if (isLoading) {
        return (
          <div style={style}>
            <ListItemButton sx={{ opacity: 1 - index * 0.1 }}>
              <Grid container alignItems="center" spacing={1}>
                {/* First column skeleton */}
                <Grid item xs={7}>
                  <Stack spacing={0.5}>
                    <Skeleton animation="wave" variant="text" width="90%" height={20} />
                    <Skeleton animation="wave" variant="text" width="70%" height={14} />
                  </Stack>
                </Grid>

                {/* Second column skeleton */}
                <Grid item xs={3}>
                  <Stack alignItems="flex-end" spacing={0.5}>
                    <Skeleton animation="wave" variant="text" width="80%" height={20} />
                    <Skeleton animation="wave" variant="text" width="60%" height={16} />
                  </Stack>
                </Grid>

                {/* Third column skeleton */}
                <Grid item xs={2} sx={{ textAlign: "right", pl: 0 }}>
                  <Skeleton animation="wave" variant="rectangular" width={80} height={32} sx={{ borderRadius: 1 }} />
                </Grid>
              </Grid>
            </ListItemButton>
            <Divider sx={{ width: "60%", mx: "auto", borderColor: "grey.300", opacity: 1 - index * 0.1 }} />
          </div>
        );
      }

      const folder = filteredFolders[index];
      return (
        <div style={style}>
          <FolderItem
            folder={folder}
            onClick={() => setSelectedFolder(folder)}
            selected={folder.dossier_id === selectedFolder?.dossier_id}
          />
        </div>
      );
    },
    [filteredFolders, isLoading, selectedFolder]
  );

  return (
    <Stack p={0} gap={1} height={0.92} sx={{ position: "relative" }}>

      {/* Access blocked message */}
      {foldersData?.client_blocked && (
        <Box
          sx={styles.blockedMessageContainer}
        >
          <Box
            sx={styles.blockedReasonText}
          >
            <Typography variant="h6" color="primary" fontWeight="bold">
              {foldersData.client_blocked_reason || "Accès bloqué"}
            </Typography>
          </Box>
        </Box>
      )}

      {/* Dossiers search bar */}
      <Box sx={{ mt: 1 }}>
        <TextField
          autoFocus
          sx={{ bgcolor: "white", padding: 0, mb: 0.5 }}
          fullWidth
          size="small"
          variant="outlined"
          margin="none"
          placeholder="Rechercher un dossier ..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            size: "small",
            sx: { padding: "0!important", "& ::placeholder": { fontSize: "12px!important" } },
            endAdornment: search && (
              <IconButton size="small" onClick={() => setSearch("")}>
                <CloseOutlined />
              </IconButton>
            ),
          }}
        />

        {/* Dossiers filter checkboxes */}
        <Stack pl={1.5} mt={0.5} mb={0.5} direction="row">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showMyFolders}
                color="primary"
                onChange={(e) => setShowMyFolders(e.target.checked)}
                sx={{ mr: 0.5 }}
              />
            }
            label="Mes dossiers"
            slotProps={{ typography: { fontSize: 12 } }}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="primary"
                checked={showInProgress}
                onChange={(e) => setShowInProgress(e.target.checked)}
                sx={{ mr: 0.5 }}
              />
            }
            label="En cours"
            slotProps={{ typography: { fontSize: 12 } }}
          />
        </Stack>

      </Box>

      {/* Folders list */}
      {(isLoading || !!filteredFolders.length) && (
        <FixedSizeList
          height={window.innerHeight - 200}
          itemSize={50}
          itemCount={isLoading ? 8 : filteredFolders.length}
          width="100%"
          style={styles.listStyles}
        >
          {Row}
        </FixedSizeList>
      )}

      {/* Folders list if content is empty */}
      {!isLoading && !filteredFolders.length && (
        <List
          sx={{
            ...styles.listStyles,
            height: "100%",
          }}
        >
          <NoFolders />
        </List>
      )}

      {/* Bottom action bar (Pieces-jointes, Glissez-déposez, Espace notarial) */}
      <Grid container spacing={{ xs: 2, }} columns={{ xs: 3 }}>
        <Grid item xs={1}>
          <WarningTooltip
            title={
              !selectedFolder
                ? "Vous devez sélectionner un dossier"
                : hasAttachments
                  ? ""
                  : "Aucune pièce jointe disponible"
            }
          >
            <span>
              <Button
                size="large"
                fullWidth
                disabled={!selectedFolder || !hasAttachments}
                onClick={() => navigate(`/folder/${selectedFolder?.dossier_id}/attachment`)}
                sx={size?.width ? styles.bottomNavButtons(size.width) : {}}
              >
                Pièces jointes
              </Button>
            </span>
          </WarningTooltip>
        </Grid>
        <Grid item xs={1}>
          <WarningTooltip title={!selectedFolder ? "Vous devez sélectionner un dossier" : ""}>
            <span>
              <Button
                size="large"
                fullWidth
                disabled={!selectedFolder}
                onClick={() => navigate(`/folder/${selectedFolder?.dossier_id}/drag-and-drop`)}
                sx={size?.width ? styles.bottomNavButtons(size.width) : {}}
              >
                Glissez-déposez
              </Button>
            </span>
          </WarningTooltip>
        </Grid>
        <Grid item xs={1}>
          <WarningTooltip title={!selectedFolder ? "Vous devez sélectionner un dossier" : ""}>
            <span>
              <Button
                size="large"
                fullWidth
                disabled={!selectedFolder}
                onClick={() => navigate(`/folder/${selectedFolder?.dossier_id}/notary-space`)}
                sx={size?.width ? styles.bottomNavButtons(size.width) : {}}
              >
                Espace-notarial
              </Button>
            </span>
          </WarningTooltip>
        </Grid>
      </Grid>

      {/* Select partenaire modal */}
      <SelectInitialesDialog
        id="select-initiales-dialog"
        open={!!displayModal}
        keepMounted={false}
        setPollingConfig={setPollingConfig}
        startPolling={startPolling}
        isPolling={isPolling}
      />
    </Stack>
  );
}
