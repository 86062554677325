import LoadingBar from "@/taskpane/components/loading-bar/LoadingBar";
import { NOTARY_SPACES } from "@/taskpane/config";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import { Button, Card, IconButton, Stack, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { MdRemoveRedEye } from "react-icons/md";
import styles from "./NotarySpaceLogin.styles";

export interface NotarySpaceLoginProps {
  startConnection: (payload: { username: string; password: string; url: string }) => void;
  connectionPending: boolean;
}

export default function NotarySpaceLogin({ startConnection, connectionPending }: NotarySpaceLoginProps) {
  const theme = useTheme();
  const [currentSpace, setCurrentSpace] = useState(NOTARY_SPACES[0].url);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [timeoutReached, setTimeoutReached] = useState(false);

  React.useEffect(() => {
    if (connectionPending) {
      const timer = setTimeout(() => {
        setTimeoutReached(true);
      }, 5 * 60 * 1000); // 5 minutes

      return () => clearTimeout(timer);
    }
  }, [connectionPending]);

  return (
    <Stack sx={styles.container}>
      <Card sx={styles.card(connectionPending)}>
        <Stack sx={styles.form}>
          <Tabs
            value={currentSpace}
            onChange={(_, value) => setCurrentSpace(value)}
            variant="fullWidth"
            sx={{ ...styles.tabs, borderBottom: `1px solid ${theme.palette.divider}` }}
          >
            {NOTARY_SPACES.map((space) => {
              if(space.name === "admin.espacenotarial") {
                return <WarningTooltip key={space.url} title={space.name === "admin.espacenotarial" ? "La fonctionnalité d’ajout de dataroom en mode admin est temporairement désactivée" : ""}>
                <span>
                  <Tab value={space.url} label={space.name} disabled/>
                </span>
              </WarningTooltip>
              } else {
                return <Tab value={space.url} label={space.name}/>
              }
            })}
          </Tabs>
          <TextField
            label="Identifiant"
            variant="outlined"
            size="small"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Mot de passe"
            variant="outlined"
            size="small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={isPasswordVisible ? "text" : "password"}
            InputProps={
              password
                ? {
                    endAdornment: (
                      <IconButton
                        onClick={() => setIsPasswordVisible((prev) => !prev)}
                        color={isPasswordVisible ? "warning" : "default"}
                      >
                        <MdRemoveRedEye />
                      </IconButton>
                    ),
                  }
                : {}
            }
          />
        </Stack>
      </Card>
      {connectionPending ? (
        <>
          <LoadingBar text="Test des identifiants en cours..." />
          {timeoutReached && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              Le test de connexion prend trop de temps. Vous pouvez contacter le support en cliquant{" "}
              <a href="mailto:support-notaires@bylaw.fr">ici</a>.
            </Typography>
          )}
        </>
      ) : (
        <WarningTooltip title={!username || !password ? "Vous devez renseigner un identifiant et un mot de passe" : ""}>
          <span>
            <Button
              variant="outlined"
              color="success"
              disabled={!username || !password}
              onClick={() => startConnection({ username, password, url: currentSpace })}
              sx={styles.button}
            >
              Tester les identifiants
            </Button>
          </span>
        </WarningTooltip>
      )}
    </Stack>
  );
}
