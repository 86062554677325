import Logo from "@/taskpane/components/logo/Logo";
import { enqueueConfirmSnackbar } from "@/taskpane/components/snackbar-provider/SnackbarProvider";
import VerticalStepper from "@/taskpane/components/vertical-stepper/VerticalStepper";
import NotarySpaceDataroom from "@/taskpane/modules/folder/notary-space/steps/notary-space-dataroom/NotarySpaceDataroom";
import NotarySpaceLogin from "@/taskpane/modules/folder/notary-space/steps/notary-space-login/NotarySpaceLogin";
import NotarySpaceSubfolder from "@/taskpane/modules/folder/notary-space/steps/notary-space-subfolder/NotarySpaceSubfolder";
import { useGetFoldersQuery } from "@/taskpane/services/folders.hook";
import {
  useAssociateDataroomToFolderMutation,
  useNotarySpaceConnectionMutation,
} from "@/taskpane/services/notary-space.hook";
import { NotarySpaceSteps } from "@/taskpane/types/notary-space";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import { SubFolder } from "@/taskpane/types/folder";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";

export default function FolderNotarySpace() {
  const params = useParams();
  const navigate = useNavigate();
  const { data: folders } = useGetFoldersQuery();
  const [selectedSubFolder, setSelectedSubFolder] = useState<SubFolder>();
  const [currentStep, setCurrentStep] = useState(NotarySpaceSteps.SELECT_SUBFOLDER);
  const [selectedDataRoom, setSelectedDataRoom] = useState<string | undefined>();
  const connection = useNotarySpaceConnectionMutation({
    onSuccess: () => {
      setCurrentStep(currentStep + 1);
    },
    onError: () => {
      enqueueConfirmSnackbar(
        "Identifiants incorrects",
        () => {},
        "OK",
        {
          persist: true,
          variant: "error",
          action: (snackbarId: SnackbarKey | undefined) => (
            <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
          )
        }
      );
    },
  });
  const associateDataroom = useAssociateDataroomToFolderMutation({
    onSuccess: () => {
      enqueueConfirmSnackbar(
        "Envoi terminé, l’analyse est en cours et les résultats seront directement intégrés dans iNot.",
        () => setCurrentStep(0),
        "Confirmer",
        {
          persist: true,
          anchorOrigin: { vertical: "center", horizontal: "center" }
        }
      );
    },
    onError: () => {
      const snackbar = enqueueSnackbar("Un problème est survenu", {
        variant: "error",
        action: (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              closeSnackbar(snackbar);
              setCurrentStep(NotarySpaceSteps.DONE);
              associateDataroom.mutate({
                folderId: params.folderId || "",
                connectionId: connection.data?.connection_id || "",
                dataRoomId: selectedDataRoom || "",
              });
            }}
          >
            Réessayer
          </Button>
        ),
      });
    },
  });

  const currentFolder = useMemo(
    () => folders?.find((f) => f.dossier_id === params.folderId),
    [folders, params.folderId]
  );

  const steps = useMemo(
    () => [
      {
        title: "Sélection du sous-dossier iNot",
        subtitle: "Choisir le sous-dossier iNot auquel rattacher la dataroom",
        component: (
          <NotarySpaceSubfolder
            folderId={params.folderId || ""}
            selectedSubFolder={selectedSubFolder}
            setSelectedSubFolder={setSelectedSubFolder}
            refreshConnection={(connection_id: string) => {
              connection.mutate({ connection_id, folderId: params.folderId || "" });
              setCurrentStep(currentStep + 1);
            }}
          />
        ),
      },
      {
        title: "Connexion à l’espace notarial",
        subtitle: "Renseigner les identifiants de connexion",
        component: (
          <NotarySpaceLogin
            startConnection={({ username, password, url }) => {
              connection.mutate({
                url,
                folderId: params.folderId || "",
                identifiant: username,
                mot_de_passe: password,
                sous_dossier_id: selectedSubFolder?.sous_dossier_id || "",
              });
            }}
            connectionPending={connection.isPending}
          />
        ),
      },
      {
        title: "Sélection de la dataroom ",
        subtitle: "Choisir la dataroom espace-notarial à analyser",
        component: (
          <NotarySpaceDataroom
            datarooms={connection.data?.all_datarooms || []}
            selectedDataroom={selectedDataRoom}
            setSelectedDataroom={setSelectedDataRoom}
          />
        ),
      },
    ],
    [selectedSubFolder, connection, currentStep, params.folderId]
  );

  return (
    <Stack p={0} gap={1} maxHeight={0.92}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {/* <Logo /> */}
        {currentStep === NotarySpaceSteps.LOGIN && !connection.isPending && (
          <Button onClick={() => setCurrentStep(currentStep - 1)} sx={{ position: "absolute", bottom: 20, left: 20 }}>
            Retour
          </Button>
        )}
      </Stack>
      <Typography fontSize={12} color="secondary.500" fontWeight="bold" sx={{ maxWidth: "75vw" }} noWrap>
        Dossier: {currentFolder?.name}
      </Typography>
      <Stack justifyContent="space-between" height="100%">
        <VerticalStepper steps={steps} currentStep={currentStep} />
        {currentStep === 0 && (
          <Stack direction="row" spacing={2} justifyContent="center" my={3}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
              Précédent
            </Button>
            <WarningTooltip title={selectedSubFolder ? "" : "Vous devez sélectionner un sous-dossier"}>
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setCurrentStep(currentStep + 1)}
                  disabled={!selectedSubFolder}
                >
                  Suivant
                </Button>
              </span>
            </WarningTooltip>
          </Stack>
        )}
        {currentStep === 2 && (
          <Stack direction="row" spacing={2} justifyContent="center" my={3}>
            <Button variant="contained" color="primary" onClick={() => setCurrentStep(currentStep - 1)}>
              Précédent
            </Button>
            <WarningTooltip title={!selectedDataRoom ? "Vous devez sélectionner une dataroom" : ""}>
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    associateDataroom.mutate({
                      folderId: params.folderId || "",
                      connectionId: connection.data?.connection_id || "",
                      dataRoomId: selectedDataRoom || "",
                    });
                    setCurrentStep(currentStep + 1);
                  }}
                  disabled={!selectedDataRoom}
                >
                  Valider
                </Button>
              </span>
            </WarningTooltip>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
