import { Button, Stack, Typography } from "@mui/material";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { enqueueConfirmSnackbar } from "@/taskpane/components/snackbar-provider/SnackbarProvider";
import { NotarySpaceSteps } from "@/taskpane/types/notary-space";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";
import { SubFolder } from "@/taskpane/types/folder";
import { useAssociateDataroomToFolderMutation, useNotarySpaceConnectionMutation } from "@/taskpane/services/notary-space.hook";
import { useGetFoldersQuery } from "@/taskpane/services/folders.hook";
import { useNavigate, useParams } from "react-router-dom";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import NotarySpaceDataroom from "@/taskpane/modules/folder/notary-space/steps/notary-space-dataroom/NotarySpaceDataroom";
import NotarySpaceLogin from "@/taskpane/modules/folder/notary-space/steps/notary-space-login/NotarySpaceLogin";
import NotarySpaceSubfolder from "@/taskpane/modules/folder/notary-space/steps/notary-space-subfolder/NotarySpaceSubfolder";
import React, { useMemo, useState } from "react";
import VerticalStepper from "@/taskpane/components/vertical-stepper/VerticalStepper";

export default function FolderNotarySpace() {
  const [selectedDataRoom, setSelectedDataRoom] = useState<string | undefined>();
  const [selectedSubFolder, setSelectedSubFolder] = useState<SubFolder>();
  const [currentStep, setCurrentStep] = useState(NotarySpaceSteps.SELECT_SUBFOLDER);

  const params = useParams();

  const navigate = useNavigate();

  const { data: folders } = useGetFoldersQuery();

  const connection = useNotarySpaceConnectionMutation({
    onSuccess: () => {
      setCurrentStep(currentStep + 1);
    },
    onError: () => {
      enqueueConfirmSnackbar(
        "Identifiants incorrects",
        () => { },
        "OK",
        {
          persist: true,
          variant: "error",
          action: (snackbarId: SnackbarKey | undefined) => (
            <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
          )
        }
      );
    },
  });

  const associateDataroom = useAssociateDataroomToFolderMutation({
    onSuccess: () => {
      enqueueConfirmSnackbar(
        "Envoi terminé, l’analyse est en cours et les résultats seront directement intégrés dans votre logiciel.",
        () => setCurrentStep(0),
        "Confirmer",
        {
          persist: true,
          anchorOrigin: { vertical: "center", horizontal: "center" }
        }
      );
    },
    onError: () => {
      const snackbar = enqueueSnackbar("Un problème est survenu", {
        variant: "error",
        action: (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              closeSnackbar(snackbar);
              setCurrentStep(NotarySpaceSteps.DONE);
              associateDataroom.mutate({
                folderId: params.folderId || "",
                connectionId: connection.data?.connection_id || "",
                dataRoomId: selectedDataRoom || "",
              });
            }}
          >
            Réessayer
          </Button>
        ),
      });
    },
  });

  /**
   * @description Get the current dossier from the list of dossiers which contain the sous-dossier
   *
   * @memoization
   * @dependency {Folder[]} folders - The list of dossier
   * @dependency {string} params.folderId - Dossier ID
   *
   * @returns {Folder} The current dossier
   */
  const currentFolder = useMemo(
    () => folders?.dossiers.find((f) => f.dossier_id === params.folderId),
    [folders, params.folderId]
  );

  /**
   * @description Dataroom association process steps
   *
   * @memoization
   * @dependency {SubFolder} selectedSubFolder - The selected sous-dossier to associate to the dataroom
   * @dependency {NotarySpaceConnection} connection - Object containinng the state of the connection
   * @dependency {number} currentStep - The current step of the process
   * @dependency {string} params.folderId - Dossier ID
   *
   */
  const steps = useMemo(
    () => [
      {
        title: "Sélection du sous-dossier",
        subtitle: "Choisir le sous-dossier auquel rattacher la dataroom",
        component: (
          <NotarySpaceSubfolder
            folderId={params.folderId || ""}
            selectedSubFolder={selectedSubFolder}
            setSelectedSubFolder={setSelectedSubFolder}
            refreshConnection={(connection_id: string) => {
              connection.mutate({ connection_id, folderId: params.folderId || "" });
              setCurrentStep(currentStep + 1);
            }}
          />
        ),
      },
      {
        title: "Connexion à l’espace notarial",
        subtitle: "Renseigner les identifiants de connexion",
        component: (
          <NotarySpaceLogin
            startConnection={({ username, password, url }) => {
              connection.mutate({
                url,
                folderId: params.folderId || "",
                identifiant: username,
                mot_de_passe: password,
                sous_dossier_id: selectedSubFolder?.sous_dossier_id || "",
              });
            }}
            connectionPending={connection.isPending}
          />
        ),
      },
      {
        title: "Sélection de la dataroom ",
        subtitle: "Choisir la dataroom espace-notarial à analyser",
        component: (
          <NotarySpaceDataroom
            datarooms={connection.data?.all_datarooms || []}
            selectedDataroom={selectedDataRoom}
            setSelectedDataroom={setSelectedDataRoom}
          />
        ),
      },
    ],
    [selectedSubFolder, connection, currentStep, params.folderId]
  );

  return (
    <Stack p={0} gap={1} maxHeight={0.92}>

      {/* Back buttons */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">

        {currentStep === NotarySpaceSteps.LOGIN && !connection.isPending && (
          <Button onClick={() => setCurrentStep(currentStep - 1)} sx={{ position: "absolute", bottom: 20, left: 20 }}>
            Retour
          </Button>
        )}

        {currentStep === 3 && !connection.isPending && (
          <Button onClick={() => navigate(-1)} sx={{ position: "absolute", top: 20, right: 20 }}>
            Retour
          </Button>
        )}

      </Stack>

      {/* Folder title */}
      <Typography fontSize={12} color="secondary.500" fontWeight="bold" sx={{ maxWidth: "75vw" }} noWrap>
        Dossier: {currentFolder?.name}
      </Typography>

      <Stack justifyContent="space-between" height="100%">

        {/* Steps */}
        <VerticalStepper steps={steps} currentStep={currentStep} />

        {/* Sous-dossier step Actions */}
        {currentStep === 0 && (
          <Stack direction="row" spacing={2} justifyContent="center" my={3}>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
              Précédent
            </Button>
            <WarningTooltip title={selectedSubFolder ? "" : "Vous devez sélectionner un sous-dossier"}>
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setCurrentStep(currentStep + 1)}
                  disabled={!selectedSubFolder}
                >
                  Suivant
                </Button>
              </span>
            </WarningTooltip>
          </Stack>
        )}

        {/* Dataroom step Actions */}
        {currentStep === 2 && (
          <Stack direction="row" spacing={2} justifyContent="center" my={3}>
            <Button variant="contained" color="primary" onClick={() => setCurrentStep(currentStep - 1)}>
              Précédent
            </Button>
            <WarningTooltip title={!selectedDataRoom ? "Vous devez sélectionner une dataroom" : ""}>
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    associateDataroom.mutate({
                      folderId: params.folderId || "",
                      connectionId: connection.data?.connection_id || "",
                      dataRoomId: selectedDataRoom || "",
                    });
                    setCurrentStep(currentStep + 1);
                  }}
                  disabled={!selectedDataRoom}
                >
                  Valider
                </Button>
              </span>
            </WarningTooltip>
          </Stack>
        )}

      </Stack>
    </Stack>
  );
}
