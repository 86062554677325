import StyledTextField from "@/taskpane/components/styled-text-field/StyledTextField";
import { PropertyNode, PropertyType } from "@/taskpane/types/filiation";
import FiliationMultiselect from "@/taskpane/modules/folder/filiation/FiliationMultiselect";
import FiliationTable from "@/taskpane/modules/folder/filiation/FiliationTable";
import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface FiliationListProps {
  title: string;
  indicator: string;
  noDataMessage: string;
  color: "success" | "warning" | "primary";
  data: PropertyNode[];
  type: PropertyType;
  selectedProperties: number[];
  onSelectProperty: (ids: number[], checked: boolean) => void;
}

export default function FiliationList({
  title,
  indicator,
  color,
  noDataMessage,
  data,
  type,
  onSelectProperty,
  selectedProperties,
}: FiliationListProps) {
  const [search, setSearch] = useState("");
  const [selectedParcelles, setSelectedParcelles] = useState<number[]>([]);
  const [selectedVolumes, setSelectedVolumes] = useState<number[]>([]);

  const theme = useTheme()

  const allParcelles = data.map((d) => d.parents.filter((p) => p.type === "cadastre")).flat();
  const parcelles = Array.from(new Set(allParcelles.map((p) => p.id))).map(
    (id) => allParcelles.find((p) => p.id === id)!
  );
  const allVolumes = data.map((d) => d.parents.filter((p) => p.type === "volume")).flat();
  const volumes = Array.from(new Set(allVolumes.map((v) => v.id))).map((id) => allVolumes.find((v) => v.id === id)!);

  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Stack height={isXs ? "70vw" : "25vh"}>
      <Box>
        <Typography fontSize={13} color={`${color}.600`} fontWeight={600}>
          {title}
        </Typography>
        <Typography fontSize={10} color={`${color}.600`}>
          {indicator}
        </Typography>
      </Box>
      <Card sx={{ height: 1, pt: 0.8 }}>
        <Stack spacing={1} direction="row">
          <Box flexGrow={1} sx={type !== "cadastre" ? { maxWidth: "50%" } : undefined}>
            <StyledTextField
              label="Recherche"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: search && (
                  <MdClose size={16} color="grey" style={{ cursor: "pointer" }} onClick={() => setSearch("")} />
                ),
              }}
            />
          </Box>
          {type === "lot" && (
            <Box flexGrow={2}>
              <FiliationMultiselect
                data={volumes}
                selectedElements={selectedVolumes}
                setSelectedElements={setSelectedVolumes}
                color="warning"
                title="Volumes"
              />
            </Box>
          )}
          {(type === "volume" || type === "lot") && (
            <Box flexGrow={2}>
              <FiliationMultiselect
                data={parcelles}
                selectedElements={selectedParcelles}
                setSelectedElements={setSelectedParcelles}
                color="success"
                title="Parcelles"
              />
            </Box>
          )}
        </Stack>
        <FiliationTable
          data={data}
          type={type}
          onSelectProperty={onSelectProperty}
          selectedProperties={selectedProperties}
          noDataMessage={noDataMessage}
          filters={{
            search,
            parcelleIds: selectedParcelles,
            volumeIds: selectedVolumes,
          }}
        />
      </Card>
    </Stack>
  );
}
