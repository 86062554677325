import React, { useMemo, useState } from "react";

import { MdClose } from "react-icons/md";
import { useWindowSize } from "@uidotdev/usehooks";
import { Box, Card, Stack, Typography } from "@mui/material";
import FiliationTable from "@/taskpane/modules/folder/filiation/FiliationTable";
import StyledTextField from "@/taskpane/components/styled-text-field/StyledTextField";
import FiliationMultiselect from "@/taskpane/modules/folder/filiation/FiliationMultiselect";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import styles from "./FiliationList.styles";

import type { FiliationListProps } from "./FiliationList.types";

export default function FiliationList({
  blockUi,
  colNum,
  color,
  data,
  indicator,
  loading,
  noDataMessage,
  onSelectProperty,
  selectedProperties,
  title,
  type,
  handleChange,
  expanded,
}: FiliationListProps) {
  const [search, setSearch] = useState("");
  const [selectedParcelles, setSelectedParcelles] = useState<number[]>([]);
  const [selectedVolumes, setSelectedVolumes] = useState<number[]>([]);

  const { height } = useWindowSize();
  const isSmallScreen = useMemo(() => height ? height < 800 : false, [height]);

  const allParcelles = data.map((d) => d.parents.filter((p) => p.type === "cadastre")).flat();

  const parcelles = Array.from(new Set(allParcelles.map((p) => p.id))).map((id) => allParcelles.find((p) => p.id === id)!)?.sort((a, b) => a.value.localeCompare(b.value));

  const allVolumes = data.map((d) => d.parents.filter((p) => p.type === "volume")).flat();

  const volumes = Array.from(new Set(allVolumes.map((v) => v.id))).map((id) => allVolumes.find((v) => v.id === id)!);


  /**
   * @description Compute the height of filiation page content based on the height of the window.
   *
   * This was necessary as the height of content may vary a lot because of multiple client screen size and resolutions.
   *
   * @returns {string} The height of the content in viewer height unit.
   */
  const computeHeight = useMemo(() => {
    if (height === null) {
      return "20vh";
    }
    if (height > 1800) {
      return "28vh";
    } else if (height > 1600) {
      return "27vh";
    } else if (height > 1400) {
      return "26vh";
    } else if (height > 1200) {
      return "25vh";
    } else if (height > 1000) {
      return "24vh";
    } else if (height > 900) {
      return "23vh";
    } else if (height > 700) {
      return "24vh";
    } else if (height > 600) {
      return "40vh";
    } else if (height > 580) {
      return "30vh";
    } else if (height > 500) {
      return "29vh";
    } else if (height > 450) {
      return "20vh";
    } else if (height > 400) {
      return "20vh";
    } else {
      return "20vh";
    }
  }, [height]);

  if (!isSmallScreen) {
    return (
      <Stack height={computeHeight} flexGrow={1} spacing={1}>

        <Box>
          <Typography fontSize={13} color={`${color}.600`} fontWeight={600} sx={{ mt: 0 }}>
            {title}
          </Typography>
          <Typography fontSize={10} color={`${color}.600`}>
            {indicator}
          </Typography>
        </Box>

        <Card sx={styles.card}>

          {/* Tables actions */}
          <Stack spacing={1} direction="row">

            {/* Search bar */}
            <Box flexGrow={1} sx={type !== "cadastre" ? { maxWidth: "50%" } : undefined}>
              <StyledTextField
                disabled={!!blockUi}
                label="Recherche"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: search && (
                    <MdClose size={16} color="grey" style={styles.closeIcon} onClick={() => setSearch("")} />
                  ),
                }}
              />
            </Box>

            {/* Lot filter */}
            {type === "lot" && (
              <Box flexGrow={2}>
                <FiliationMultiselect
                  blockUi={blockUi}
                  data={volumes}
                  selectedElements={selectedVolumes}
                  setSelectedElements={setSelectedVolumes}
                  color="warning"
                  title="Volumes"
                />
              </Box>
            )}

            {/* Lots/Volumes filter */}
            {(type === "volume" || type === "lot") && (
              <Box flexGrow={2}>
                <FiliationMultiselect
                  blockUi={blockUi}
                  data={parcelles}
                  selectedElements={selectedParcelles}
                  setSelectedElements={setSelectedParcelles}
                  color="success"
                  title="Parcelles"
                />
              </Box>
            )}

          </Stack>

          {/* Filiation Table */}
          <FiliationTable
            blockUi={blockUi}
            data={data}
            type={type}
            onSelectProperty={onSelectProperty}
            selectedProperties={selectedProperties}
            noDataMessage={noDataMessage}
            loading={loading}
            colNum={colNum}
            filters={{
              search,
              parcelleIds: selectedParcelles,
              volumeIds: selectedVolumes,
            }}
          />

        </Card>
      </Stack>
    );
  }

  return (
      <Accordion expanded={expanded === type} onChange={handleChange(type)} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id={`${type}-header`}
          sx={{ px: "4px", minHeight: "36px" }}
        >
          <Box>
            <Typography fontSize={13} color={`${color}.600`} fontWeight={600} sx={{ mt: 0 }}>
              {title} ({data?.length ?? 0})
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ px: "4px", pb: "4px", pt: 0 }}>
          <Stack height={computeHeight} spacing={0}>
            <Card sx={styles.card}>
              {/* Tables actions */}
              <Stack spacing={1} direction="row">

                {/* Search bar */}
                <Box flexGrow={1} sx={type !== "cadastre" ? { maxWidth: "50%" } : undefined}>
                  <StyledTextField
                    disabled={!!blockUi}
                    label="Recherche"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    fullWidth
                    InputProps={{
                      endAdornment: search && (
                        <MdClose size={16} color="grey" style={styles.closeIcon} onClick={() => setSearch("")} />
                      ),
                    }}
                  />
                </Box>

                {/* Lot filter */}
                {type === "lot" && (
                  <Box flexGrow={2}>
                    <FiliationMultiselect
                      blockUi={blockUi}
                      data={volumes}
                      selectedElements={selectedVolumes}
                      setSelectedElements={setSelectedVolumes}
                      color="warning"
                      title="Volumes"
                    />
                  </Box>
                )}

                {/* Lots/Volumes filter */}
                {(type === "volume" || type === "lot") && (
                  <Box flexGrow={2}>
                    <FiliationMultiselect
                      blockUi={blockUi}
                      data={parcelles}
                      selectedElements={selectedParcelles}
                      setSelectedElements={setSelectedParcelles}
                      color="success"
                      title="Parcelles"
                    />
                  </Box>
                )}

              </Stack>

              {/* Filiation Table */}
              <FiliationTable
                blockUi={blockUi}
                data={data}
                type={type}
                onSelectProperty={onSelectProperty}
                selectedProperties={selectedProperties}
                noDataMessage={noDataMessage}
                loading={loading}
                colNum={colNum}
                filters={{
                  search,
                  parcelleIds: selectedParcelles,
                  volumeIds: selectedVolumes,
                }}
              />

            </Card>
          </Stack>
        </AccordionDetails>
      </Accordion>
  );
}
