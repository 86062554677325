/**
 * Login Component
 *
 * This component handles user authentication, including email input and OTP verification.
 *
 * TODO: Implement rate limiting for OTP attempts
 * TODO: Implement proper logout mechanism to clear stored tokens
 */

import React, { useContext, useEffect, useState } from "react";

import * as z from "zod";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import { AuthContext } from "@/taskpane/contexts/auth/AuthContext";
import InputCode from "@/taskpane/components/input-code/InputCode";
import LoadingButton from "@/taskpane/components/button/LoadingButton";
import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import { initiateAuth, respondToAuthChallenge } from "@/taskpane/services/auth";
import { enqueueConfirmSnackbar } from "@/taskpane/components/snackbar-provider/SnackbarProvider";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";

const MAX_OTP_ATTEMPTS = 3;
const OTP_COOLDOWN_PERIOD = 300000; // 5 minutes in milliseconds

/* global Office */

// Get user email using Office API
// const getUserEmail = async () => {
//   return new Promise<string>((resolve, reject) => {
//     if (Office?.context?.mailbox?.userProfile?.emailAddress) {
//       resolve(Office.context.mailbox.userProfile.emailAddress);
//     } else {
//       reject(new Error("Unable to fetch user email"));
//     }
//   });
// };

const checkForOTPEmail = async (): Promise<string | null> => {
  const senderEmail = "contact@bylaw.fr";
  const otpSubject = "Your Login Code";
  // const bodyText = "Your login code is ";

  // enqueueSnackbar("Checking for OTP email...", { variant: "info" });
  return new Promise((resolve) => {
    if (!Office?.context?.mailbox?.item) {
      // enqueueSnackbar("No active email item found", { variant: "warning" });
      resolve(null);
      return;
    }

    const { item } = Office.context.mailbox;

    const emailSubject = item.subject;
    const emailFrom = item.from.emailAddress;
    // const emailBody = item.body.getAsync(Office.CoercionType.Text, function (result) {
    //   if (result.status === Office.AsyncResultStatus.Succeeded) {
    //     return result.value;
    //   } else {
    //     return null;
    //   }
    // });

    let { body } = Office.context.mailbox.item;
    body.getAsync(Office.CoercionType.Text, function (asyncResult) {
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
        // do something with the error
      } else {
        // Parse email body for OTP code, if found and sender and subject match, return OTP code
        const bodyText = asyncResult.value;
        const otpIndex = bodyText.indexOf("Your login code is ");
        if (otpIndex !== -1 && emailFrom === senderEmail && emailSubject === otpSubject) {
          const otpCode = bodyText.slice(otpIndex + 19, otpIndex + 23);
          // Check if OTP code is valid (4 digits)
          if (otpCode.length !== 4 || isNaN(Number(otpCode))) {
            resolve(null);
            // enqueueSnackbar("Invalid OTP code found in email body", { variant: "warning" });
            return;
          }
          resolve(otpCode);
          // enqueueSnackbar(`OTP code found: ${otpCode}`, { variant: "success" });
        } else {
          resolve(null);
          // // Print a detailed error message in enqueueSnackbar
          // if (otpIndex === -1) {
          //   enqueueSnackbar("No OTP code found in email body", { variant: "warning" });
          // }
          // if (emailFrom !== senderEmail) {
          //   enqueueSnackbar("Email sender does not match", { variant: "warning" });
          // }
          // if (emailSubject !== otpSubject) {
          //   enqueueSnackbar("Email subject does not match", { variant: "warning" });
          // }
          // if (otpIndex !== -1 && emailFrom === senderEmail && emailSubject === otpSubject) {
          //   enqueueSnackbar("OTP code found but could not be extracted", { variant: "warning" });
          // }
        }
      }
    });
  });
};

function getOtpCode(element: any) {
  // Select all the elements with the class 'otp-td'
  const otpElements = element.querySelectorAll(".otp-td");

  // Initialize an empty string to hold the OTP code
  let otpCode = "";

  // Check if otpElements is not null or empty
  if (otpElements && otpElements.length > 0) {
    // Loop through each element and concatenate its text content
    otpElements.forEach((el: { textContent: string }) => {
      if (el && el.textContent) {
        // Check if element and its textContent exist
        otpCode += el.textContent;
      }
    });
  } else {
    console.error("OTP elements not found.");
    return null; // Return null if no elements are found
  }

  // Return the final OTP code
  return otpCode || null; // Return null if OTP code remains empty
}

/**
 * Zod schema for validating login form input.
 *
 * This schema ensures that the email field is:
 * - A string
 * - A valid email address
 * - Not empty
 *
 * @constant
 * @type {z.ZodObject}
 * @property {z.ZodString} email - The email field, must be a valid email address and is required.
 * @throws Will throw validation errors if the input does not meet the specified criteria.
 */
const loginSchema = z.object({
  email: z.string().email("Addresse mail invalide").min(1, "L'adresse mail est requise"),
});

type FormData = z.infer<typeof loginSchema>;

/**
 * Login component for handling user authentication.
 *
 * This component manages the login process, including email submission and OTP verification.
 * It uses React hooks for state management and various helper functions for handling authentication.
 *
 * @component
 * @returns {JSX.Element} The rendered login component.
 */
export function LoginPage() {
  const [username, setUsername] = useState(() => localStorage.getItem("username") || "");
  const [isFirstLogin, setIsFirstLogin] = useState(() => localStorage.getItem("bylawFirstConnectionDone") || null);
  const [session, setSession] = useState<string | undefined>(
    () => localStorage.getItem("session") || null || undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [otpAttempts, setOtpAttempts] = useState(0);
  const [lastOtpAttemptTimestamp, setLastOtpAttemptTimestamp] = useState<number>();
  const [otp, setOtp] = useState("");
  const [defaultOtp, setDefaultOtp] = useState("");
  const { setTokens: login, logout } = useContext(AuthContext);
  const { changeCounter, currentMailbox } = useOfficeContext();
  const loginFormDefaults: FormData = {
    email: currentMailbox?.userProfile?.emailAddress ?? "",
  };
  const {
    handleSubmit, // function to handle form submission
    formState: { errors }, // Destructure errors
    control,
  } = useForm<FormData>({
    // Call useForm hook with generic type FormData
    resolver: zodResolver(loginSchema), // Pass Zod schema to resolver
    defaultValues: loginFormDefaults, // specify default values for form inputs
  });
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handles the submission of the username (email) form for initiating authentication.
   *
   * @async
   * @function handleOnUserNameSubmit
   * @param {FormData} data - The form data containing the email.
   */
  const handleOnUserNameSubmit = async (data: FormData) => {
    const resultEmail = data.email.toLowerCase();

    setIsLoading(true);

    try {
      const { result, statusCode } = await initiateAuth(resultEmail);

      if (statusCode === 200) {
        setSession(result.Session);
        localStorage.setItem("session", result.Session);
        localStorage.setItem("username", resultEmail);
        setOtpAttempts(0);
        setUsername(resultEmail);
        setLastOtpAttemptTimestamp(undefined);
      } else if (statusCode === 400) {
        enqueueSnackbar("L'adresse mail est invalide", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles the submission of the OTP code for verifying 2FA authentication.
   *
   * @async
   * @function handleConfirmOTP
   * @param {string} otpValue - The OTP code entered by the user.
   */
  const handleConfirmOTP = async (otpValue: string) => {
    const currentTime = Date.now();
    if (otpAttempts >= MAX_OTP_ATTEMPTS && currentTime - lastOtpAttemptTimestamp! < OTP_COOLDOWN_PERIOD) {
      const remainingCooldown = Math.ceil((OTP_COOLDOWN_PERIOD - (currentTime - lastOtpAttemptTimestamp!)) / 60000);
      enqueueConfirmSnackbar(
        `Trop de tentatives. Veuillez réessayer dans ${remainingCooldown} minutes.`,
        () => {},
        "OK",
        {
          persist: true,
          maxHideDuration: remainingCooldown,
          variant: "error",
          action: (snackbarId: SnackbarKey | undefined) => (
            <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
          ),
        }
      );
      return;
    }

    setIsLoading(true);
    try {
      const { result, statusCode } = await respondToAuthChallenge(username, otpValue, session);
      if (statusCode === 200) {
        login(result.AuthenticationResult);
        navigate("/folders");
      } else if (statusCode === 400) {
        setOtpAttempts((prevAttempts) => prevAttempts + 1);
        setLastOtpAttemptTimestamp(Date.now());
        enqueueSnackbar("Le code n'est pas valide", {
          variant: "error",
          action: (snackbarId: SnackbarKey | undefined) => (
            <>
              <Button
                color="error"
                variant="text"
                size="small"
                onClick={() => {
                  handleOnUserNameSubmit({ email: username });
                  setOtp("");
                }}
                sx={{ marginRight: 5 }}
              >
                Nouveau code
              </Button>
              <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
            </>
          ),
        });
      }
    } catch (error) {
      console.error("Challenge response failed:", error);
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handles the search for an OTP code in the email body.
   * This function will help to detect OTP code in when mail item changes and
   * automaticaly fill the OTP input. This is a TODO feature.
   * @function handleSearchForOTP
   * @param {string} mailContext - The email context containing the email body and other usefull infos
   * that can help to detect OTP code in when mail item changes.
   */
  const handleSearchForOTP = (mailContext: any) => {
    return new Promise((resolve) => {
      if (!mailContext?.item) {
        resolve(null);
        return;
      }

      let { body } = mailContext.item;

      body.getAsync(Office.CoercionType.Html, function (asyncResult: any) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          const bodyHtml = asyncResult.value;

          const element = document.createElement("div");
          element.innerHTML = bodyHtml;

          const otpElement = getOtpCode(element);

          if (otpElement) {
            setDefaultOtp(otpElement);
          } else {
            // console.log('No OTP code found.');
          }
        }
      });
    });
  };

  useEffect(() => {
      if (isFirstLogin != "true") {
        navigate('/first-login')
      }
  }, [isFirstLogin]);

  useEffect(() => {
    if (location?.pathname === "login") {
      handleSearchForOTP(currentMailbox);
    }
  }, [changeCounter, currentMailbox]);

  useEffect(() => {
    // Check for saved session data
    const savedUsername = localStorage.getItem("username");
    const savedSession = localStorage.getItem("session");
    if (savedUsername && savedSession) {
      setUsername(savedUsername);
      setSession(savedSession);
    }
    // // Fetch user email using Office API
    // const fetchUserEmail = async () => {
    //   try {
    //     const email = await getUserEmail();
    //     if (email && !savedUsername) {
    //       setUsername(email);
    //       localStorage.setItem("username", email);
    //     }
    //   } catch (error) {
    //     console.error("Failed to fetch user email:", error);
    //   }
    // };

    // fetchUserEmail();
  }, []);

  useEffect(() => {
    if (session) {
      const checkOTPEmail = async () => {
        const otpCode = await checkForOTPEmail();
        if (otpCode) {
          setOtp(otpCode);
        }
      };
      checkOTPEmail();
    }
  }, [session]);


  return (
    <Box className="container">
      <Card sx={{ margin: "auto", width: "80%" }}>
        <Stack spacing={2} p={2} alignItems="center">
          {!session && (
            <form
              onSubmit={handleSubmit(handleOnUserNameSubmit)}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" gutterBottom color="black" fontWeight={600} textAlign={"center"}>
                Connexion à votre compte
              </Typography>

              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email}
                    fullWidth
                    helperText={errors.email?.message}
                    label="Adresse email"
                    onChange={(e) => field.onChange(e.target.value)}
                    size="small"
                    type="text"
                    variant="outlined"
                    margin="normal"
                  />
                )}
                control={control}
                defaultValue={loginFormDefaults.email}
                name="email"
              />

              <WarningTooltip title={errors.email?.message ? "Veuillez entrer une adresse email" : ""}>
                <span>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isLoading || !!errors.email?.message}
                    className="login-button"
                    loading={isLoading}
                    size="medium"
                    style={{ paddingLeft: 12, paddingRight: 12, height: 36 }}
                  >
                    Se connecter
                  </LoadingButton>
                </span>
              </WarningTooltip>
            </form>
          )}

          {session && (
            <>
              <Typography
                variant="subtitle1"
                gutterBottom
                color="black"
                fontWeight={600}
                textAlign="center"
                lineHeight={1.4}
              >
                Vous allez recevoir un code de sécurité par mail
              </Typography>

              <InputCode length={4} onCodeChange={(code) => setOtp(code)} />
              <WarningTooltip title={otp.length !== 4 ? "Veuillez entrer le code de sécurité reçu par mail" : ""}>
                <span>
                  <LoadingButton disabled={otp.length !== 4} loading={isLoading} onClick={() => handleConfirmOTP(otp)}>
                    Valider
                  </LoadingButton>
                </span>
              </WarningTooltip>
            </>
          )}
        </Stack>
      </Card>
    </Box>
  );
}
