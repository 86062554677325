import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { CSSProperties } from 'react';

import type { PaletteColor } from "./FoldersList.types";

export default {
    listItemButton: (theme: Theme, alpha: any) => ({
        flexDirection: "column",
        height: 50,
        paddingLeft: "0",
        paddingRight: "4px",
        "&.Mui-selected, &.Mui-selected:hover": {
            bgcolor: alpha(theme.palette.secondary.main, 0.2),
        },
    }),
    listStyles: {
        backgroundColor: "white",
        border: "solid 1px",
        borderColor: "rgba(135, 135, 135, 0.34)",
        borderRadius: "4px",
        padding: 0,
        overflowX: "hidden",
        mt: 0,
    } as CSSProperties,
    iconButton: (color: PaletteColor, selected: boolean, theme: Theme, alpha: any): SxProps<Theme> => (
        selected
            ? {
                "&:hover": {
                    borderRadius: "50%",
                    backgroundColor: `${alpha(theme.palette?.[`${color}`].main, 0.3)}!important`,
                },
            }
            : {}),
    syncIcon: (color: any) => ({
        "&.MuiSvgIcon-root": {
            fontSize: "20px",
            fill: `${color}`,
            rotate: "90deg",
        },
    }),
    dossierNameAndCreationDateContainer: { marginLeft: 0, paddingLeft: 0, overflow: "hidden", textOverflow: "ellipsis" },
    dossierName: { marginLeft: 0, paddingLeft: 0 },
    creationDate: { opacity: 0.5 },
    controlCenterIcon: (theme: Theme) => ({
        color: theme.palette.text.secondary,
        borderRadius: 1,
        padding: "1px",
        "&:hover": {
            borderRadius: 1,
            padding: "1px",
            color: theme.palette.primary.main,
        },
    }),
    blockedMessageContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(4px)",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        p: 2,
    },
    blockedReasonText: {
        border: "2px solid #1976d2",
        borderRadius: 3,
        p: 3,
        bgcolor: "#bbdefb",
        maxWidth: "80%",
        boxShadow: 2,
    },
    bottomNavButtons: (width: number) => ({ height: 48, fontSize: width ? width < 400 ? "3.2vw" : 12 : 12 }),
}