import { Property, ValidatedProperty } from "@/taskpane/types/filiation";
import { apiClient } from "@/taskpane/utils/client";
import { handleError } from "@/taskpane/utils/errors";

interface ErrorWithStatus extends Error {
  status?: number;
}

/**
 * Retrieves the filiation data for a given folder and subfolder.
 *
 * @param {Object} options - The options for retrieving the filiation data.
 * @param {string} options.folderId - The ID of the folder.
 * @param {string} options.flowId - The ID of the flow.
 * @returns {Promise<Property[]>} - A promise that resolves to an array of filiation properties.
 */
export async function getFiliation({
  folderId,
  flowId,
}: {
  folderId: string;
  flowId: string;
}): Promise<Property[] | null> {
  try {
    const response = await apiClient.get(`/dossier/${folderId}/filiation/${flowId}`);
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /dossier/${folderId}/filiation/${flowId} failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Dossier ou filiation introuvable`);
  }
}

/**
 * Sets the filiation for a given folder and subfolder.
 * Error handling is done locally in the component calling this function.
 *
 * @param {Object} options - The options for setting the filiation.
 * @param {string} options.folderId - The ID of the folder.
 * @param {string} options.subFolderId - The ID of the subfolder.
 * @param {ValidatedProperty[]} options.properties - The properties to set for the filiation.
 * @returns {Promise<void>} - A promise that resolves when the filiation is set.
 */
export async function setFiliation({
  folderId,
  subFolderId,
  properties,
}: {
  folderId: string;
  subFolderId: string;
  properties: ValidatedProperty[];
}) {
  return apiClient.post(`/dossier/${folderId}/filiation/${subFolderId}`, properties);
}


/**
 * Locks a filiation to prevent further modifications.
 * Error handling is done locally in the component calling this function.
 *
 * @param {Object} options - The options for locking the filiation.
 * @param {string} options.folderId - The ID of the folder.
 * @param {string} options.subFolderId - The ID of the subfolder.
 * @returns {Promise<void>} - A promise that resolves when the filiation is locked.
 */
export async function lockFiliation({
  folderId,
  flowId,
  lockValue,
}: {
  folderId: string;
  flowId: string;
  lockValue: boolean;
}) {
  const body = {
    'lock_status': lockValue
  }
  return apiClient.put(`/dossier/${folderId}/filiation/${flowId}/lock_filiation`, body);
}


/**
 * Retrieves the filiation data for a given folder and subfolder.
 *
 * @param {Object} options - The options for retrieving the filiation data.
 * @param {string} options.clientId - The ID of the client.
 * @param {string} options.dossierId - The ID of the folder.
 * @param {string} options.sousDossierId - The ID of the subfolder.
 * @param {string} options.sousDossierId - The ID of the flow link to filiation.
 * @returns {Promise<Property[]>} - A promise that resolves to an array of filiation properties.
 */
export async function getPublicFiliation({
  clientId,
  dossierId,
  sousDossierId,
  flowId
}: {
  clientId: string;
  dossierId: string;
  sousDossierId: string;
  flowId: string;
}): Promise<Property[] | null> {
  try {
    const response = await apiClient.get(`/public/filiation/${clientId}/${dossierId}/${sousDossierId}/${flowId}`);
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /public/filiation/${clientId}/${dossierId}/${sousDossierId}/${flowId} failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Aucun dossier ou flux de travail n'a été trouvé`);
  }
}