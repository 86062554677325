export function useChart(options: any) {
    const defaultOptions = {
      colors: ['#2065D1', '#3BE5CC'],
      chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        foreColor: '#919EAB',
        fontFamily: "'Public Sans', sans-serif",
      },
      states: {
        hover: {
          filter: { type: 'lighten', value: 0.04 },
        },
        active: {
          filter: { type: 'darken', value: 0.88 },
        },
      },
      fill: {
        opacity: 1,
        gradient: {
          type: 'vertical',
          shadeIntensity: 0,
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      dataLabels: { enabled: false },
      stroke: {
        width: 3,
        curve: 'smooth',
        lineCap: 'round',
      },
      grid: {
        strokeDashArray: 3,
        borderColor: '#919EAB',
        xaxis: {
          lines: { show: false },
        },
      },
      xaxis: {
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      markers: {
        size: 0,
        strokeColors: '#fff',
      },
      tooltip: {
        x: { show: false },
      },
      legend: {
        show: true,
        fontSize: '13px',
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          radius: 12,
        },
        fontWeight: 500,
        itemMargin: { horizontal: 12 },
        labels: {
          colors: '#919EAB',
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: '28%',
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'last',
        },
      },
    };
  
    return {
      ...defaultOptions,
      ...options,
    };
  }