import { DESKTOP_MIN_WIDTH } from "@/taskpane/styles/responsive";

export default {
    backButton: { position: "absolute", top: 20, right: 20 },
    selectContainer: { 
        width: "100%", 
        height: "100%", 
        minHeight: 0,
        overflowY: "auto",
        paddingBottom: "0px",
        [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
            maxWidth: "800px",
            margin: "0 auto",
            "& .MuiTypography-root": {
                fontSize: 12
            }
        }
    },
    selectStack: { mb: 2, mt: 1 },
    autoImportSwitch: { ml: 0, mr: 1, mt: 1, mb: 1, color: "black", fontSize: 12 },
    autoImportSwitchLabel: { color: "black", fontSize: 12, "& .MuiTypography-root": { fontSize: 12 }, mb: 4 },
    controlCenterActions: { 
        width: 1, 
        justifyContent: "space-between", 
        display: "flex",
        gap: 2,
        marginBottom: 3
    },
    controlCenterActionsButton: (w: number) => ({ 
        height: 48,
        fontSize: w && w < 400 ? "2.8vw" : 12, 
        whiteSpace: "nowrap",
        flex: 1,
        backgroundColor: "#2F4FA5",
        color: "white",
        "&:hover": {
            backgroundColor: "#1E3A8A"
        }
    }),
    widgetContainer: { width: 1, justifyContent: "space-between", display: "flex" },
}