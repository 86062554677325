import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Logo from "@/taskpane/components/logo/Logo";
import { Stack } from "@mui/material";
import { replace, useNavigate } from "react-router-dom";

const steps = [
  {
    header: "Présentation",
    label: "Bienvenue sur l'add-in Bylaw !",
    description: `Pilotez votre activité et gérez vos documents n'aura jamais été aussi simple !`,
  },
  {
    header: "Optimisez votre gestion de documents",
    label: "Associez vos documents en quelques clics depuis votre boîte mail Outlook !",
    description: `L'add-in Bylaw vous permet d'importer vos documents depuis 3 cannaux différents: détection automatique et importation de vos pièce-jointes, glissez-déposez directement depuis votre poste de travail et importation depuis votre espace-notarial distant !`,
  },
  {
    header: "L'intelligence artificielle â votre service",
    label: "L'IA Bylaw réalise une analyse approfondie de l'ensemble de vos documents",
    description: `Grâce à l'utilisation de l'intelligence artificielle, Bylaw traite une quantité illimitée d'informations structurées et non structurées. De ces analyses, l'add-in Bylaw vous permet de retracer la filiation cadastrale avec son  historique complète (parcelle, volume, lots) et bien d'autres encore...`,
  },
];

export function LoginFRE() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;
  const nav = useNavigate()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinishFRE = () =>{
    localStorage.setItem('bylawFirstConnectionDone', 'true')
    nav('/login')
  }

  return (
    <Box sx={{ width: "100%", height: "100vh", flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "64px",
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography variant="subtitle1" sx={{ color: "#1B269E", fontWeight: 600 }}>
          {steps[activeStep].header}
        </Typography>
      </Paper>
      <Stack sx={{ width: "100%", height: "calc(100vh - 128px)" }}>
        <Box sx={{ width: "100%", p: 2, height: 0.3, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Logo width="100%" />
        </Box>
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 0.7,
            p: 2,
            bgcolor: "background.default",
          }}
        >
          <Stack justifyContent="center" spacing={4} sx={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
            <Typography variant="h5" sx={{ color: "#1B269E", fontWeight: 500 }}>
              {steps[activeStep].label}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {steps[activeStep].description}
            </Typography>
            {activeStep === 2 ? (
              <Button size="medium" onClick={handleFinishFRE} disabled={activeStep !== 2} sx={{ bgColor: "#1B269E" }}>
                Commencez
              </Button>
            ) : null}
          </Stack>
        </Paper>
      </Stack>
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} sx={{ bgColor: "#1B269E" }}>
            Suivant
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0} sx={{ bgColor: "#1B269E" }}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Precedent
          </Button>
        }
        sx={{ height: "64px" }}
      />
    </Box>
  );
}
