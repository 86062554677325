import React, { useCallback, useEffect, useMemo, useState } from "react";

import FiliationList from "@/taskpane/modules/folder/filiation/FiliationList";
import StyledSelect from "@/taskpane/components/styled-select/StyledSelect";
import { WarningTooltip } from "@/taskpane/utils/tooltips";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import useFiliationData from "@/taskpane/hooks/filiation";
import { useGetFiliationQuery, useSetFiliationMutation, useLockFiliationMutation } from "@/taskpane/services/filiation.hook";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueConfirmSnackbar } from "@/taskpane/components/snackbar-provider/SnackbarProvider";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { useWindowSize } from "@uidotdev/usehooks";

interface FiliationProps {}

interface FilteredData {
  filteredLots: any[];
  filteredParcelles: any[];
  filteredVolumes: any[];
}

/**
 * Main component to render the filiation process.
 * @returns {JSX.Element} The rendered component.
 */
export default function Filiation(): JSX.Element {
  const [blockUi, setBlockUi] = useState<boolean>(false);
  const [selectedCommune, setSelectedCommune] = useState<number>(-1);
  const [selectedProperties, setSelectedProperties] = useState<number[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [previousModalResponse, setPreviousModalResponse] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<string | false>('cadastre');
  const { height } = useWindowSize();

  const navigate = useNavigate();

  const { folderId, subFolderId } = useParams();

  const queryClient = useQueryClient();

  const { communes, parcellesWithNoChildren, lots, volumesWithNoChildren, getValidatedProperties, isLoading } =
    useFiliationData(folderId || "", subFolderId || "");

  const { data: filiationData } = useGetFiliationQuery(folderId || "", subFolderId || "");

  const theme = useTheme();

  const { mutate: lockFiliationMutation, isPending: lockFiliationPending } = useLockFiliationMutation({
    onSuccess: () => {
      enqueueConfirmSnackbar(
        previousModalResponse === "Oui"
          ? "Vous ne recevrez plus de mails pour ce sous-dossier."
          : previousModalResponse === "Non"
            ? "Vous recevrez un mail pour chaque nouveau bien identifié."
            : "Aucune action n'a été effectuée.",
        () => {
          setBlockUi(false);
          navigate("/folders");
        },
        "Compris",
        {
          persist: true,
        }
      );
      setPreviousModalResponse(null);
    },
    onError: (error) => {
      console.error(error);
      // Close all potential already as all snackbar are persistent and displayed only one at a time. So we give priority to the error one if user hasn't closed the previous one.
      closeSnackbar();
      // Display the error message from the request if any or the following message if no error message is provided.
      enqueueSnackbar(
        "Une erreur est survenue lors du verrouillage",
        {
          variant: "error",
          persist: true,
          action: (snackbarId: SnackbarKey | undefined) => {
            setBlockUi(false);
            return <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />;
          },
        }
      );
    },
  });

  const {
    mutate: setFiliation,
    isPending: setFiliationPending,
    isSuccess: setFiliationSuccess,
  } = useSetFiliationMutation({
    onSuccess: () => {
      setIsConfirmModalOpen(true);
      setBlockUi(true);
      queryClient.invalidateQueries({ queryKey: ["getFiliation"] });
    },
    onError: (error) => {
      console.error(error);
      // Close all potential already as all snackbar are persistent and displayed only one at a time. So we give priority to the error one if user hasn't closed the previous one.
      closeSnackbar();
      // Display the error message from the request if any or the following message if no error message is provided.
      enqueueSnackbar(
        "Une erreur est survenue lors de l'enregistrement de la filiation",
        {
          variant: "error",
          persist: true,
          action: (snackbarId: SnackbarKey | undefined) => (
            <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
          ),
        }
      );
    },
  });

  const dossierData = filiationData?.dossier_data;

  /**x
   * @description Pre-rendering filtering of lots, parcelles, and volume fetched data that adapt thier content depending on the selected commune.
   *
   * @memoization
   * @dependency {obj[]} lots - Lots received from the API in the filiation.
   * @dependency {obj[]} parcellesWithNoChildren - Parcelles received from the API in the filiation.
   * @dependency {obj[]} volumesWithNoChildren - volumes received from the API in the filiation.
   * @dependency {number} selectedCommune - The selected commune to filter the data.
   *
   * @returns {obj} filteredData - The filtered data to be displayed in the filiation list.
   */
  const filteredData = useMemo<FilteredData>(() => {
    return {
      filteredLots: lots.filter((lot) => {
        if (selectedCommune >= 0) {
          return lot.parents.some((parent) => parent.parents_ids.includes(selectedCommune));
        }
        return true;
      }),
      filteredParcelles: parcellesWithNoChildren.filter((parcelle: { parents_ids: number[] }) => {
        if (selectedCommune >= 0) {
          return parcelle.parents_ids.includes(selectedCommune);
        }
        return true;
      }),
      filteredVolumes: volumesWithNoChildren.filter((volume: { parents: any[] }) => {
        if (selectedCommune >= 0) {
          return volume.parents.some((parent) => parent.parents_ids.includes(selectedCommune));
        }
        return true;
      }),
    };
  }, [lots, selectedCommune, parcellesWithNoChildren, volumesWithNoChildren]);


  /**
   * @description Callback function to handle the selection of filiation properties.
   *
   * This function is responsible to update the selected filiation properties when the user
   * manually select a lot, volume, or parcelle.
   *
   * @param {number[]} ids - IDs of selected properties
   * @param {boolean} checked - Whether the properties are being selected or deselected
   *
   * @dependency {number[]} selectedProperties - The selected properties to be updated
   * @dependency {function} setSelectedProperties - The function to update the selected properties
   */
  const handleSelectProperty = useCallback(
    (ids: number[], checked: boolean): void => {
      if (checked) {
        setSelectedProperties((prev) => [...prev, ...ids]);
      } else {
        setSelectedProperties((prev) => prev.filter((id) => !ids.includes(id)));
      }
    },
    [selectedProperties, setSelectedProperties]
  );

  /**
   * @description Callback function to handle the closing of the confirmation modal.
   *
   * @param {React.MouseEvent} event - The event that triggered the closing of the modal
   * @param {string} reason - The reason why the modal is being closed
   *
   * @callback
   * @dependency {function} setIsConfirmModalOpen - setState function to update isConfirmModalOpen state
   */
  const handleCloseModal = useCallback(
    (event: React.MouseEvent, reason: string): void => {
      if (reason === "backdropClick") {
        event.stopPropagation();
        event.preventDefault();
      } else {
        setIsConfirmModalOpen(false);
      }
    },
    [setIsConfirmModalOpen]
  );

  const handleChange = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  /**
   * @description Side effect that updates the selected properties of each lots when the user manually select a lot.
   *
   * This effect is responsible to update the checkbox in render to representing a user choice and
   * adding it to already chosen lots.
   *
   * @effect
   * @dependency {obj[]} lots - Filiation lots objects
   * @dependency {function} setSelectedProperties - setState function to update the selected properties
   */
  useEffect(() => {
    if (lots && lots?.length && lots.length > 0) {
      const chosenLots = lots
        .map((chosenLot) => {
          if (chosenLot.chosen === true) {
            return chosenLot.id;
          } else {
            return null;
          }
        })
        .filter((chosenLot) => chosenLot !== null);
      setSelectedProperties((prev) => [...prev, ...chosenLots]);
    }
  }, [lots, setSelectedProperties]);

  /**
   * @description Side effect that track changes of the parcelle received in the filiation from the API.
   *
   * This effect is responsible to update the selected parcelles with parcelles from the filiation that can have the property chosen to true.
   *
   * This allow the user to see what parcelles are already chose before making the filiation choice.
   *
   * @dependency {obj[]} parcellesWithNoChildren - Parcelles received from the API in the filiation.
   * @dependency {function} setSelectedProperties - setState function to update the selected properties
   */
  useEffect(() => {
    if (parcellesWithNoChildren && parcellesWithNoChildren?.length && parcellesWithNoChildren.length > 0) {
      const chosenParcellesWithNoChildren = parcellesWithNoChildren
        .map((chosenParcelle: any) => {
          if (chosenParcelle.chosen === true) {
            return chosenParcelle.id;
          } else {
            return null;
          }
        })
        .filter((chosenParcelle: any) => chosenParcelle !== null);
      setSelectedProperties((prev) => [...prev, ...chosenParcellesWithNoChildren]);
    }
  }, [parcellesWithNoChildren, setSelectedProperties]);

  /**
   * @description Side effect that track changes of the volumes received in the filiation from the API.
   *
   * This effect is responsible to update the selected volumes with volumes from the filiation that can have the property chosen to true.
   *
   * This allow the user to see what volumes are already chose before making the filiation choice.
   *
   * @dependency {obj[]} volumesWithNoChildren - volumes received from the API in the filiation.
   * @dependency {function} setSelectedProperties - setState function to update the selected properties
   */
  useEffect(() => {
    if (volumesWithNoChildren && volumesWithNoChildren?.length && volumesWithNoChildren.length > 0) {
      const chosenVolumes = volumesWithNoChildren
        .map((chosenVolume: any) => {
          if (chosenVolume.chosen === true) {
            return chosenVolume.id;
          } else {
            return null;
          }
        })
        .filter((chosenVolume: any) => chosenVolume !== null);
      setSelectedProperties((prev) => [...prev, ...chosenVolumes]);
    }
  }, [volumesWithNoChildren, setSelectedProperties]);

  /**
   * @description Side effect responsible to close all snackbars when the component is unmounted.
   *
   * @effect
   */
  useEffect(() => {
    return () => {
      // Close all snackbars when the component is unmounted
      closeSnackbar();
    };
  }, []);

  const filiationLoading = setFiliationPending || lockFiliationPending;

  return (
    <>
      <Box sx={{ width: "100%", height: 1, p: 1 }}>
        <Stack spacing={1} height={1} >

          <Box>
            <Box sx={{ mb: 1 }}>
              {/* Title */}
              <Typography fontSize={12} color="secondary.500" fontWeight={600} sx={{ maxWidth: "75vw" }} noWrap>
                Dossier: {dossierData?.dossier_name}
              </Typography>
              <Typography fontSize={12} color="secondary.500" fontWeight={600} sx={{ maxWidth: "75vw" }} noWrap>
                Sous Dossier: {dossierData?.sous_dossier_name}
              </Typography>
            </Box>

            {/* Subtitle */}
            <Typography fontSize={12} color="primary.500" fontWeight={600} sx={{ my: 0 }}>
              Sélection des biens objets de la transaction:
            </Typography>
          </Box>

          {/* Communes Select */}
          <Box sx={{ my: 0, marginTop: 0 }} flexShrink={0}>
            <StyledSelect
              label="Commune"
              disabled={!!blockUi}
              borderColor={theme.palette.grey[900]}
              value={selectedCommune}
              onChange={(e) => setSelectedCommune(Number(e.target.value))}
            >
              <MenuItem value={-1}>Toutes les communes</MenuItem>
              {communes?.map((c: any) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.value}
                </MenuItem>
              ))}
            </StyledSelect>
          </Box>

          {/* Filiation tables */}
          <Box flexGrow={1} sx={ height && height <= 400 ? { height: "100vh", overflowY: "auto" } : {} }>
            <Grid container spacing={1} columns={1} justifyContent="flex-start" height={expanded !== false && ["cadastre", "volumes", "lots"]?.includes(expanded) ? 1 : "0px"}>
              <Grid item xs={1}>
                <FiliationList
                  blockUi={blockUi}
                  color="success"
                  title="Parcelles entières :"
                  noDataMessage="Aucune parcelle à afficher"
                  indicator="(seules les parcelles ne contenant aucun volume ni lot sont affichées)"
                  data={filteredData.filteredParcelles}
                  type="cadastre"
                  selectedProperties={selectedProperties}
                  onSelectProperty={handleSelectProperty}
                  loading={isLoading}
                  colNum={0}
                  handleChange={handleChange}
                  expanded={expanded}
                />
              </Grid>
              <Grid item xs={1}>
                <FiliationList
                  blockUi={blockUi}
                  color="warning"
                  title="Volumes entiers :"
                  noDataMessage="Aucun volume à afficher"
                  indicator="(seuls les volumes ne contenant aucun lot sont affichés)"
                  data={filteredData.filteredVolumes}
                  type="volume"
                  selectedProperties={selectedProperties}
                  onSelectProperty={handleSelectProperty}
                  loading={isLoading}
                  colNum={2}
                  handleChange={handleChange}
                  expanded={expanded}
                />
              </Grid>
              <Grid item xs={1} >
                <FiliationList
                  blockUi={blockUi}
                  color="primary"
                  title="Lots entiers :"
                  noDataMessage="Aucun lot à afficher"
                  indicator="(vous pouvez filtrer les lots par parcelle et par volume)"
                  data={filteredData.filteredLots}
                  type="lot"
                  selectedProperties={selectedProperties}
                  onSelectProperty={handleSelectProperty}
                  loading={isLoading}
                  colNum={3}
                  handleChange={handleChange}
                  expanded={expanded}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Actions */}
          <Box>
            <Stack direction="row" gap={3} justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/folders");
                }}
                disabled={filiationLoading || !!blockUi}
              >
                Annuler
              </Button>
              <WarningTooltip title={selectedProperties.length ? "" : "Vous devez sélectionner au moins un bien"}>
                <span>
                  <Button
                    variant="outlined"
                    color="success"
                    disabled={!selectedProperties.length || filiationLoading || !!blockUi}
                    onClick={() => {
                      setFiliation({
                        folderId: folderId || "",
                        subFolderId: subFolderId || "",
                        properties: getValidatedProperties(selectedProperties),
                      });
                    }}
                  >
                    Valider
                  </Button>
                </span>
              </WarningTooltip>
            </Stack>
          </Box>

        </Stack>
      </Box>

      {/* Blocking UI preventing user to udate filiation when saving is in progress */}
      {blockUi ? (
        <Backdrop
          sx={{ color: "rgba(0,0,0,1)", zIndex: (theme) => theme.zIndex.drawer + 1, height: "100%" }}
          open={blockUi}
          onClick={undefined}
        ></Backdrop>
      ) : null}

      {/* Confirmation pop-up to lock the filiation */}
      <Dialog
        keepMounted={false}
        open={isConfirmModalOpen && setFiliationSuccess}
        onClose={handleCloseModal}
        aria-labelledby="confirm-lock-dialog-title"
        disableEscapeKeyDown
      >
        <DialogContent>
          <Typography
            variant="body1"
            align="center"
            sx={{ my: 2, color: theme.palette.primary.main, fontWeight: "bold" }}
          >
            Avez-vous trouvé l'ensemble des biens objets de votre mutation ?
          </Typography>

          <Typography variant="body2" align="center" sx={{ my: 2, color: theme.palette.secondary.main }}>
            <strong>Oui</strong>: Vous ne recevrez plus de mails pour cette filiation.
            <br />
            <strong>Non</strong>: Vous recevrez un mail pour chaque nouveau bien identifié.
            <br />
          </Typography>
          <Typography variant="body2" align="center" sx={{ my: 2, color: theme.palette.secondary.main }}>
            <i>Vous pourrez toujours modifier votre choix de filiation dans les paramètres du sous-dossier.</i>
          </Typography>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: "center",
            pb: 3,
            gap: 2,
          }}
        >
          <Button
            onClick={() => {
              setPreviousModalResponse("Non");
              setIsConfirmModalOpen(false);
              lockFiliationMutation({
                folderId: folderId || "",
                flowId: subFolderId || "",
                lockValue: false,
              });
            }}
            color="primary"
            variant="contained"
            sx={{ width: 120 }}
          >
            Non
          </Button>

          <Button
            onClick={() => {
              setPreviousModalResponse("Oui");
              setIsConfirmModalOpen(false);
              lockFiliationMutation({
                folderId: folderId || "",
                flowId: subFolderId || "",
                lockValue: true,
              });
            }}
            color="success"
            variant="outlined"
            sx={{ width: 120 }}
          >
            Oui
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
