import { UploadableFile } from "@/taskpane/types/file";
import { useState, useRef } from "react";
import { ACCEPTED_MIME_MAPPING } from "@/taskpane/config/mime-accepted";
/**
 * Checks if the given file is allowed based on its mime type and extension.
 *
 * @param file - The file to check.
 * @returns {boolean} True if the file is allowed, false otherwise.
 */
export function checkMimeTypeAllow(file: File): boolean {
  const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
  return Object.keys(ACCEPTED_MIME_MAPPING).includes(fileExtension);
}


/**
 * Reads an uploaded file as base64.
 *
 * @param file - The file to be read.
 * @returns A promise that resolves with the base64 representation of the file.
 */
const readUploadedFileAsBase64 = (file: File) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.addEventListener("load", function () {
      const base64 = temporaryFileReader.result;
      resolve(base64);
    });

    temporaryFileReader.readAsDataURL(file);
  });
};

/**
 * Generates a random ID like "b4p9f0o".
 *
 * @returns {string} The generated random ID.
 */
function generateRandomId() {
  return Math.random().toString(36).substring(7);
}

interface FileWithMetadata extends UploadableFile {
  mimeTypeAllow: boolean;
  file: File; // Store the original File object
}

/**
 * Custom hook for handling file drag and drop functionality.
 *
 * @param selectFiles - A function that will be called with an array of files when files are selected.
 * @returns An object with the following properties and methods:
 *   - files: An array of UploadableFile objects with an additional "mimeTypeAllow" property.
 *   - onDrop: An asynchronous function that handles the drop event and processes the selected files.
 *   - resetFiles: A function that resets the files array to an empty array.
 */
export function useFileDnd(selectFiles: (files: (UploadableFile & { mimeTypeAllow: boolean })[]) => void) {
  const [files, setFiles] = useState<FileWithMetadata[]>([]);
  const [processingCount, setProcessingCount] = useState<{ total: number; processed: number }>({
    total: 0,
    processed: 0
  });

  return {
    files,
    processingCount,
    onDrop(selectedFiles: File[]) {
      // Set the total number of files to be processed
      setProcessingCount({ total: selectedFiles.length, processed: 0 });

      selectedFiles.forEach(async (file) => {
        try {
          const newFile = {
            id: `${generateRandomId()}-${file.name}`,
            name: file.name,
            size: file.size,
            type: file.type,
            mimeTypeAllow: checkMimeTypeAllow(file),
            file, // Store the original File object
            base64: '', // Will be populated when needed
          };

          setFiles((prevFiles) => [...prevFiles, newFile]);

          // Increment the processed count
          setProcessingCount(prev => ({
            ...prev,
            processed: prev.processed + 1
          }));

          if (newFile.mimeTypeAllow) {
            selectFiles([newFile]);
          }
        } catch (error) {
          console.error('Error processing file:', error);
          // Still increment the processed count even if there's an error
          setProcessingCount(prev => ({
            ...prev,
            processed: prev.processed + 1
          }));
        }
      });
    },
    resetFiles() {
      setFiles([]);
      setProcessingCount({ total: 0, processed: 0 });
    },
  };
}
