import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { styles } from "./FolderPageLayout.styles";

import { WarningTooltip } from "@/taskpane/utils/tooltips";
import LoadingCircular from "@/taskpane/components/loading-circular/LoadingCircular";
import LoadingButton from "@/taskpane/components/button/LoadingButton";
import { useGetFoldersQuery, useGetSubFoldersQuery } from "@/taskpane/services/folders.hook";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Modal,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { renameFileExtension } from "@/taskpane/utils/formatFile";
import { getUploadUrls, uploadFile } from "@/taskpane/services/upload";
import PQueue from "p-queue";

import type { FolderPageLayoutProps } from "@/taskpane/types/folder";
import { useWindowSize } from "@uidotdev/usehooks";
import { useOfficeContext } from "@/taskpane/contexts/office/office-context";

/**
 * Constantes pour la configuration des requêtes et des performances
 */
const UPLOAD_CONFIG = {
  // Tailles des batchs
  URL_BATCH_SIZE: 10 as number,          // Nombre de fichiers par batch pour la récupération des URLs
  MIN_UPLOAD_BATCH_SIZE: 10 as number,   // Taille minimum d'un batch d'upload
  MAX_UPLOAD_BATCH_SIZE: 30 as number,   // Taille maximum d'un batch d'upload

  // Limites de concurrence
  MIN_CONCURRENT_REQUESTS: 2 as number,   // Nombre minimum de requêtes simultanées
  DEFAULT_CONCURRENT_REQUESTS: 4 as number, // Nombre par défaut de requêtes simultanées
  MAX_CONCURRENT_REQUESTS: 10 as number,  // Nombre maximum de requêtes simultanées
  BROWSER_CONNECTION_LIMIT: 6 as number,  // Limite standard du navigateur

  // Timeouts et retries
  UPLOAD_TIMEOUT: 5000 as number,       // Timeout pour chaque upload (5 secondes)
  RETRY_COUNT: 2 as number,              // Nombre de tentatives en cas d'échec
  RETRY_DELAY: 1000 as number,          // Délai entre les tentatives (1 seconde)

  // Seuils de performance
  MEMORY_THRESHOLDS: {
    LOW: 1 as number,    // 1GB - performances réduites
    MEDIUM: 4 as number, // 4GB - performances moyennes
    HIGH: 8 as number    // 8GB - bonnes performances
  },

  NETWORK_THRESHOLDS: {
    DOWNLINK: {
      SLOW: 1 as number,    // 1Mbps
      MEDIUM: 5 as number,  // 5Mbps
      FAST: 10 as number    // 10Mbps
    },
    LATENCY: {
      HIGH: 500 as number,  // 500ms
      MEDIUM: 250 as number // 250ms
    }
  }
} as const;

/**
 * @description Déterminer le nombre optimal de requêtes concurrentes en fonction des capacités du client
 * @returns {number} Nombre optimal de requêtes concurrentes
 */
function determineOptimalConcurrency(): number {
  let maxConcurrentRequests = UPLOAD_CONFIG.DEFAULT_CONCURRENT_REQUESTS;

  // Vérifier la mémoire disponible
  if ('deviceMemory' in navigator) {
    const memory = (navigator as any).deviceMemory;
    if (memory <= UPLOAD_CONFIG.MEMORY_THRESHOLDS.LOW) {
      maxConcurrentRequests = 3;
    } else if (memory <= UPLOAD_CONFIG.MEMORY_THRESHOLDS.MEDIUM) {
      maxConcurrentRequests = 6;
    } else if (memory <= UPLOAD_CONFIG.MEMORY_THRESHOLDS.HIGH) {
      maxConcurrentRequests = 8;
    } else {
      maxConcurrentRequests = UPLOAD_CONFIG.MAX_CONCURRENT_REQUESTS;
    }
  }

  if ("connection" in navigator) {
    const {connection} = navigator as any;

    if (connection?.effectiveType) {
      switch (connection.effectiveType) {
        case 'slow-2g':
          maxConcurrentRequests = Math.min(maxConcurrentRequests, UPLOAD_CONFIG.MIN_CONCURRENT_REQUESTS);
          break;
        case '2g':
          maxConcurrentRequests = Math.min(maxConcurrentRequests, 3);
          break;
        case '3g':
          maxConcurrentRequests = Math.min(maxConcurrentRequests, 5);
          break;
      }
    }

    if (connection?.saveData) {
      maxConcurrentRequests = Math.max(UPLOAD_CONFIG.MIN_CONCURRENT_REQUESTS, Math.floor(maxConcurrentRequests / 2));
    }

    if (connection?.downlink) {
      if (connection.downlink < UPLOAD_CONFIG.NETWORK_THRESHOLDS.DOWNLINK.SLOW) {
        maxConcurrentRequests = Math.min(maxConcurrentRequests, UPLOAD_CONFIG.MIN_CONCURRENT_REQUESTS);
      } else if (connection.downlink < UPLOAD_CONFIG.NETWORK_THRESHOLDS.DOWNLINK.MEDIUM) {
        maxConcurrentRequests = Math.min(maxConcurrentRequests, 4);
      } else if (connection.downlink < UPLOAD_CONFIG.NETWORK_THRESHOLDS.DOWNLINK.FAST) {
        maxConcurrentRequests = Math.min(maxConcurrentRequests, 6);
      }
    }

    if (connection?.rtt) {
      if (connection.rtt > UPLOAD_CONFIG.NETWORK_THRESHOLDS.LATENCY.HIGH) {
        maxConcurrentRequests = Math.min(maxConcurrentRequests, 3);
      } else if (connection.rtt > UPLOAD_CONFIG.NETWORK_THRESHOLDS.LATENCY.MEDIUM) {
        maxConcurrentRequests = Math.min(maxConcurrentRequests, 5);
      }
    }
  }

  const cpuCores = navigator.hardwareConcurrency || 4;
  maxConcurrentRequests = Math.min(maxConcurrentRequests, cpuCores * 2);
  maxConcurrentRequests = Math.min(maxConcurrentRequests, UPLOAD_CONFIG.BROWSER_CONNECTION_LIMIT);

  const finalConcurrency = Math.max(
    UPLOAD_CONFIG.MIN_CONCURRENT_REQUESTS,
    Math.min(maxConcurrentRequests, UPLOAD_CONFIG.MAX_CONCURRENT_REQUESTS)
  );

  console.debug('Concurrent requests calculation:', {
    deviceMemory: (navigator as any).deviceMemory,
    connection: (navigator as any).connection,
    cpuCores,
    finalConcurrency
  });

  return finalConcurrency;
}

export default function FolderPageLayout({
  canValidate,
  children,
  files,
  folderId,
  onSelectSubFolder,
  onUpload,
  onValidate,
  selectedSubfolder,
  setUploadProgress,
}: FolderPageLayoutProps) {
  const [numberOfFilesToUpload, setNumberOfFilesToUpload] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [showLoadingBar, setShowLoadingBar] = useState(false);
  const { height, width } = useWindowSize();
  const officeContext = useOfficeContext();

  console.log(officeContext);

  const isSmallScreen = useMemo(() => height ? height < 800 : false, [height]);

  const { data: folders } = useGetFoldersQuery();
  const { data: subFolders, isFetching: subFoldersLoading } = useGetSubFoldersQuery(folderId || "");

  const maxConcurrentRequests = useMemo(() => {
    const optimal = determineOptimalConcurrency();
    return Math.min(optimal * 2, 20);
  }, []);

  const queue = useMemo(() => {
    return new PQueue({
      concurrency: maxConcurrentRequests,
      timeout: UPLOAD_CONFIG.UPLOAD_TIMEOUT,
      throwOnTimeout: false
    });
  }, [maxConcurrentRequests]);

  const processBatch = useCallback(async (batch: any[], originalFiles: any[]) => {
    const uploadPromises = batch.map((file) => {
      const currentFile = file;
      const fileInput = originalFiles.find((f: any) => f.name === currentFile.filename);
      return async () => {
        try {
          await uploadFile({
            file: currentFile,
            fileInput: fileInput,
            onProgress: (progress: any) => {
              if (setUploadProgress && fileInput) {
                setUploadProgress((prev: any) => ({
                  ...prev,
                  [fileInput.id]: { status: "uploading", progress },
                }));
              }
            },
          });

          if (setUploadProgress && fileInput) {
            setUploadProgress((prev: any) => ({
              ...prev,
              [fileInput.id]: { status: "success", progress: 100 },
            }));
          }
          setUploadedFiles(prev => {
            const newValue = prev + 1;
            // Check if this was the last file
            if (newValue === numberOfFilesToUpload) {
              setShowLoadingBar(false);
              enqueueSnackbar(
                "Envoi terminé, l'analyse est en cours et les résultats seront directement intégrés dans votre logiciel",
                { variant: "success" }
              );
            }
            return newValue;
          });
        } catch (error) {
          if (setUploadProgress && fileInput) {
            setUploadProgress((prev: any) => ({
              ...prev,
              [fileInput.id]: { status: "error", progress: 0 },
            }));
          }
          setShowLoadingBar(false);
          throw error;
        }
      };
    });

    const promises = uploadPromises.map(uploadPromise =>
      queue.add(uploadPromise, { priority: 1 })
        .catch((error) => {
          console.error('Upload failed:', error);
          setShowLoadingBar(false);
        })
    );

    await Promise.all(promises);
  }, [queue, setUploadProgress, numberOfFilesToUpload, enqueueSnackbar]);

  const navigate = useNavigate();

  useEffect(() => {
    if (files?.length > 0) {
      setNumberOfFilesToUpload(files.length);
      setUploadedFiles(0); // Reset uploaded files count when files change
    }
  }, [files]);

  const getUploadUrlsMutation: any = useMutation({
    mutationFn: async (data: any) => {
      setShowLoadingBar(true);
      setUploadedFiles(0);
      const formatedData = {
        ...data,
        files: data.files.map((file: any) => ({
          ...file,
          id: renameFileExtension(file.id),
          name: renameFileExtension(file.name),
        })),
      };

      const batches = [];
      for (let i = 0; i < formatedData.files.length; i += UPLOAD_CONFIG.URL_BATCH_SIZE) {
        const end = Math.min(i + UPLOAD_CONFIG.URL_BATCH_SIZE, formatedData.files?.length);
        batches.push(formatedData.files.slice(i, end));
      }

      let accumulatedResults: any[] = [];

      for (let batchIndex = 0; batchIndex < batches?.length; batchIndex++) {
        const batch = batches[batchIndex];
        try {
          const batchResult = await getUploadUrls({
            ...formatedData,
            files: batch,
          });
          accumulatedResults = accumulatedResults.concat(batchResult.files);

          // Start uploading this batch immediately
          if (accumulatedResults.length > 0) {
            const currentBatch = accumulatedResults.slice(
              batchIndex * UPLOAD_CONFIG.URL_BATCH_SIZE,
              (batchIndex + 1) * UPLOAD_CONFIG.URL_BATCH_SIZE
            );

            await processBatch(currentBatch, data.files).catch((error: Error) => {
              console.error('Failed to process batch:', error);
            });
          }
        } catch (error) {
          console.error('Failed to get upload URLs for batch:', error);
          setShowLoadingBar(false);
        }
      }

      return { ...formatedData, files: accumulatedResults };
    },
    onError: () => {
      setShowLoadingBar(false);
    },
  });

  const handleValidate = useCallback(() => {
    if (!canValidate) {
      return;
    }
    onUpload?.();
    getUploadUrlsMutation.mutate({
      folderId: folderId!,
      subFolderId: selectedSubfolder!.sous_dossier_id,
      files,
    });
    onValidate?.();
  }, [canValidate, folderId, selectedSubfolder, files, onUpload, onValidate, getUploadUrlsMutation]);

  const currentFolder = useMemo(() => folders?.dossiers.find((f) => f.dossier_id === folderId), [folders, folderId]);

  return (
    <>
    <Stack id="folder-page-layout" sx={styles.root}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">

        {/** Back button */}
        {!showLoadingBar && (
          <Button onClick={() => navigate("/folders")} sx={styles.backButton}>
            Retour
          </Button>
        )}

      </Stack>

      {/** Folder title */}
      <Typography sx={styles.folderTitle} noWrap>
        Dossier: {currentFolder?.name}
      </Typography>


      {/** Sous-dossiers list */}
      <Box sx={styles.contentContainer}>
        <Box sx={styles.childrenContainer}>{children}</Box>
        <Stack sx={styles.subFolderSection}>
          <Typography sx={styles.subFolderTitle}>
            Sélection du sous-dossier :
          </Typography>
          <Card sx={styles.subFolderCard}>
            {subFoldersLoading && (
              <Stack sx={styles.loadingContainer}>
                <CircularProgress />
              </Stack>
            )}
            {subFolders?.map((sf) => (
              <FormControlLabel
                key={sf.sous_dossier_id}
                control={
                  <Radio
                    size="small"
                    checked={selectedSubfolder?.sous_dossier_id === sf?.sous_dossier_id}
                    color="secondary"
                    onChange={() => onSelectSubFolder(sf)}
                  />
                }
                label={sf.nature + " " + sf.name}
                slotProps={{ typography: { fontSize: 12 } }}
              />
            ))}
          </Card>
        </Stack>
      </Box>

      {/** Validate button */}
      <Stack sx={styles.validateButtonContainer}>
        {showLoadingBar ? (
          null
        ) : (
          <WarningTooltip title={!canValidate ? "Vous devez sélectionner un fichier et un sous-dossier" : ""}>
            <span>
              <LoadingButton
                disabled={!canValidate}
                onClick={handleValidate}
                variant={canValidate ? "outlined" : "contained"}
                color="success"
              >
                Valider
              </LoadingButton>
            </span>
          </WarningTooltip>
        )}
      </Stack>
    </Stack>
      <Modal
        open={showLoadingBar}
        aria-labelledby="modal-upload-in-progress"
        aria-describedby="modal-upload-in-progress"
        disableEscapeKeyDown
        sx={{
          "& .MuiBox-root": {
            maxWidth: '95vw',
            margin: 'auto',
            textAlign: 'center',
            padding: 1,
          }
        }}
      >
        <Box sx={styles.modalUploadInProgress}>
          <Alert severity="warning" color="warning"  sx={{ textAlign: 'left', backgroundColor: 'warning.light', color: 'text.primary', padding: "4px 6px", "& .MuiAlert-message": {
            padding: "4px",
            whiteSpace: width && width <= 250 ? "wrap" : "nowrap"
          } }}>
            <AlertTitle sx={{ textAlign: 'left', color: 'text.primary', whiteSpace: width && width <= 250 ? "wrap" : "nowrap" }}>
                <Typography id="modal-upload-in-progress" variant="subtitle2" component="h1" gutterBottom noWrap={!!(width && width > 250)} sx={{ fontSize: width && width <= 250 ? 10 : 12, color: 'text.primary' } }>
                {officeContext?.currentMailbox ? "Veuillez ne pas changer de mail" : "Veuillez ne pas quitter la page"}
              </Typography>
            </AlertTitle>
            <Typography id="modal-upload-in-progress" variant="body2" component="h2" noWrap={!!(width && width > 250)} sx={{ fontSize: width && width <= 250 ? 10 : 12, color: 'text.secondary' }}>
              Envoi des documents en cours...
            </Typography>
          </Alert>
          <LoadingCircular
            text={`Documents envoyés: ${uploadedFiles} sur ${numberOfFilesToUpload}`}
            variant="determinate"
            progress={numberOfFilesToUpload > 0 ? (uploadedFiles / numberOfFilesToUpload) * 100 : 0}
          />
        </Box>
      </Modal>
    </>
  );
}
