import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function ControlCenterIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      strokeWidth={2}
      stroke="currentColor"
      width="12px"
      height="12px"
      {...props}
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M810 5110 c-138 -17 -295 -75 -407 -151 -70 -47 -178 -154 -229 -226
      -60 -83 -119 -212 -146 -319 l-23 -89 0 -1765 0 -1765 23 -89 c27 -107 86
      -236 146 -319 51 -72 159 -179 229 -226 75 -51 206 -109 303 -133 l89 -23
      1765 0 1765 0 89 23 c110 28 251 94 332 155 70 53 167 154 214 222 50 72 108
      204 132 301 l23 89 0 1765 0 1765 -23 89 c-24 97 -82 228 -133 303 -47 70
      -154 178 -226 229 -80 58 -210 118 -314 146 l-84 22 -1730 2 c-952 1 -1759 -2
      -1795 -6z m3600 -322 c179 -66 316 -205 382 -388 l23 -65 3 -1753 c2 -1934 6
      -1804 -60 -1937 -63 -127 -158 -223 -283 -284 -133 -65 -8 -61 -1915 -61
      -1909 0 -1782 -4 -1915 62 -127 63 -223 158 -284 283 -65 133 -61 8 -61 1915
      0 1531 2 1739 15 1792 58 222 216 384 440 449 56 17 169 18 1820 16 l1760 -2
      75 -27z"
        />
        <path
          d="M1458 4291 c-120 -39 -226 -129 -280 -238 l-31 -63 -194 0 c-209 0
      -225 -3 -273 -55 -21 -23 -25 -36 -25 -90 0 -54 4 -67 25 -90 48 -52 64 -55
      273 -55 l194 0 33 -65 c36 -72 131 -170 195 -203 242 -123 538 -20 649 226
      l19 42 1159 0 c1258 0 1184 -3 1238 55 21 23 25 36 25 90 0 54 -4 67 -25 90
      -54 58 20 55 -1237 55 l-1158 0 -35 67 c-60 116 -170 206 -294 239 -71 19
      -189 17 -258 -5z m205 -285 c44 -18 95 -78 103 -121 14 -76 -9 -133 -70 -180
      -93 -71 -232 -22 -267 94 -40 134 104 262 234 207z"
        />
        <path
          d="M3423 3015 c-132 -29 -259 -128 -318 -250 l-27 -55 -1152 0 c-1113 0
      -1152 -1 -1193 -20 -112 -50 -112 -210 0 -260 41 -19 80 -20 1193 -20 l1152 0
      27 -55 c87 -179 294 -287 489 -256 146 23 280 121 345 249 l31 62 188 0 c163
      0 192 3 229 20 112 50 112 210 0 260 -37 17 -66 20 -229 20 l-188 0 -31 62
      c-37 74 -124 162 -196 201 -56 30 -165 57 -223 56 -19 0 -63 -7 -97 -14z m201
      -314 c40 -25 76 -92 76 -141 0 -55 -36 -117 -85 -146 -92 -54 -204 -22 -246
      70 -75 165 99 313 255 217z"
        />
        <path
          d="M2127 1729 c-122 -29 -261 -142 -307 -251 l-25 -58 -518 0 c-560 0
      -544 1 -597 -55 -21 -23 -25 -36 -25 -90 0 -54 4 -67 25 -90 53 -56 37 -55
      597 -55 l518 0 24 -56 c30 -69 99 -146 173 -195 128 -82 302 -93 443 -25 97
      45 181 127 221 214 l29 62 838 0 c909 0 863 -3 917 55 21 23 25 36 25 90 0 54
      -4 67 -25 90 -54 58 -8 55 -917 55 l-838 0 -28 60 c-53 113 -174 212 -302 246
      -56 15 -170 17 -228 3z m207 -310 c108 -74 99 -242 -17 -298 -160 -78 -321
      101 -223 249 52 79 163 102 240 49z"
        />
      </g>
    </SvgIcon>
  );
}
