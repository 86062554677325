import { Theme } from "@mui/material";

export const styles = {
  card: (theme: Theme, color: string) => ({
    p: 3,
    boxShadow: 'none',
    position: 'relative',
    color: `${color}.darker`,
    backgroundColor: 'common.white',
  }),

  trendingBox: {
    top: 16,
    gap: 0.5,
    right: 16,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
  },

  contentBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },

  titleBox: {
    flexGrow: 1,
    minWidth: 112,
  },

  title: {
    mb: 1,
    typography: 'subtitle2',
  },

  total: {
    typography: 'h4',
  },

  chart: {
    width: 84,
    height: 56,
  },

  backgroundShape: (color: string) => ({
    top: 0,
    left: -20,
    width: 240,
    zIndex: -1,
    height: 240,
    opacity: 0.24,
    position: 'absolute',
    color: `${color}.main`,
  }),
};