import { SxProps } from "@mui/material";
import { DESKTOP_MIN_WIDTH } from "@/taskpane/styles/responsive";

const styles = {
  container: {
    width: "100%",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      maxWidth: "400px",
      alignSelf: "flex-start"
    }
  },

  card: (connectionPending: boolean): SxProps => ({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 1,
    opacity: connectionPending ? 0.5 : 1,
    pointerEvents: connectionPending ? "none" : "auto",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      borderRadius: 2,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      border: "1px solid",
      borderColor: "divider",
      padding: 2
    }
  }),

  form: {
    display: "flex",
    flexDirection: "column",
    gap: 2
  },

  tabs: {
    "& .MuiTab-root": {
      textAlign: "left",
      justifyContent: "flex-start",
      paddingLeft: 1,
      paddingRight: 1,
      minHeight: 40
    }
  },

  button: {
    marginTop: 2,
    alignSelf: "flex-start"
  }
};

export default styles;