import { SxProps, Theme } from "@mui/material";
import { DESKTOP_MIN_WIDTH, desktopMaxWidth, desktopPadding } from "@/taskpane/styles/responsive";

type StyleProps = {
  height?: string | number;
  width?: string | number;
  display?: string;
  flexDirection?: string;
  [key: string]: any;
};

export const styles = {
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...desktopPadding,
    ...desktopMaxWidth(600),
    margin: "0 auto",
  } as StyleProps,
  title: {
    fontWeight: "bold",
    fontSize: 14,
    color: "primary.dark",
    width: "100%",
    textAlign: "left",
  },
  card: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    opacity: (theme: Theme) => (theme as any).opacity?.loading || 0.5,
    pointerEvents: "none",
  },
  cardActive: {
    opacity: 1,
    pointerEvents: "auto",
  },
  dragDropContainer: {
    p: 4,
    width: "100%",
    height: "100%",
  },
  fileListContainer: {
    direction: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 1,
    width: "100%",
  },
  fileList: {
    direction: "column",
    gap: 0.5,
    width: 1,
  },
  foldedDragDrop: {
    width: "60%",
    mt: 1,
    mb: 1,
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      maxWidth: "400px",
    },
  },
} as const;
