import { SxProps } from "@mui/material";
import { DESKTOP_MIN_WIDTH } from "@/taskpane/styles/responsive";

const styles = {
  container: {
    width: "100%",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      
      alignSelf: "flex-start"
    }
  },

  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 500px)",
    overflowY: "auto",
    padding: 0,
    width: "100%",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      borderRadius: 2,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      border: "1px solid",
      borderColor: "divider",
      maxHeight: "calc(100vh - 600px)"
    }
  },

  loadingContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: 2,
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      padding: 3
    }
  },

  subfolderRow: {
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 0 4px 4px",
    width: "100%",
    "&:hover": {
      backgroundColor: "action.hover"
    },
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      padding: "8px 0 8px 8px"
    }
  },

  formControl: {
    margin: 0,
    paddingLeft: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      flex: "none",
      marginLeft: 1
    }
  },

  label: {
    fontSize: 12,
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      fontSize: 14
    }
  }
};

export default styles;