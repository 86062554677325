import CustomCheckbox from "@/taskpane/components/custom-checkbox/CustomCheckbox";
import FileIconType from "@/taskpane/components/file-icon-type/FileIconType";
import { UploadableFile } from "@/taskpane/types/file";
import { StyledTooltip } from "@/taskpane/utils/tooltips";
import { Box, Stack, StackProps, Typography, TypographyProps, useTheme } from "@mui/material";
import React, { useCallback, PropsWithChildren, useMemo, useState, useEffect, useRef } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { memo } from 'react';
import { useInView } from 'react-intersection-observer';
import { alpha } from '@mui/material/styles';

export interface CheckableFileProps {
  attachment: UploadableFile & { mimeTypeAllow: boolean };
  selectedAttachments: (UploadableFile & { mimeTypeAllow: boolean })[];
  setSelectedAttachments: React.Dispatch<React.SetStateAction<(UploadableFile & { mimeTypeAllow: boolean })[]>>;
  uploaded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  failed?: boolean;
  style?: React.CSSProperties;
  isNew?: boolean;
}

type FileType = UploadableFile & { mimeTypeAllow: boolean };

// Memoized styled components
const StyledStack = memo<PropsWithChildren<StackProps>>(({ children, ...props }) => (
  <Stack
    direction="row"
    alignItems="center"
    gap={0.5}
    ml={1}
    {...props}
  >
    {children}
  </Stack>
));

StyledStack.displayName = 'StyledStack';

const StyledTypography = memo<PropsWithChildren<TypographyProps>>(({ children, ...props }) => (
  <Typography fontSize={12} noWrap {...props}>
    {children}
  </Typography>
));

StyledTypography.displayName = 'StyledTypography';

// Individual file item component with intersection observer
const FileItem = memo(function FileItem({
  attachment,
  selectedAttachments,
  setSelectedAttachments,
  uploaded,
  disabled,
  loading,
  isNew = false,
  style,
  ref
}: CheckableFileProps & { ref?: React.Ref<HTMLDivElement> }) {
  const theme = useTheme();
  const itemRef = useRef<HTMLDivElement | null>(null);
  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  // Combine refs
  const setRefs = useCallback((node: HTMLDivElement | null) => {
    itemRef.current = node;
    inViewRef(node);
    // Forward the ref if it's provided
    if (typeof ref === 'function') {
      ref(node);
    } else if (ref) {
      (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
    }
  }, [inViewRef, ref]);

  // Scroll into view if it's a new item
  useEffect(() => {
    if (isNew && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isNew]);

  const handleClick = useCallback(() => {
    if (!disabled && attachment.mimeTypeAllow) {
      setSelectedAttachments((prev: FileType[]) => {
        const isSelected = prev.some(a => a.id === attachment.id);
        if (isSelected) {
          return prev.filter(a => a.id !== attachment.id);
        }
        return [...prev, attachment] as FileType[];
      });
    }
  }, [disabled, attachment, setSelectedAttachments]);

  const isChecked = selectedAttachments.some((a) => a.id === attachment.id);

  // Don't render content if not in view
  if (!inView) {
    return <div ref={setRefs} style={{ height: '26px' }} />;
  }

  return (
    <StyledStack
      ref={setRefs}
      sx={{
        cursor: disabled ? "default" : "pointer",
        marginTop: "2px",
        marginBottom: "2px",
        opacity: inView ? 1 : 0,
        transition: 'opacity 0.2s ease-in-out',
        backgroundColor: isNew ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
        ...style,
      }}
      onClick={handleClick}
    >
      <StyledTooltip
        type={uploaded ? "success" : "warning"}
        title={
          uploaded
            ? "Ce document a déjà été intégré dans un sous-dossier"
            : !attachment.mimeTypeAllow
              ? "Ce type de fichier n'est pas autorisé"
              : ""
        }
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        }}
      >
        <span>
          {loading ? <CircularProgress size={16} /> :
            <CustomCheckbox
              size="small"
              checked={isChecked}
              color={uploaded ? "success" : "warning"}
              sx={
                uploaded
                  ? {
                    ".MuiSvgIcon-root": {
                      color: theme.palette.success.main,
                    },
                  }
                  : undefined
              }
              disabled={disabled}
            />
          }
        </span>
      </StyledTooltip>
      <Box sx={{ opacity: disabled ? 0.5 : 1 }}>
        <FileIconType fileName={attachment.name} />
      </Box>

      <StyledTypography sx={{ color: disabled ? "gray" : "inherit", maxWidth: "75vw" }}>
        {attachment.name}
      </StyledTypography>
    </StyledStack>
  );
});

FileItem.displayName = 'FileItem';

// Chunked list component with virtualization
const CHUNK_SIZE = 50;
const BUFFER_SIZE = 10; // Number of items to render beyond visible area

interface ChunkedListProps {
  items: FileType[];
  selectedAttachments: FileType[];
  setSelectedAttachments: React.Dispatch<React.SetStateAction<FileType[]>>;
  uploaded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  newItemIds?: Set<string>;
}

const ChunkedList = memo(({
  items,
  selectedAttachments,
  setSelectedAttachments,
  uploaded,
  disabled,
  loading,
  newItemIds = new Set()
}: ChunkedListProps) => {
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: CHUNK_SIZE });

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight } = e.currentTarget;
    const itemHeight = 26;
    const visibleItems = Math.ceil(clientHeight / itemHeight);
    const startIndex = Math.floor(scrollTop / itemHeight);
    const endIndex = Math.min(
      startIndex + visibleItems + BUFFER_SIZE,
      items.length
    );

    setVisibleRange({
      start: Math.max(0, startIndex - BUFFER_SIZE),
      end: endIndex
    });
  }, [items.length]);

  const displayedItems = useMemo(() =>
    items
      .slice(visibleRange.start, visibleRange.end)
      .map(item => (
        <FileItem
          key={item.id}
          attachment={item}
          selectedAttachments={selectedAttachments}
          setSelectedAttachments={setSelectedAttachments}
          uploaded={uploaded}
          disabled={disabled}
          loading={loading}
          isNew={newItemIds.has(item.id)}
        />
      )), [items, visibleRange, selectedAttachments, setSelectedAttachments, uploaded, disabled, loading, newItemIds]);

  return (
    <div
      style={{
        height: '100%',
        overflowY: 'auto',
        paddingRight: '8px'
      }}
      onScroll={handleScroll}
    >
      <div style={{ height: `${visibleRange.start * 26}px` }} />
      {displayedItems}
      <div style={{ height: `${(items.length - visibleRange.end) * 26}px` }} />
    </div>
  );
});

ChunkedList.displayName = 'ChunkedList';

// Main CheckableFile list component
interface CheckableFileListProps {
  attachments: FileType[];
  selectedAttachments: FileType[];
  setSelectedAttachments: React.Dispatch<React.SetStateAction<FileType[]>>;
  uploaded?: boolean;
  disabled?: boolean;
  loading?: boolean;
  newItemIds?: Set<string>;
  ref?: React.Ref<HTMLDivElement>;
}

const CheckableFileList = memo(function CheckableFileList({
  attachments,
  selectedAttachments,
  setSelectedAttachments,
  uploaded,
  disabled,
  loading,
  newItemIds,
}: CheckableFileListProps) {
  return (
      <ChunkedList
        items={attachments}
        selectedAttachments={selectedAttachments}
        setSelectedAttachments={setSelectedAttachments}
        uploaded={uploaded}
        disabled={disabled}
        loading={loading}
        newItemIds={newItemIds}
      />
  );
});

CheckableFileList.displayName = 'CheckableFileList';

export default CheckableFileList;