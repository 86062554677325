import { EspaceNotarialStatus, ShieldType } from "@/taskpane/types/notary-space";

/**
 * Checks if the given status is a valid EspaceNotarialStatus.
 * @param status - The status to be checked.
 * @returns True if the status is a valid EspaceNotarialStatus, false otherwise.
 */
export function isEspaceNotarialStatus(status: string): status is EspaceNotarialStatus {
  return Object.keys(mapping_status).includes(status);
}

export const mapping_status: Record<
  EspaceNotarialStatus,
  {
    new_connection_possible: boolean;
    shield_label: string;
    shield_type: ShieldType;
    refresh_type: string;
    refresh_icon_disabled: boolean;
    refresh_icon_tooltip: string;
  }
> = {
  connection_pending: {
    new_connection_possible: true,
    shield_label: "",
    shield_type: "disabled",
    refresh_type: "",
    refresh_icon_disabled: true,
    refresh_icon_tooltip: "",
  },
  connection_success: {
    new_connection_possible: true,
    shield_label: "",
    shield_type: "disabled",
    refresh_type: "",
    refresh_icon_disabled: true,
    refresh_icon_tooltip: "",
  },
  connection_fail: {
    new_connection_possible: true,
    shield_label: "",
    shield_type: "disabled",
    refresh_type: "",
    refresh_icon_disabled: true,
    refresh_icon_tooltip: "",
  },
  connection_fail_wrong_credentials: {
    new_connection_possible: true,
    shield_label: "",
    shield_type: "disabled",
    refresh_type: "",
    refresh_icon_disabled: true,
    refresh_icon_tooltip: "",
  },
  refresh_pending: {
    new_connection_possible: false,
    shield_label: "Mise à jour en cours",
    shield_type: "success",
    refresh_type: "gray",
    refresh_icon_disabled: true,
    refresh_icon_tooltip: "Mise à jour en cours",
  },
  refresh_in_progress: {
    new_connection_possible: false,
    shield_label: "Mise à jour en cours",
    shield_type: "success",
    refresh_type: "gray",
    refresh_icon_disabled: true,
    refresh_icon_tooltip: "Mise à jour en cours",
  },
  refresh_success: {
    new_connection_possible: false,
    shield_label: "Un accès espace-notarial a déjà été configuré pour ce sous-dossier",
    shield_type: "success",
    refresh_type: "green",
    refresh_icon_disabled: false,
    refresh_icon_tooltip: "Cliquez ici pour relancer l'analyse de la dataroom connectée à ce sous-dossier",
  },
  refresh_fail_wrong_credentials: {
    new_connection_possible: false,
    shield_label: "Les identifiants de connexion sont périmés",
    shield_type: "error",
    refresh_type: "error",
    refresh_icon_disabled: false,
    refresh_icon_tooltip: "Cliquez ici pour mettre à jour les identifiants de connexion",
  },
  refresh_fail_dataroom_not_found: {
    new_connection_possible: false,
    shield_label: "La dataroom configurée est introuvable",
    shield_type: "error",
    refresh_type: "error",
    refresh_icon_disabled: false,
    refresh_icon_tooltip: "Cliquez ici pour connecter une nouvelle dataroom",
  },
  refresh_fail: {
    new_connection_possible: false,
    shield_label: "Erreur lors de la mise à jour",
    shield_type: "error",
    refresh_type: "error",
    refresh_icon_disabled: false,
    refresh_icon_tooltip: "Cliquez ici pour reconnecter la dataroom",
  },
  download_pending: {
    new_connection_possible: false,
    shield_label: "Un accès espace-notarial a déjà été configuré pour ce sous-dossier",
    shield_type: "success",
    refresh_type: "gray",
    refresh_icon_disabled: true,
    refresh_icon_tooltip: "La dernière analyse de cette dataroom date d’il y à moins de 2 heures",
  },
  download_in_progress: {
    new_connection_possible: false,
    shield_label: "La dernière analyse de cette dataroom date d’il y à moins de 2 heures",
    shield_type: "success",
    refresh_type: "gray",
    refresh_icon_disabled: true,
    refresh_icon_tooltip: "La dernière analyse de cette dataroom date d’il y à moins de 2 heures",
  },
  download_fail_wrong_credentials: {
    new_connection_possible: false,
    shield_label: "Les identifiants de connexion sont périmés",
    shield_type: "error",
    refresh_type: "error",
    refresh_icon_disabled: false,
    refresh_icon_tooltip: "Cliquez ici pour mettre à jour les identifiants de connexion",
  },
  download_success: {
    new_connection_possible: false,
    shield_label: "Un accès espace-notarial a déjà été configuré pour ce sous-dossier",
    shield_type: "success",
    refresh_type: "green",
    refresh_icon_disabled: false,
    refresh_icon_tooltip: "Cliquez ici pour relancer l'analyse de la dataroom connectée à ce sous-dossier",
  },
  download_fail: {
    new_connection_possible: false,
    shield_label: "Erreur lors du téléchargement",
    shield_type: "error",
    refresh_type: "error",
    refresh_icon_disabled: false,
    refresh_icon_tooltip: "Cliquez ici pour reconnecter la dataroom",
  },
  vpn_missing_credentials: {
    new_connection_possible: true,
    shield_label: "",
    shield_type: "disabled",
    refresh_type: "",
    refresh_icon_disabled: true,
    refresh_icon_tooltip: "",
  }
};
