import { SxProps } from "@mui/material";
import { DESKTOP_MIN_WIDTH } from "@/taskpane/styles/responsive";

const styles = {
  container: {
    width: "100%",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      maxWidth: "400px",
      alignSelf: "flex-start"
    }
  },

  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: 1,
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      borderRadius: 2,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      border: "1px solid",
      borderColor: "divider",
      padding: 2
    }
  },

  content: {
    height: 1,
    gap: 0
  },

  searchField: {
    marginBottom: 0
  },

  dataroomList: {
    overflowY: "auto",
    height: "calc(100vh - 470px)",
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      height: "calc(100vh - 520px)"
    }
  },

  noResults: {
    fontWeight: "bold",
    color: "grey.400",
    textAlign: "center",
    padding: 3
  },

  formControl: {
    margin: 0,
    paddingLeft: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      flex: "none",
      marginLeft: 1
    }
  },

  label: {
    fontSize: 12,
    [`@media (min-width: ${DESKTOP_MIN_WIDTH}px)`]: {
      fontSize: 14
    }
  }
};

export default styles;