import React, { useMemo, useState } from "react";

import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import { SubFolder } from "@/taskpane/types/folder";
import { useFileDnd } from "@/taskpane/hooks/file-dnd";
import { UploadableFile } from "@/taskpane/types/file";
import DragAndDrop from "@/taskpane/components/drag-and-drop/DragAndDrop";
import FolderPageLayout from "@/taskpane/modules/folder/FolderPageLayout";
import { Box, Card, Stack, SxProps, Theme, Typography } from "@mui/material";
import CheckableFile from "@/taskpane/components/checkable-file/CheckableFile";

import { styles } from "./FolderDragAndDropPage.styles";

export default function FolderDragAndDropPage() {
  const params = useParams();
  const [selectedSubFolder, setSelectedSubFolder] = useState<SubFolder>();
  const [selectedFiles, setSelectedFiles] = useState<(UploadableFile & { mimeTypeAllow: boolean })[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<(UploadableFile & { mimeTypeAllow: boolean })[]>([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { onDrop, files } = useFileDnd((files) => setSelectedFiles((prev) => [...prev, ...files]));
  const [uploadProgress, setUploadProgress] = useState({});

  const renderFileList = useMemo(() => {
    return files.map((file) => {
      return (
        <CheckableFile
          key={file.id}
          attachments={[file]}
          uploaded={uploadProgress && (uploadProgress as Record<string, {status: string}>)[file.id]?.status === "success"}
          loading={uploadProgress && (uploadProgress as Record<string, {status: string}>)[file.id]?.status === "uploading"}
          selectedAttachments={selectedFiles}
          setSelectedAttachments={setSelectedFiles}
        />
      );
    });
  }, [files, uploadProgress, selectedFiles, setSelectedFiles]);



  return (
      <FolderPageLayout
        folderId={params.folderId}
        onSelectSubFolder={setSelectedSubFolder}
        selectedSubfolder={selectedSubFolder}
        files={selectedFiles}
        canValidate={!!selectedSubFolder && selectedFiles?.length > 0}
        onValidate={() => {
          setUploadLoading(false);
          setUploadedFiles([...uploadedFiles, ...selectedFiles]);
          setSelectedFiles([]);
          setSelectedSubFolder(undefined);
        }}
        onUpload={() => {
          setUploadLoading(true);
        }}
        setUploadProgress={setUploadProgress}
      >
        <Stack sx={styles.root}>
          <Typography sx={styles.title}>
            Sélection des fichiers déposés :
          </Typography>
          <Card sx={{ ...styles.card, ...(uploadLoading ? {} : styles.cardActive) } as SxProps<Theme>}>
            {files?.length === 0 ? (
              <Box sx={styles.dragDropContainer}>
                <DragAndDrop
                  options={{
                    multiple: true,
                    onDrop,
                    onError: (error) => {
                      console.error(error);
                      enqueueSnackbar(`Une erreur est survenue lors de la dépose des fichiers: ${error?.message}`, {
                        variant: "error",
                      });
                    },
                  }}
                />
              </Box>
            ) : (
              <Stack sx={styles.fileListContainer}>
                <Stack sx={styles.fileList}>
                  {renderFileList}
                </Stack>
                <Box sx={styles.foldedDragDrop}>
                  <DragAndDrop
                    folded
                    options={{
                      multiple: true,
                      onDrop,
                      onError: (error) => {
                        console.error(error);
                        enqueueSnackbar(`Une erreur est survenue lors de la dépose des fichiers: ${error?.message}`, {
                          variant: "error",
                        });
                      },
                    }}
                  />
                </Box>
              </Stack>
            )}
          </Card>
        </Stack>
      </FolderPageLayout>
  );
}
