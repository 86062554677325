import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    // colors generated using the design's main color + https://uicolors.app/create
    primary: {
      light: "#b0ccf1",
      main: "#1b489e",
      dark: "#1B269E",
      "50": "#ebf2fb",
      "100": "#d2e1f7",
      "200": "#b0ccf1",
      "300": "#79ace8",
      "400": "#387ddd",
      "500": "#1b489e",
      "600": "#1B269E",
      "700": "#223472",
      "800": "#202c5c",
      "900": "#212b4f",
    },
    secondary: {
      "50": "#fff9ed",
      "100": "#fff3d4",
      "200": "#ffe2a8",
      "300": "#ffcd70",
      "400": "#ffab37",
      "500": "#ff8e09",
      "600": "#f07506",
      "700": "#c75807",
      "800": "#9e450e",
      "900": "#7f3b0f",
      main: "#ff8e09",
      light: "#ffe2a8",
      dark: "#9e450e",
    },
    success: {
      main: "#22C55E",
      dark: "#00A76F",
      "900": "#199A48",
      "600": "#329F58",
      light: "#e7f4ec",
    },
    error: {
      main: "#FF0000",
      dark: "#B71D18",
      light: "#f9eeed",
    },
    warning: {
      main: "#FF8E09",
      dark: "#FF8E09",
      "600": "#F08200",
      light: "#fdf2e5",
    },
    text: {
      primary: "#1C252E",
      secondary: "#637381",
      disabled: "#919EAB",
    },
  },
  typography: {
    fontFamily: "Public Sans",
    button: {
      textTransform: "none",
      fontWeight: "bold",
      fontSize: 13,
    },
  },
  components: {
    MuiCard: {
      variants: [
        {
          props: {},
          style: ({ theme }) => ({
            padding: 8,
            boxShadow: "none",
            border: "solid 1px",
            borderRadius: 8,
            borderColor: theme.palette.grey[400],
          }),
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
      variants: [
        {
          props: { size: "large" },
          style: () => ({
            padding: "12px 8px",
            fontSize: 13,
          }),
        },
        {
          props: { variant: "outlined", color: "success" },
          style: ({ theme }) => ({
            color: theme.palette.success.main,
            backgroundColor: `${theme.palette.success.main}25`,
            border: `2px solid ${theme.palette.success.main}`,
            "&:hover": {
              backgroundColor: `${theme.palette.success.main}35`,
              border: `2px solid ${theme.palette.success.main}`,
            },
          }),
        },
        {
          props: { variant: "contained", color: "success" },
          style: ({ theme }) => ({
            color: theme.palette.success.main,
            backgroundColor: `${theme.palette.success.main}25`,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: `${theme.palette.success.main}35`,
            },
          }),
        },
        {
          props: { variant: "outlined", disabled: true },
          style: ({ theme }) => ({
            color: theme.palette.grey[500],
            backgroundColor: `${theme.palette.grey[700]}15`,
            border: `2px solid ${theme.palette.grey[500]}`,
          }),
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: ({ theme }) => ({
            color: theme.palette.primary.main,
            backgroundColor: `${theme.palette.primary.main}15`,
            border: `2px solid ${theme.palette.primary.main}`,
            "&:hover": {
              backgroundColor: `${theme.palette.primary.main}45`,
              border: `2px solid ${theme.palette.primary.main}`,
            },
          }),
        },
        {
          props: { color: "primary", variant: "contained" },
          style: ({ theme }) => ({
            boxShadow: `0px 8px 16px 0px ${theme.palette.primary.main}55`,
          }),
        },
      ],
    },
    MuiCheckbox: {
      defaultProps: {
        color: "secondary",
      },
      variants: [
        {
          props: { size: "small" },
          style() {
            return {
              height: 16,
              width: 16,
            };
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style() {
            return {
              "& .MuiInputBase-root": {
                padding: "6px 6px",
                fontSize: 14,
                borderRadius: 8,
              },
              "& .MuiInputLabel-root:not(.Mui-focused)": {
                marginTop: 6,
              },
              "& .MuiInputLabel-root.Mui-focused": {
                marginTop: 2,
              },
              "& .MuiInputLabel-root": {
                fontSize: 14,
              },
            };
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 36,
          backgroundColor: "#F4F6F8",
          borderRadius: 8,
          borderBottom: "solid 1px transparent !important",
          padding: 10,
          "& .MuiTabs-indicator": {
            display: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 8,
          minHeight: 36,
          color: "#637381",
          minWidth: 0,
          "&.Mui-selected": {
            backgroundColor: "white",
            borderRadius: 8,
            color: "#1C252E",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginLeft: 4,
          borderRadius: 8,
          boxShadow: "0px 0px 2px  0px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          minHeight: 22,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          minHeight: 0,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            backgroundColor: "#F4F6F8",
            borderBottom: "none",
            "&:first-child": {
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
            },
            "&:last-child": {
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px dashed #E9ECF8",
          padding: "3px 8px",
          fontSize: 12,
        },
      },
    },
  },
});
