import { SxProps, Theme } from "@mui/material";

// Props needed to compute dynamic styles based on drag state and theme
interface StyleProps {
  isDragAccept: boolean;
  isDragReject: boolean;
  theme: Theme;
}

export const styles = {
  // Main card container that handles the drag and drop
  card: ({ isDragAccept, isDragReject, theme }: StyleProps): SxProps => ({
    // Basic card styling
    p: 2, // Padding of 16px (MUI spacing unit * 2)
    cursor: "pointer",
    boxShadow: "none", // Remove default card shadow
    borderStyle: "dashed", // Dashed border to indicate droppable area
    borderRadius: 2, // Rounded corners

    // Dynamic background color based on drag state
    // Using alpha channel (1A = 10% opacity) for subtle feedback
    backgroundColor: isDragAccept 
      ? `${theme.palette.success.light}1A` // Green background when file is valid
      : isDragReject 
        ? `${theme.palette.error.light}1A` // Red background when file is invalid
        : "transparent",

    // Make the card fill its parent container
    width: "100%",
    height: "100%",

    // Center the content both horizontally and vertically
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),

  // Text shown in the folded (minimized) state
  foldedText: {
    fontSize: 13,
    fontWeight: 600,
    textAlign: "center",
    color: "grey",
    width: "100%", // Full width to ensure proper text alignment
  },

  // Stack container for the expanded state content
  contentStack: ({ isDragAccept, isDragReject, theme }: StyleProps): SxProps => ({
    spacing: 1, // Gap between stack items
    // Dynamic text color based on drag state
    color: isDragAccept 
      ? theme.palette.success.dark // Green text for valid files
      : isDragReject 
        ? theme.palette.error.dark // Red text for invalid files
        : theme.palette.text.primary, // Default text color
    
    // Center content and fill parent
    alignItems: "center",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    minHeight: 200,
    position: "relative",
    py: 2,
  }),

  // Upload illustration image
  illustration: {
    width: "auto",
    height: "auto",
    // To make the image larger/smaller:
    // 1. Increase/decrease maxWidth percentage (e.g., 80% for larger, 40% for smaller)
    maxWidth: "80%",
    // 2. Adjust the space reserved for text in maxHeight:
    // - Decrease 80px for larger image (e.g., calc(100% - 60px))
    // - Increase 80px for smaller image (e.g., calc(100% - 100px))
    maxHeight: "calc(100% - 60px)",
    objectFit: "contain",
    // 3. Adjust bottom margin (mb) if needed:
    // - Decrease for larger image
    // - Increase for smaller image
    mb: 0.5,
    display: "block",
    "@media (max-height: 660px)": {
      display: "none",
    },
  },

  // Main "Drop files here" text
  mainText: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "center",
    width: "100%", // Full width for proper alignment
  },

  // Secondary text below main text
  subText: {
    fontSize: 13,
    textAlign: "center",
    width: "100%", // Full width for proper alignment
  },

  // "Browse" text that appears clickable
  browseText: (theme: Theme): SxProps => ({
    color: theme.palette.primary.main, // Use primary color to indicate interactivity
    cursor: "pointer", // Show pointer cursor on hover
  }),
};
