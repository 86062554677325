import React from "react";

import { MdClose } from "react-icons/md";
import StyledSelect from "@/taskpane/components/styled-select/StyledSelect";
import CustomCheckbox from "@/taskpane/components/custom-checkbox/CustomCheckbox";
import { Box, IconButton, InputAdornment, ListItemText, MenuItem } from "@mui/material";

import type { FiliationMultiselectProps } from "./FiliationMultiselect.types";

/**
 * @description Component that renders when content is empty
 */
function EmptyComponent() {
  return <div />;
}

/**
 * @description Component that renders the typical addin multiselect
 */
export default function FiliationMultiselect({
  blockUi,
  color,
  data,
  selectedElements,
  setSelectedElements,
  title,
}: FiliationMultiselectProps) {
  return (
    <StyledSelect
      label={title}
      multiple
      value={selectedElements}
      onChange={(e) => setSelectedElements(e.target.value as number[])}
      renderValue={(selected: unknown) => (
        <Box sx={{ maxWidth: 70, textOverflow: "ellipsis", overflow: "hidden" }}>
          {(selected as number[]).map((x) => data.find((v) => v.id === x)?.value).join(", ")}
        </Box>
      )}
      endAdornment={
        selectedElements.length ? (
          <InputAdornment sx={{ marginRight: "-13px" }} position="end">
            <IconButton
              onClick={() => {
                setSelectedElements([]);
              }}
              size="small"
            >
              <MdClose />
            </IconButton>
          </InputAdornment>
        ) : null
      }
      IconComponent={selectedElements.length ? EmptyComponent : undefined}
    >
      {data.map((v) => (
        <MenuItem sx={{ pl: 0.5, py: 0 }} key={v.id} value={v.id}>
          <CustomCheckbox
            color={color}
            checked={selectedElements.includes(v.id)}
            disabled={!!blockUi}
            sx={{
              p: 0.5,
              ".MuiSvgIcon-root": {
                color: `${color}.600`,
              },
            }}
          />
          <ListItemText primaryTypographyProps={{ fontSize: 12, color: `${color}.600` }} primary={v.value} />
        </MenuItem>
      ))}
    </StyledSelect>
  );
}
