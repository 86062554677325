import { apiClient } from "@/taskpane/utils/client";
import { handleError } from "@/taskpane/utils/errors";
import { clearSenstiveDataFromLocalStorage } from "@/taskpane/utils/storage";

import type { UsersPartenaires } from "@/taskpane/types/user";

export async function setUserCrpcen({ crpcen }: { crpcen: string }) {
  return (
    await apiClient.post("/user/set_crpcen", {
      crpcen,
    })
  ).data;
}

export async function getUserData() {
  try {
    const response = await apiClient.get("/user/current");
    return response?.data;
  } catch (error) {
    const errorMessage =
      error instanceof Error
        ? error.message
        : typeof error === 'string'
          ? error
          : (error as any).status
            ? "error code: " + (error as any).status
            : "";

    return handleError(
      error,
      `GET request to api endpoint /user/current failed ${errorMessage ?
        "with status"
        +
        " "
        +
        errorMessage
        :
        ""
      }`,
      `Echec de l'authentification utilisateur`,  () => {
        const loginURL = `${window.location.origin}${window.location.pathname}?${window.location.search}#/login`;
        window.location.href = loginURL;
        clearSenstiveDataFromLocalStorage();
      });
  }
}

/**
 * Retrieves all infos on patenaires for the current client (etude notariale).
 *
 * Youc can find infos to link with the user like initiales, nom, prenom, etc.
 *
 * This allow us to link the addin user with his informations from the etude notariale and
 * professional software used by the client.
 *
 * @returns {Promise} - A promise that resolves to a list of all patenaires for the current client.
 */
export async function getAllUserPartenaireId(): Promise<UsersPartenaires | null> {
    return (await apiClient.get(`/user/get_all_partenaire_id`))?.data;
}

/**
 * Refresh the list of all patenaires for the current client (etude notariale).
 *
 * This is useful when a user did not find his information in the list.
 *
 * @returns {Promise} - A promise that resolves when the list is refreshed.
 */
export async function refreshAllUserPartenaireId() {
  return apiClient.post(`/user/refresh_list_partenaire_id`);
}

/**
 * Allows to set infos from partenaire user to the current user.
 * In this case, the partenaireId refers to the initiales of the user in the etude notariale.
 *
 * @param {string} partenaireId - partenaireUser id identify thanks to initiales in the partenaireUser object from the etude notariale serving as unique identifier from client external data.
 * @returns {Promise<void>} - A promise that resolves when the partenaireId is set.
 */
export async function setUserPartenaireId({
  partenaireId,
}: {
  partenaireId: string;
}) {

  const body = {
    'partenaire_id': partenaireId
  }

  return apiClient.post(`/user/set_partenaire_id`, body);
}