import React, { useContext, useCallback } from "react";

import { Box, Button, Stack } from "@mui/material";
import Logo from "@/taskpane/components/logo/Logo";
import { AuthContext } from "@/taskpane/contexts/auth/AuthContext";
import { useNavigate } from "react-router-dom";

export function Header() {
  const { isAuthenticated, logout, isLoading } = useContext(AuthContext);
  const nav = useNavigate();

  const handleLogout = useCallback(() => {
    if (isAuthenticated && !isLoading) {
      logout();
      nav("/login");
    }
  }, [isAuthenticated, isLoading]);

  return (
    <Box sx={{ px: 1, pt: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Logo />
        {isAuthenticated ? (
          <Button variant="outlined" size="small" onClick={handleLogout} color="error">
            Déconnexion
          </Button>
        ) : null}
      </Stack>
    </Box>
  );
}
