import { getUploadUrls, uploadFile } from "@/taskpane/services/upload";
import { MutationOptions, useMutation } from "@tanstack/react-query";
import { renamePdfExtension } from "@/taskpane/utils/formatFile";

export const useUploadFilesMutation = (
  opt?: MutationOptions<Awaited<ReturnType<typeof getUploadUrls>>, Error, Parameters<typeof getUploadUrls>[0]>
) => {
  return useMutation({
    ...opt,
    mutationFn: async (data) => {
      const formatedData = {
        ...data,
        files: data.files.map((file) => ({
          ...file,
          id: renamePdfExtension(file.id),
          name: renamePdfExtension(file.name),
        })),
      }

      const urls = await getUploadUrls(formatedData);

      const { files } = formatedData;

      const calls = [];

      for (const file of urls.files) {
        const fileInput = files.find((f) => f.name === file.filename);

        if (!fileInput) {
          throw new Error("File not found");
        }

        calls.push(uploadFile({ file, fileInput }));
      }

      return Promise.all(calls);
    },
    onSuccess(data, variables, context) {
      if (opt?.onSuccess) {
        opt.onSuccess(data, variables, context);
      }
    },
  });
};
