import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { WarningTooltip } from "@/taskpane/utils/tooltips";
import LoadingBar from "@/taskpane/components/loading-bar/LoadingBar";
import LoadingButton from "@/taskpane/components/button/LoadingButton";
import { useGetFoldersQuery, useGetSubFoldersQuery } from "@/taskpane/services/folders.hook";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { renamePdfExtension } from "@/taskpane/utils/formatFile";
import { getUploadUrls, uploadFile } from "@/taskpane/services/upload";
import PQueue from "p-queue";

import type { FolderPageLayoutProps } from "@/taskpane/types/folder";



/**
 * @description Compute the optimal concurrency for the current device
 *
 * @param empiricalFactor - A factor to adjust weight of hardware capabilities detected
 *
 * @returns {number} - The optimal concurrency
 */
function determineOptimalConcurrency(empiricalFactor: number = 0.05) {
  let maxConcurrentRequests = 4; // Valeur par défaut

  if ("connection" in navigator) {
    const connection = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;

    switch (connection.effectiveType) {
      case "slow-2g":
      case "2g":
        maxConcurrentRequests = 2;
        break;
      case "3g":
        maxConcurrentRequests = 4;
        break;
      case "4g":
        maxConcurrentRequests = 8;
        break;
    }

    if (connection.saveData) {
      maxConcurrentRequests = Math.max(1, Math.floor(maxConcurrentRequests / 2));
    }
  }

  // Utiliser navigator.hardwareConcurrency si disponible
  const cpuCores = navigator.hardwareConcurrency || 4;
  maxConcurrentRequests = Math.min(maxConcurrentRequests, cpuCores);

  // Considérer les limitations du navigateur (généralement 6 connexions simultanées par domaine)
  const browserLimit = 6;
  maxConcurrentRequests = Math.min(maxConcurrentRequests, browserLimit);

  return maxConcurrentRequests / empiricalFactor;
}

export default function FolderPageLayout({
  canValidate,
  children,
  files,
  folderId,
  onSelectSubFolder,
  onUpload,
  onValidate,
  selectedSubfolder,
  setUploadProgress,
}: FolderPageLayoutProps) {
  const [numberOfFilesToUpload, setNumberOfFilesToUpload] = useState(0);
  const [loading, setLoading] = useState(false);

  const { data: folders } = useGetFoldersQuery();
  const { data: subFolders, isFetching: subFoldersLoading } = useGetSubFoldersQuery(folderId || "");

  const maxConcurrentRequests = useMemo(() => determineOptimalConcurrency(), []);

  const queue = useMemo(() => {
    return new PQueue({ concurrency: maxConcurrentRequests });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (files.length > 0) {
      setNumberOfFilesToUpload(files.length);
    }
  }, [files]);

  const getUploadUrlsMutation: any = useMutation({
    mutationFn: async (data: any) => {
      const formatedData = {
        ...data,
        files: data.files.map((file: any) => ({
          ...file,
          id: renamePdfExtension(file.id),
          name: renamePdfExtension(file.name),
        })),
      };

      const batchSize = 200;
      const batches = [];

      for (let i = 0; i < formatedData.files.length; i += batchSize) {
        batches.push(formatedData.files.slice(i, i + batchSize));
      }

      let accumulatedResults: any[] = [];

      for (const batch of batches) {
        const batchResult = await getUploadUrls({
          ...formatedData,
          files: batch,
        });
        accumulatedResults = accumulatedResults.concat(batchResult.files);
      }

      return { ...formatedData, files: accumulatedResults };
    },
    onSuccess: (data, variables) => {
      const { files } = data;
      setLoading(true);
      if (files && Array.isArray(files) && files.length > 0) {
        const uploadPromises = files.map((file) => {
          const currentFile = file;
          const fileInput = variables.files.find((f: any) => f.name === currentFile.filename);
          return () =>
            uploadFile({
              file: currentFile,
              fileInput: fileInput,
              onProgress: (progress: any) => {
                setUploadProgress((prev: any) => ({
                  ...prev,
                  [fileInput.id]: { status: "uploading", progress },
                }));
              },
            })
              .then(() => {
                setUploadProgress((prev: any) => ({
                  ...prev,
                  [fileInput.id]: { status: "success", progress: 100 },
                }));
              })
              .catch(() => {
                setUploadProgress((prev: any) => ({
                  ...prev,
                  [fileInput.id]: { status: "error", progress: 0 },
                }));
              });
        });

        try {
          queue.addAll(uploadPromises);
          queue.onIdle().then(() => {
            setNumberOfFilesToUpload(0);
            enqueueSnackbar(
              "Envois terminé, l’analyse est en cours et les résultats seront directement intégrés dans votre logiciel",
              {
                variant: "success",
              }
            );
          });
        } catch (e) {
          setLoading(false);
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  /**
   * @description Handle the validation of the upload and call the upload mutation when files are valid.
   *
   * @callback
   * @dependency {boolean} canValidate - Boolean value of the validation state for files to upload
   */
  const handleValidate = useCallback(() => {
    if (!canValidate) {
      return;
    }
    onUpload?.();
    getUploadUrlsMutation.mutate({
      folderId: folderId!,
      subFolderId: selectedSubfolder!.sous_dossier_id,
      files,
    });
    onValidate?.();
  }, [canValidate]);

  const currentFolder = useMemo(() => folders?.dossiers.find((f) => f.dossier_id === folderId), [folders, folderId]);

  const loadingState = useMemo(() => {
    return (numberOfFilesToUpload > 0 && queue.pending > 0 && queue.pending !== numberOfFilesToUpload) || loading;
  }, [numberOfFilesToUpload, queue.pending, loading, setLoading]);

  return (
    <Stack id="folder-page-layout" p={0} gap={1} height={0.92}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">

        {/** Back button */}
        {!loadingState && (
          <Button onClick={() => navigate(-1)} sx={{ position: "absolute", top: 20, right: 20 }}>
            Retour
          </Button>
        )}

      </Stack>

      {/** Folder title */}
      <Typography fontSize={12} color="secondary.500" fontWeight="bold" sx={{ maxWidth: "75vw" }} noWrap>
        Dossier: {currentFolder?.name}
      </Typography>


      {/** Sous-dossiers list */}
      <Box sx={{ display: "grid", gridTemplateRows: "1fr 1fr", height: "100%", minHeight: 0 }}>
        <Box minHeight={0}>{children}</Box>
        <Stack minHeight={0} mt={1}>
          <Typography fontWeight="bold" fontSize={14} color="primary.dark">
            Sélection du sous-dossier :
          </Typography>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            {subFoldersLoading && (
              <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
                <CircularProgress />
              </Stack>
            )}
            {subFolders?.map((sf) => (
              <FormControlLabel
                key={sf.sous_dossier_id}
                control={
                  <Radio
                    size="small"
                    checked={selectedSubfolder?.sous_dossier_id === sf?.sous_dossier_id}
                    color="secondary"
                    onChange={() => onSelectSubFolder(sf)}
                  />
                }
                label={sf.nature + " " + sf.name}
                slotProps={{ typography: { fontSize: 12 } }}
              />
            ))}
          </Card>
        </Stack>
      </Box>

      {/** Validate button */}
      <Stack alignItems="center" justifyContent="flex-end" direction="row">
        {loadingState ? (
          <LoadingBar
            text={`Documents envoyés: ${numberOfFilesToUpload - queue.pending} sur ${numberOfFilesToUpload}`}
            variant="determinate"
            progress={
              numberOfFilesToUpload > 0
                ? Math.ceil(((numberOfFilesToUpload - queue.pending) / numberOfFilesToUpload) * 100)
                : 0
            }
          />
        ) : (
          <WarningTooltip title={!canValidate ? "Vous devez sélectionner un fichier et un sous-dossier" : ""}>
            <span>
              <LoadingButton
                disabled={!canValidate}
                onClick={handleValidate}
                variant={canValidate ? "outlined" : "contained"}
                color="success"
              >
                Valider
              </LoadingButton>
            </span>
          </WarningTooltip>
        )}
      </Stack>
    </Stack>
  );
}
