import { UploadableFile } from "@/taskpane/types/file";
import { apiClient } from "@/taskpane/utils/client";
import mime from "mime";
import { handleError } from "@/taskpane/utils/errors";

/**
 * Retrieves the upload URLs for the specified folder and subfolder, and the informations in order to upload the files.
 * @param folderId - The ID of the folder.
 * @param subFolderId - The ID of the subfolder.
 * @param files - An array of UploadableFile objects representing the files to be uploaded.
 * @returns A Promise that resolves to the upload URLs and file details.
 */
export async function getUploadUrls({
  folderId,
  subFolderId,
  files,
}: {
  folderId: string;
  subFolderId: string;
  files: UploadableFile[];
}) {
  return (
    await apiClient.post(`/dossier/${folderId}/get-upload-urls`, {
      files: files.map((f) => ({
        filename: f.name,
        mime_type: mime.getType(f.name) || "application/octet-stream",
      })),
      sous_dossier_id: subFolderId,
    })
  ).data;
}

/**
 * Uploads a file to the specified URL using a FormData object.
 *
 * @param file - The file object containing the URL, fields, and filename.
 * @param fileInput - The UploadableFile object containing the base64 data and name of the file.
 * @returns A Promise that resolves to the response from the server.
 * @throws An error if the HTTP response is not successful.
 */
export async function uploadFile({
  file,
  fileInput,
}: {
  file: { url: string; fields: Record<string, string>; filename: string };
  fileInput: UploadableFile;
}) {
  const { url, fields } = file;

  const formData = new FormData();

  for (const key in fields) {
    formData.append(key, fields[key]);
  }

  const splittedBase64 = fileInput.base64.split(",");
  let { base64 } = fileInput;
  if (splittedBase64.length === 2) {
    base64 = splittedBase64[1];
  }

  // Convert base64 to Blob
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mime.getType(fileInput.name) || "application/octet-stream" });

  formData.append("file", blob, fileInput.name);

  const response = await fetch(url, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    handleError(
      `${response.status}`,
      `${response.status}`,
      `Echec de l'upload du fichier ${file.filename}`);
  }

  return response;
}
