import { Button, Stack, Typography, Drawer, IconButton, Box } from "@mui/material";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { enqueueConfirmSnackbar } from "@/taskpane/components/snackbar-provider/SnackbarProvider";
// import { NotarySpaceSteps } from "@/taskpane/types/notary-space";
import { SnackbarCloseIcon } from "@/taskpane/components/snackbar-provider";
import { SubFolder } from "@/taskpane/types/folder";
import { useAssociateDataroomToFolderMutation, useNotarySpaceConnectionMutation } from "@/taskpane/services/notary-space.hook";
import { useGetFoldersQuery } from "@/taskpane/services/folders.hook";
import { useNavigate, useParams } from "react-router-dom";
// import { WarningTooltip } from "@/taskpane/utils/tooltips";
import NotarySpaceDataroom from "@/taskpane/modules/folder/notary-space/steps/notary-space-dataroom/NotarySpaceDataroom";
import NotarySpaceLogin from "@/taskpane/modules/folder/notary-space/steps/notary-space-login/NotarySpaceLogin";
import NotarySpaceSubfolder from "@/taskpane/modules/folder/notary-space/steps/notary-space-subfolder/NotarySpaceSubfolder";
import NotarySpaceDebug from "./NotarySpaceDebug";
import React, { useMemo, useState } from "react";
import VerticalStepper from "@/taskpane/components/vertical-stepper/VerticalStepper";
// import BugReportIcon from '@mui/icons-material/BugReport';
import styles from "./FolderNotarySpace.styles";

export default function FolderNotarySpace() {
  const [selectedDataRoom, setSelectedDataRoom] = useState<string | undefined>();
  const [selectedSubFolder, setSelectedSubFolder] = useState<SubFolder>();
  const [currentStep, setCurrentStep] = useState(0);
  const [debugOpen, setDebugOpen] = useState(false);

  const params = useParams();

  const navigate = useNavigate();

  const { data: folders } = useGetFoldersQuery();

  const handleChangeStep = (newStep: number) => {
    if (newStep < 0) {
      navigate("/folders");
    } else if(newStep > 3) {
      return;
    }else {
      setCurrentStep(newStep);
    }
  };


  const connection = useNotarySpaceConnectionMutation({
    onSuccess: () => {
      handleChangeStep(currentStep + 1);
    },
    onError: () => {
      enqueueConfirmSnackbar(
        "Identifiants incorrects",
        () => {},
        "OK",
        {
          persist: true,
          variant: "error",
          action: (snackbarId: SnackbarKey | undefined) => (
            <SnackbarCloseIcon handleClose={() => closeSnackbar(snackbarId)} />
          )
        }
      );
    },
  });

  const associateDataroom = useAssociateDataroomToFolderMutation({
    onSuccess: () => {
      handleChangeStep(currentStep + 1);
      const message = "Envoi terminé, l'analyse est en cours et les résultats seront directement intégrés dans votre logiciel.";
      enqueueSnackbar(message, {
        variant: "success",
        persist: true,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        action: (snackbarId) => (
          <Button
            variant="outlined"
            color="success"
            onClick={() => {
              navigate("/folders");
              closeSnackbar(snackbarId);
            }}
          >
            Confirmer
          </Button>
        )
      });
    },
    onError: () => {
      enqueueSnackbar("Un problème est survenu", {
        variant: "error",
        action: (snackbarId) => (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              closeSnackbar(snackbarId);
              associateDataroom.mutate({
                folderId: params.folderId || "",
                connectionId: connection.data?.connection_id || "",
                dataRoomId: selectedDataRoom || "",
              });
            }}
          >
            Réessayer
          </Button>
        ),
      });
    },
  });

  const currentFolder = useMemo(
    () => folders?.dossiers.find((f) => f.dossier_id === params.folderId),
    [folders, params.folderId]
  );

  const steps = useMemo(
    () => [
      {
        title: "Sélection du sous-dossier",
        subtitle: "Choisir le sous-dossier auquel rattacher la dataroom",
        component: (
          <NotarySpaceSubfolder
            folderId={params.folderId || ""}
            selectedSubFolder={selectedSubFolder}
            setSelectedSubFolder={setSelectedSubFolder}
            refreshConnection={(connection_id: string) => {
              connection.mutate({ connection_id, folderId: params.folderId || "" });
              handleChangeStep(currentStep + 1);
            }}
          />
        ),
      },
      {
        title: "Connexion à l'espace notarial",
        subtitle: "Renseigner les identifiants de connexion",
        component: (
          <NotarySpaceLogin
            startConnection={({ username, password, url }) => {
              connection.mutate({
                url,
                folderId: params.folderId || "",
                identifiant: username,
                mot_de_passe: password,
                sous_dossier_id: selectedSubFolder?.sous_dossier_id || "",
              });
            }}
            connectionPending={connection.isPending}
          />
        ),
      },
      {
        title: "Sélection de la dataroom ",
        subtitle: "Choisir la dataroom espace-notarial à analyser",
        component: (
          <NotarySpaceDataroom
            datarooms={connection.data?.all_datarooms || []}
            selectedDataroom={selectedDataRoom}
            setSelectedDataroom={setSelectedDataRoom}
          />
        ),
      },
    ],
    [selectedSubFolder, connection, currentStep, params.folderId]
  );

  return (
    <Stack sx={styles.container}>
      {/* Debug Panel Toggle Button */}
      {/* <IconButton
        onClick={() => setDebugOpen(!debugOpen)}
        sx={{
          position: "fixed",
          right: 20,
          top: 20,
          zIndex: 1000,
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "#f5f5f5"
          }
        }}
      >
        <BugReportIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={debugOpen}
        onClose={() => setDebugOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '400px',
            padding: 2,
            boxSizing: 'border-box',
          },
        }}
      >
        <NotarySpaceDebug
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          selectedDataRoom={selectedDataRoom}
          setSelectedDataRoom={setSelectedDataRoom}
          connection={connection}
        />
      </Drawer> */}

      {/* Back buttons */}
      <Stack sx={styles.navigationButtons}>
        {currentStep === 3 && !connection.isPending && (
          <Button onClick={() => handleChangeStep(currentStep - 1)} sx={{ position: "absolute", top: 20, right: 20 }}>
            Retour
          </Button>
        )}
      </Stack>

      {/* Folder title */}
      <Typography sx={styles.folderTitle} noWrap>
        Dossier: {currentFolder?.name}
      </Typography>

      <Stack sx={styles.mainContent}>
        {/* Steps */}
        <VerticalStepper steps={steps} currentStep={currentStep} />

        {currentStep === 1 && !connection.isPending && (
          <Stack sx={styles.actionButtons}>
            <Button variant="contained" color="primary" onClick={() => handleChangeStep(currentStep - 1)}>
              Retour
            </Button>
          </Stack>
        )}

        {/* Sous-dossier step Actions */}
        {currentStep === 0 && (
          <Stack sx={styles.actionButtons}>
            <Button variant="contained" color="primary" onClick={() => handleChangeStep(currentStep - 1)}>
              Précédent
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleChangeStep(currentStep + 1)}
              disabled={!selectedSubFolder}
            >
              Suivant
            </Button>
          </Stack>
        )}

        {/* Dataroom step Actions */}
        {currentStep === 2 && (
          <Stack sx={styles.actionButtons}>
            <Button variant="contained" color="primary" onClick={() => handleChangeStep(currentStep - 1)}>
              Précédent
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                associateDataroom.mutate({
                  folderId: params.folderId || "",
                  connectionId: connection.data?.connection_id || "",
                  dataRoomId: selectedDataRoom || "",
                });
                handleChangeStep(currentStep + 1);
              }}
              disabled={!selectedDataRoom}
            >
              Valider
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}