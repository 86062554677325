import CheckableFile from "@/taskpane/components/checkable-file/CheckableFile";
import DragAndDrop from "@/taskpane/components/drag-and-drop/DragAndDrop";
import { useFileDnd } from "@/taskpane/hooks/file-dnd";
import FolderPageLayout from "@/taskpane/modules/folder/FolderPageLayout";
import { UploadableFile } from "@/taskpane/types/file";
import { SubFolder } from "@/taskpane/types/folder";
import { Box, Card, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export default function FolderDragAndDropPage() {
  const params = useParams();
  const [selectedSubFolder, setSelectedSubFolder] = useState<SubFolder>();
  const [selectedFiles, setSelectedFiles] = useState<(UploadableFile & { isPdf: boolean })[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<(UploadableFile & { isPdf: boolean })[]>([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const { onDrop, files } = useFileDnd((files) => setSelectedFiles((prev) => [...prev, ...files]));

  return (
    <FolderPageLayout
      folderId={params.folderId}
      onSelectSubFolder={setSelectedSubFolder}
      selectedSubfolder={selectedSubFolder}
      files={selectedFiles}
      canValidate={!!selectedSubFolder && selectedFiles.length > 0}
      onValidate={() => {
        setUploadLoading(false);
        setUploadedFiles([...uploadedFiles, ...selectedFiles]);
        setSelectedFiles([]);
        setSelectedSubFolder(undefined);
      }}
      onUpload={() => {
        setUploadLoading(true);
      }}
    >
      <Stack height="100%">
        <Typography fontWeight="bold" fontSize={14} color="primary.dark">
          Sélection des fichiers déposés :
        </Typography>
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            opacity: uploadLoading ? 0.5 : 1,
            pointerEvents: uploadLoading ? "none" : "auto",
          }}
        >
          {files.length === 0 ? (
            <Box p={4}>
              <DragAndDrop
                options={{
                  multiple: true,
                  onDrop,
                }}
              />
            </Box>
          ) : (
            <Stack direction="column" justifyContent="space-between" alignItems="center" height={1}>
              <Stack direction="column" gap={0.5} width={1}>
                {files.map((file, index) => (
                  <CheckableFile
                    key={index}
                    attachment={file}
                    uploaded={uploadedFiles.some((a) => a.id === file.id)}
                    selectedAttachments={selectedFiles}
                    setSelectedAttachments={setSelectedFiles}
                  />
                ))}
              </Stack>
              <Box width="70%" mt={3} mb={1}>
                <DragAndDrop
                  folded
                  options={{
                    multiple: true,
                    onDrop,
                  }}
                />
              </Box>
            </Stack>
          )}
        </Card>
      </Stack>
    </FolderPageLayout>
  );
}
