import { useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { usePollingContext } from '@/taskpane/contexts/polling/PollingContext';

/**
 * @description Custom hook to manage polling functionality.
 *
 * @returns {Object} Polling state and control functions.
 */
export const usePolling = () => {
  const {
    queryKey,
    queryFn,
    interval,
    stopCondition,
    isPolling,
    setPollingConfig,
    startPolling,
    stopPolling,
  } = usePollingContext();

  const queryClient = useQueryClient();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * @description Effect hook to initialize polling on component mount.
   *
   * This effect runs only once when the component mounts.
   *
   * It sets up the initial polling mechanism if isPolling is true and required parameters are present.
   *
   * - Checks if polling should start (isPolling is true and queryKey and queryFn are present)
   * - Defines an async poll function that:
   *   - Fetches data using queryClient.fetchQuery
   *   - Checks stop condition and stops polling if met
   *   - Otherwise, schedules the next poll using setTimeout
   * - Initiates the polling process
   * - Cleans up by clearing the timeout when the component unmounts
   *
   * @effect
   */
  useEffect(() => {
    if (isPolling && queryKey && queryFn) {
      const poll = async () => {
        const data = await queryClient.fetchQuery({ queryKey: [queryKey], queryFn });
        if (stopCondition && stopCondition(data)) {
          stopPolling();
        } else {
          timerRef.current = setTimeout(poll, interval);
        }
      };
      poll();
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  /**
    * @description Effect hook to manage the polling process.
    *
    * This effect runs whenever any of its dependencies change.
    *
    * It handles the core polling logic, including starting, stopping, and updating the poll.
    * - Defines an async poll function that:
    *   - Checks if polling should continue (isPolling is true and required parameters are present)
    *   - Fetches data using queryClient.fetchQuery
    *   - Checks stop condition and stops polling if met
    *   - Otherwise, schedules the next poll using setTimeout
    * - Starts polling if isPolling is true
    * - Cleans up by clearing the timeout when the effect re-runs or the component unmounts
    *
    * @effect
    * @dependency {boolean} isPolling - The current polling state.
    * @dependency {string} queryKey - The query key to fetch data.
    * @dependency {function} queryFn - The function to fetch data.
    * @dependency {number} interval - The polling interval in milliseconds.
    * @dependency {function} stopCondition - The function to check if polling should stop.
    * @dependency {function} stopPolling - The function to stop polling.
    * @dependency {function} queryClient - The query client instance.
    */
  useEffect(() => {
    const poll = async () => {
      if (!isPolling || !queryKey || !queryFn) {
        return;
      }

      const data = await queryClient.fetchQuery({ queryKey: [queryKey], queryFn });

      if (stopCondition && stopCondition(data)) {
        stopPolling();
      } else {
        timerRef.current = setTimeout(poll, interval);
      }
    };

    if (isPolling) {
      poll();
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [queryClient, queryKey, queryFn, interval, stopCondition, isPolling, stopPolling]);

  const { data, error, isLoading } = useQuery({
    queryKey: queryKey ? [queryKey] : [],
    queryFn: queryFn || (() => Promise.resolve(null)),
    enabled: isPolling && !!queryKey && !!queryFn,
  });

  return {
    data,
    error,
    isLoading,
    isPolling,
    setPollingConfig,
    startPolling,
    stopPolling
  };
};